import React, { createContext, FunctionComponent, useState, useContext, useRef } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Alert from '@material-ui/lab/Alert'

enum Severity {
  Success = 'success',
  Error = 'error',
}

type Message = {
  text: string
  severity?: Severity
  key: number
}

type Context = {
  open: boolean
  setOpen: (value: boolean) => void
  message: Message | undefined
  processQueue: () => void
  showMessage: (message: string, severity?: Severity) => void
}

const defaultContext: Context = {
  open: false,
  setOpen: (value: boolean) => { },
  message: undefined,
  processQueue: () => { },
  showMessage: (message: string, severity?: Severity) => { },
}

const MessageContext = createContext<Context>(defaultContext)

export const MessageProvider: FunctionComponent = ({ children }) => {
  const queue = useRef<Message[]>([])
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState<Message | undefined>(undefined)

  const processQueue = () => {
    if (queue.current.length > 0) {
      setMessage(queue.current.shift())
      setOpen(true)
    }
  }

  const showMessage = (message: string, severity?: Severity) => {
    queue.current.push({
      text: message,
      severity: severity,
      key: new Date().getTime(),
    })

    if (open) {
      setOpen(false)
    } else {
      processQueue()
    }
  }

  const contextValue = { open, setOpen, message, processQueue, showMessage }

  return (
    <MessageContext.Provider value={contextValue}>
      {children}
    </MessageContext.Provider>
  )
}

const Message = () => {
  const context = useContext(MessageContext)

  const handleClose = (event: React.SyntheticEvent | MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    context.setOpen(false)
  }

  const handleExited = () => {
    context.processQueue()
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      key={context.message ? context.message.key : undefined}
      open={context.open}
      autoHideDuration={8000}
      style={{ marginTop: 16 }}
      onClose={handleClose}
      onExited={handleExited}
    >
      <Alert variant="filled" severity={(context.message && context.message.severity) || Severity.Success} action={
        <IconButton
          color="inherit"
          size="small"
          onClick={handleClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }>
        {context.message ? context.message.text : undefined}
      </Alert>
    </Snackbar>
  )
}

export default Message

export const useMessage = () => {
  const context = useContext(MessageContext)

  const showSuccess = (message: string) => context.showMessage(message)
  const showError = (message: string) => context.showMessage(message, Severity.Error)

  return {
    showSuccess,
    showError,
  }
}
