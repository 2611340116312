import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { IPermitsAddZoneData, IPermitsListZoneData, useApi } from 'components/providers/ApiProvider'

interface Filter {
  zone: number
  plateNumber: string
  client: string
  assignedFrom: string
  assignedTill: string
  notes: string
}

type RootState = {
  filter: Filter
  addZones: IPermitsAddZoneData[]
  listZones: IPermitsListZoneData[]
  loadList: number
  listLoading: boolean
  listPerPage: number
  listPage: number
}

type RootFunctions = {
  setFilter(filter: Filter): void
  setLoadList(): void
  setListLoading(status: boolean): void
  setLoadSearchList(): void
  setLoadChangePerPageList(perPage: number): void
  setLoadChangePageList(page: number): void
  setListPage(value: number): void
}

const InitialState: RootState = {
  filter: {
    zone: 0,
    plateNumber: '',
    client: '',
    assignedFrom: '',
    assignedTill: '',
    notes: '',
  },
  addZones: [],
  listZones: [],
  loadList: 0,
  listLoading: false,
  listPerPage: 20,
  listPage: 1,
}

export const ReportsContext = React.createContext<[RootState, (state: any) => void]>([InitialState, () => {
}])

export const PermitsProvider: FunctionComponent = ({ children }) => {
  const api = useApi()

  const [state, setState] = useState<RootState>(InitialState)

  useEffect(() => {
    api.getPermitsAddZones()
      .then(data => {
        setState((state: RootState) => ({
          ...state,
          addZones: data.zones,
        }))
      })
      .catch(() => {
      })

    api.getPermitsListZones()
      .then(data => {
        setState((state: RootState) => ({
          ...state,
          listZones: data.zones,
        }))
      })
      .catch(() => {
      })
  }, [])

  return (
    <ReportsContext.Provider value={[state, setState]}>
      {children}
    </ReportsContext.Provider>
  )
}

export const usePermits = (): RootFunctions & RootState => {
  const [state, setState] = useContext(ReportsContext)

  const setFilter = (filter: Filter) => {
    setState((state: RootState) => ({
      ...state,
      filter,
    }))
  }

  const setLoadList = () => {
    setState((state: RootState) => ({
      ...state,
      loadList: state.loadList + 1,
    }))
  }

  const setListLoading = (status: boolean) => {
    setState((state: RootState) => ({
      ...state,
      listLoading: status,
    }))
  }

  const setLoadSearchList = () => {
    setState((state: RootState) => ({
      ...state,
      loadList: state.loadList + 1,
      listPage: 1,
    }))
  }

  const setLoadChangePerPageList = (perPage: number) => {
    setState((state: RootState) => ({
      ...state,
      loadList: state.loadList + 1,
      listPerPage: perPage,
      listPage: 1,
    }))
  }

  const setLoadChangePageList = (page: number) => {
    setState((state: RootState) => ({
      ...state,
      loadList: state.loadList + 1,
      listPage: page,
    }))
  }

  const setListPage = (value: number) => {
    setState((state: RootState) => ({
      ...state,
      listPage: value,
    }))
  }

  return {
    setFilter,
    setLoadList,
    setListLoading,
    setLoadSearchList,
    setLoadChangePerPageList,
    setLoadChangePageList,
    setListPage,
    ...state,
  }
}
