import React, { FunctionComponent, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { SelectField } from 'components/elements/SelectField'
import { DateTimeField } from 'components/elements/DateTimeField'
import { useTranslation } from 'react-i18next'
import useFormal from '@kevinwolf/formal-web'
import * as yup from 'yup'
import { useParkingLot } from '../ParkingLotProvider'
import { ToolbarButtonsGridContainer, ToolbarFormGridContainer } from '../ParkingLot.css'
import AddSessionDialog from './AddSessionDialog'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import BarriersDialog from './BarriersDialog'
import { ClickAwayListener, FormControl, Grow, MenuItem, MenuList, Popper } from '@material-ui/core'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { formatDateTimeFieldToISO, isDateTimeFieldValid, parkingLotBarriersPermission, parkingLotModifyPermission, parseDateTimeField, yupDateTimeField } from 'utils'
import { PlateNumberField } from 'components/elements/PlateNumberField'
import { usePermissions } from 'components/providers/PermissionProvider'
import SearchIcon from '@material-ui/icons/Search'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Paper from '@material-ui/core/Paper'
import { useApi } from 'components/providers/ApiProvider'
import download from 'downloadjs'
import AddIcon from '@material-ui/icons/Add'

type Props = {}
type Functions = {}
type AllProps = Props & Functions

const Toolbar: FunctionComponent<AllProps> = ({}) => {
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const api = useApi()
  const { listZones: zones, searchByPlateNumber, setLoadSearchList, setLoadSearchByPlateNumberList, listLoading } = useParkingLot()
  const permissions = usePermissions()

  const hasParkingLotModifyPermission = parkingLotModifyPermission(permissions)
  const hasParkingLotBarriersPermission = parkingLotBarriersPermission(permissions)

  const [search, setSearch] = useState(0)
  const [openSearchButtonDropdown, setOpenSearchButtonDropdown] = React.useState(false)
  const searchButtonRef = React.useRef(null)
  let exportClicked = false
  const [exportLoading, setExportLoading] = useState(false)

  const [openAddSessionDialog, setOpenAddSessionDialog] = useState(false)
  const [openBarriersDialog, setOpenBarriersDialog] = useState(false)

  const filter = queryString.parse(location.search)

  useEffect(() => {
    const selectedZone = zones.find(z => (typeof filter.zone == 'string' && z.id == parseInt(filter.zone)))

    if (selectedZone) {
      formal.change('zone', selectedZone.id)
    }
  }, [zones])

  useEffect(() => {
    if (typeof searchByPlateNumber != 'string') {
      return
    }

    formal.change('plateNumber', searchByPlateNumber)

    const values = formal.values
    const search = queryString.stringify({
      zone: values.zone || undefined,
      plateNumber: searchByPlateNumber,
      dateFrom: values.dateFrom || undefined,
      dateTill: values.dateTill || undefined,
      state: values.state || undefined,
    }, { sort: false })

    history.push({
      search,
    })

    setLoadSearchByPlateNumberList()
  }, [searchByPlateNumber])

  useEffect(() => {
    if (search) {
      formal.submit()
    }
  }, [search])

  const handleSearchButtonDropdownToggle = () => {
    setOpenSearchButtonDropdown((prevOpen) => !prevOpen)
  }

  const handleSearchButtonDropdownClose = (event) => {
    if (searchButtonRef.current && searchButtonRef.current.contains(event.target)) {
      return
    }

    setOpenSearchButtonDropdown(false)
  }

  const handleExportClick = () => {
    exportClicked = true
    formal.submit()
  }

  const initialValues = {
    zone: 0,
    plateNumber: (typeof filter.plateNumber == 'string' && filter.plateNumber) || '',
    dateFrom: (typeof filter.dateFrom == 'string' && filter.dateFrom) || '',
    dateTill: (typeof filter.dateTill == 'string' && filter.dateTill) || '',
    state: (typeof filter.state == 'string' && filter.state) || '',
  }

  const schema = yup.object().shape({
    zone: yup.number(),
    plateNumber: yup.string(),
    dateFrom: yupDateTimeField().test({
      test: value => {
        if (value == '') {
          return true
        }

        const valueDate = parseDateTimeField(value)

        if (formal.values.dateTill != '' && isDateTimeFieldValid(formal.values.dateTill) && valueDate >= parseDateTimeField(formal.values.dateTill)) {
          return false
        }

        return valueDate <= (new Date)
      },
    }),
    dateTill: yupDateTimeField().test({
      test: value => {
        if (value == '') {
          return true
        }

        const valueDate = parseDateTimeField(value)

        if (formal.values.dateFrom != '' && isDateTimeFieldValid(formal.values.dateFrom) && valueDate <= parseDateTimeField(formal.values.dateFrom)) {
          return false
        }

        return true
      },
    }),
    state: yup.string(),
  })

  const formal = useFormal(initialValues, {
    schema,
    onSubmit: values => {
      if (exportClicked) {
        setExportLoading(true)

        api.exportParkingLotList({
          ...(values.zone && { zone_id: values.zone }),
          ...(values.plateNumber.trim() && { plate_number: values.plateNumber.trim().toUpperCase() }),
          ...(values.dateFrom && { occurred_from: formatDateTimeFieldToISO(values.dateFrom) }),
          ...(values.dateTill && { occurred_till: formatDateTimeFieldToISO(values.dateTill) }),
          ...(values.state && { state: values.state }),
        })
          .then(blob => {
            setExportLoading(false)

            download(blob, 'parking-lot.xlsx')
          })
          .catch(() => {
            setExportLoading(false)

            alert(`${t('alerts.couldNotExportData')} ${t('alerts.tryLater')}`)
          })
      } else {
        const search = queryString.stringify({
          zone: values.zone || undefined,
          plateNumber: values.plateNumber.trim().toUpperCase() || undefined,
          dateFrom: values.dateFrom || undefined,
          dateTill: values.dateTill || undefined,
          state: values.state || undefined,
        }, { sort: false })

        history.push({
          search,
        })

        setLoadSearchList()
      }
    },
  })

  return (
    <>
      {(hasParkingLotModifyPermission || hasParkingLotBarriersPermission) && (
        <ToolbarButtonsGridContainer container>
          {hasParkingLotModifyPermission && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                startIcon={<AddIcon />}
                onClick={() => setOpenAddSessionDialog(true)}
              >
                {t('newSession')}
              </Button>
            </Grid>
          )}
          {hasParkingLotBarriersPermission && (
            <Grid item style={{ marginLeft: 'auto' }}>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                onClick={() => setOpenBarriersDialog(true)}
              >
                {t('barriers')}
              </Button>
            </Grid>
          )}
        </ToolbarButtonsGridContainer>
      )}

      <form {...formal.getFormProps()}>
        <ToolbarFormGridContainer container spacing={1}>
          <Grid item xs={12} md={true}>
            <Grid container spacing={1}>
              <SelectField
                GridProps={{ xs: 6, lg: 3 }}
                label={t('zone')}
                optionsCustom={[
                  {
                    label: t('allZones'),
                    id: 0,
                  },
                  ...zones.sort((a, b) => a.code.localeCompare(b.code)).map(zone => {
                    const localization = zone.localizations.find(l => l.language == i18n.language)

                    return {
                      label: `${zone.code}${localization && localization.name ? ` ${localization.name}` : ''}`,
                      id: zone.id,
                    }
                  }),
                ]}
                size="small"
                {...formal.getFieldProps('zone')}
              />
              <PlateNumberField
                GridProps={{ xs: 6, lg: 3 }}
                size="small"
                allowMultiple={true}
                clearable={true}
                {...formal.getFieldProps('plateNumber')}
              />
              <DateTimeField
                GridProps={{ xs: 12, sm: 6, lg: 3 }}
                label={`${t('date')} - ${t('from')}`}
                size="small"
                {...formal.getFieldProps('dateFrom')}
                initialFocusedDate={(new Date).setHours(0, 0, 0, 0)}
                {
                  ...(formal.values.dateTill && { maxDate: parseDateTimeField(formal.values.dateTill) })
                }
                clearable
              />
              <DateTimeField
                GridProps={{ xs: 12, sm: 6, lg: 3 }}
                label={`${t('date')} - ${t('till')}`}
                size="small"
                {...formal.getFieldProps('dateTill')}
                initialFocusedDate={(new Date).setHours(23, 59, 59, 99)}
                {
                  ...(formal.values.dateFrom && { minDate: parseDateTimeField(formal.values.dateFrom) })
                }
                clearable
              />
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    {...formal.getFieldProps('state')}
                    onChange={event => {
                      formal.change('state', event.target.value)

                      // @ts-ignore
                      if (event.nativeEvent.altKey) {
                        setSearch(search + 1)
                      }
                    }}
                    style={{ paddingLeft: 12 }}
                  >
                    <FormControlLabel value="" control={<Radio />} label={t('all')} />
                    <FormControlLabel value="open_session" control={<Radio />} label={t('inParkingLot')} />
                    <FormControlLabel value="closed_session" control={<Radio />} label={t('closedSessions')} />
                    <FormControlLabel value="closed_unpaid_session" control={<Radio />} label={t('closedUnpaidSessions')} />
                    <FormControlLabel value="event" control={<Radio />} label={t('events')} />
                    {hasParkingLotModifyPermission && (
                      <FormControlLabel value="archived_event" control={<Radio />} label={t('archivedEvents')} />
                    )}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={'auto'}>
            <ButtonGroup variant="contained" color="primary" fullWidth ref={searchButtonRef} style={{ height: '100%', maxHeight: 40 }}>
              <Button
                type="submit"
                startIcon={<SearchIcon />}
                disabled={listLoading}
              >
                {t('search')}
              </Button>
              <Button
                size="small"
                style={{ flex: 1 }}
                onClick={handleSearchButtonDropdownToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper open={openSearchButtonDropdown} placement="bottom-end" anchorEl={searchButtonRef.current} transition disablePortal>
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: 'right top',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleSearchButtonDropdownClose}>
                      <MenuList>
                        <MenuItem
                          disabled={exportLoading}
                          onClick={() => handleExportClick()}
                        >
                          {`${t('export')} (XLSX)`}
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
        </ToolbarFormGridContainer>
      </form>
      {hasParkingLotModifyPermission && <AddSessionDialog open={openAddSessionDialog} setOpen={setOpenAddSessionDialog} />}
      {hasParkingLotBarriersPermission && <BarriersDialog open={openBarriersDialog} setOpen={setOpenBarriersDialog} />}
    </>
  )
}

export default Toolbar
