import React, { FunctionComponent } from 'react'

export type Props = {
  width?: number
  fill?: string
}
export type Functions = {}

export type AllProps = Props & Functions

export const MobillyIcon: FunctionComponent<AllProps> = ({ width, fill }) => (
  <svg
    width={`${width || 37}px`}
    viewBox="0 0 37 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-24.000000, -16.000000)"
        fill={`${fill || '#FFFFFF'}`}
      >
        <g id="header-menu" transform="translate(-136.000000, 0.000000)">
          <g id="Group-3" transform="translate(160.000000, 16.000000)">
            <g id="mobilly-logo-circle">
              <g id="shape">
                <path
                  d="M1.89897234,4.33185713 C0.991608808,4.78358056 0.637802309,5.89656964 1.09213685,6.79871093 C1.55365197,7.70019945 2.6620716,8.05792262 3.56551845,7.59836584 C8.21526697,5.22159561 13.2449332,4.0139535 18.5030722,4.0139535 C23.8023363,4.0139535 29.0683087,5.30776251 33.7285017,7.74850513 C34.630643,8.21720083 35.7384098,7.86339433 36.2077583,6.97561418 C36.6784123,6.07151455 36.327217,4.9670116 35.4322562,4.49701035 C30.2478813,1.78210033 24.3957129,0.347290944 18.5030722,0.347290944 C12.6541677,0.347290944 7.06702779,1.6874473 1.89897234,4.33185713"
                  id="Fill-1"
                />
                <path
                  d="M6.40360797,12.1629919 C5.49624444,12.6075348 5.11828509,13.7087738 5.55956405,14.620054 C6.00802358,15.5267648 7.10404039,15.9086408 8.01727894,15.4601813 C11.3027183,13.8582603 14.8453527,13.0429387 18.5355153,13.0429387 C22.3131504,13.0429387 25.926285,13.8954687 29.2763495,15.564626 C30.1797964,16.02353 31.2856049,15.6547096 31.7425506,14.7545266 C32.194274,13.8478158 31.8287175,12.7439657 30.9220067,12.2915895 C27.0523297,10.3541399 22.8902075,9.3743178 18.5355153,9.3743178 C14.2813512,9.3743178 10.2001736,10.3104036 6.40360797,12.1629919"
                  id="Fill-4"
                />
                <path
                  d="M11.0419981,20.1498146 C10.1176623,20.5578018 9.70575844,21.6518603 10.1176623,22.5722794 C10.530219,23.4985735 11.6190552,23.9150469 12.5460021,23.5037958 C14.4495072,22.6499602 16.4770404,22.2197785 18.5842127,22.2197785 C20.735774,22.2197785 22.8155295,22.6715019 24.7510208,23.5573237 C25.6753566,23.9816304 26.7596234,23.5756015 27.1858884,22.6499602 C27.6088895,21.7308466 27.2028607,20.6446215 26.2824415,20.2209676 C23.8567129,19.109284 21.2697476,18.5465881 18.5842127,18.5465881 C15.9613446,18.5465881 13.4220322,19.0851312 11.0419981,20.1498146"
                  id="Fill-6"
                />
                <path
                  d="M23.5758218,41.1684665 C23.5758218,41.5190091 23.3675852,41.7298569 23.0118203,41.7298569 L21.8857757,41.7298569 C21.5306636,41.7298569 21.3237325,41.5190091 21.3237325,41.1684665 L21.3237325,35.4337984 L19.0096291,38.4502926 C18.9045316,38.6069597 18.8124897,38.6598348 18.6029475,38.6598348 L18.4985027,38.6598348 C18.3039744,38.6598348 18.2112797,38.6069597 18.1042239,38.4502926 L15.9918294,35.4337984 L15.9918294,41.1684665 C15.9918294,41.5190091 15.7848983,41.7298569 15.430439,41.7298569 L14.2998249,41.7298569 C13.9453656,41.7298569 13.7358234,41.5190091 13.7358234,41.1684665 L13.7358234,32.4538599 C13.7358234,32.1000534 13.9453656,31.8905112 14.2998249,31.8905112 L15.5864533,31.8905112 C15.889343,31.8905112 16.0714685,31.9962615 16.2183439,32.1907898 L18.6029475,35.6289795 L21.108968,32.1907898 C21.2388712,31.9962615 21.4249133,31.8905112 21.7264975,31.8905112 L23.0118203,31.8905112 C23.3675852,31.8905112 23.5758218,32.1000534 23.5758218,32.4538599 L23.5758218,41.1684665 Z M18.6577809,25.9521759 C12.6646122,25.9521759 7.8157659,30.8055916 7.8157659,36.7896215 C7.8157659,42.7795264 12.6646122,47.6335949 18.6577809,47.6335949 C24.6378941,47.6335949 29.4958793,42.7795264 29.4958793,36.7896215 C29.4958793,30.8055916 24.6378941,25.9521759 18.6577809,25.9521759 L18.6577809,25.9521759 Z"
                  id="Fill-8"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
