import React, { FunctionComponent, useEffect, useState } from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableBody from '@material-ui/core/TableBody'
import { usePermits } from 'components/views/subscriptions/permits/PermitsProvider'
import { useTranslation } from 'react-i18next'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import IconEdit from '@material-ui/icons/Edit'
import IconStop from '@material-ui/icons/Stop'
import IconDelete from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import IconInfo from '@material-ui/icons/InfoOutlined'
import { IPermitsListData, useApi } from 'components/providers/ApiProvider'
import { useHistory } from 'react-router-dom'
import { formatDateTimeFieldToISO, formatTimestamp } from 'utils'
import { useMessage } from 'components/elements/Message'
import { Skeleton } from '@material-ui/lab'
import EditPermitDialog, { EditPermitDialogData } from 'components/views/subscriptions/permits/partials/EditPermitDialog'
import { Container, LinearProgress } from '@material-ui/core'
import { TablePaginationActions } from 'components/elements/TablePaginationActions'
import Alert from '@material-ui/lab/Alert'
import Tooltip from '@material-ui/core/Tooltip'

const useClasses = makeStyles(theme => ({
  fadeTableRow: {
    '& td': {
      color: theme.palette.grey['A200'],
    },
  },
}))

const NotesTableCell = withStyles(theme => ({
  root: {
    color: theme.palette.text.disabled,
    fontStyle: 'italic',
  },
}))(TableCell)

type Props = {}
type Functions = {}
type AllProps = Props & Functions

const List: FunctionComponent<AllProps> = ({}) => {
  const { t } = useTranslation()
  const api = useApi()
  const history = useHistory()
  const { filter, loadList, listLoading, setListLoading, listPerPage, setLoadChangePerPageList, listPage, setListPage, setLoadChangePageList } = usePermits()
  const { fadeTableRow } = useClasses()
  const { showSuccess, showError } = useMessage()

  const [modify, setModify] = useState<boolean>(false)
  const [permits, setPermits] = useState<IPermitsListData[]>(null)
  const [totalPermits, setTotalPermits] = useState(0)

  const [openEditPermitDialog, setOpenEditPermitDialog] = useState(false)
  const [editPermitDialogData, setEditPermitDialogData] = useState<EditPermitDialogData>(null)

  const load = (onLoad?: Function, page?: number) => {
    if (!page) {
      setListLoading(true)
    }

    api.getPermitsList({
      zone_id: filter.zone || undefined,
      plate_number: filter.plateNumber.toUpperCase() || undefined,
      client: filter.client || undefined,
      ...(filter.assignedFrom && { assigned_from: formatDateTimeFieldToISO(filter.assignedFrom) }),
      ...(filter.assignedTill && { assigned_till: formatDateTimeFieldToISO(filter.assignedTill) }),
      notes: filter.notes || undefined,
      limit: listPerPage,
      page: page || listPage,
    })
      .then(data => {
        if (!page && data.permits && !data.permits.length && data.totalPermits) {
          const pages = Math.ceil(data.totalPermits / listPerPage)

          if (listPage > pages) {
            setListPage(pages)
            load(onLoad, pages)

            return
          }
        }

        setListLoading(false)

        if (onLoad) {
          onLoad(data.permits)
        }

        setModify(Boolean(data.modify))
        setPermits(data.permits)
        setTotalPermits(data.totalPermits)
      })
      .catch(error => {
        setListLoading(false)

        if (error.status == 401) {
          history.push('/')

          return
        }

        showError(`${t('alerts.couldNotLoadData')} ${t('alerts.tryLater')}`)
      })
  }

  useEffect(() => {
    load()
  }, [loadList])

  const handleEdit = (permit: IPermitsListData) => {
    const { id, valid_from: validFrom, valid_till: validTill, count, plate_numbers: plateNumbers, notes, modify } = permit

    setEditPermitDialogData({
      id,
      validFrom,
      validTill,
      count,
      plateNumbers,
      notes,
      updatable: modify.updatable,
    })
    setOpenEditPermitDialog(true)
  }

  const handleStop = (permit: IPermitsListData) => {
    if (!confirm(t('confirms.stopPermit'))) {
      return
    }

    api.stopPermit({
      permit_id: permit.id,
    })
      .then(data => {
        if (!data || !data.status) {
          throw new Error
        }

        load()

        showSuccess(t('messages.permitStopped'))
      })
      .catch(error => {
        let message

        switch (error.code) {
          default:
            message = t('alerts.tryLater')
        }

        showError(`${t('alerts.couldNotStopPermit')} ${message}`)
      })
  }

  const handleDelete = (permit: IPermitsListData) => {
    if (!confirm(t('confirms.deletePermit'))) {
      return
    }

    api.deletePermit({
      permit_id: permit.id,
    })
      .then(data => {
        if (!data || !data.status) {
          throw new Error
        }

        load()

        showSuccess(t('messages.permitDeleted'))
      })
      .catch(error => {
        let message

        switch (error.code) {
          default:
            message = t('alerts.tryLater')
        }

        showError(`${t('alerts.couldNotDeletePermit')} ${message}`)
      })
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setLoadChangePageList(page + 1)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLoadChangePerPageList(parseInt(event.target.value, 10))
  }

  const nowTime = (new Date).getTime()

  return (
    <>
      {permits && listLoading && (
        <LinearProgress style={{ height: 1, marginBottom: -1 }} />
      )}

      {permits && !permits.length && (
        <Container maxWidth="md" style={{ paddingTop: 36 }}>
          <Alert severity="info">{t('noRecords')}</Alert>
        </Container>
      )}

      {(!permits || Boolean(permits.length)) && (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                {!permits && (
                  <TableRow>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                )}
                {permits && (
                  <TableRow>
                    <TableCell>{t('zone')}</TableCell>
                    <TableCell>{t('assignedAt')}</TableCell>
                    <TableCell>{t('period')}</TableCell>
                    <TableCell>{t('quantity')}</TableCell>
                    <TableCell>{t('plateNumbers')}</TableCell>
                    <TableCell>{t('client')}</TableCell>
                    <TableCell>{t('notes')}</TableCell>
                    {modify && <TableCell colSpan={4} />}
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {!permits && (
                  <TableRow>
                    <TableCell>
                      <Skeleton />
                      <Skeleton style={{ margin: '8px 0' }} />
                      <Skeleton />
                      <Skeleton style={{ margin: '8px 0' }} />
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                )}
                {permits && permits
                  .map((permit, key) => {
                    const closedPermit = permit.valid_till && new Date(permit.valid_till).getTime() <= nowTime

                    return (<TableRow key={key} hover {...(closedPermit && { className: fadeTableRow })}>
                      <TableCell>
                        {permit.zone}
                      </TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>
                        {formatTimestamp(permit.assigned_at)}
                      </TableCell>
                      <TableCell>
                        {formatTimestamp(permit.valid_from, 'dd.MM.yyyy')} - {permit.valid_till ? formatTimestamp(permit.valid_till, 'dd.MM.yyyy') : '...'}
                      </TableCell>
                      <TableCell>
                        {permit.count}
                      </TableCell>
                      <TableCell style={{ fontFamily: '"Roboto Mono", monospace' }}>
                        {Array.from(permit.plate_numbers).sort().join(', ')}
                      </TableCell>
                      <TableCell>
                        {(permit.clients || []).join(', ')}
                      </TableCell>
                      <NotesTableCell>
                        {permit.notes}
                      </NotesTableCell>
                      {modify && (
                        <>
                          <TableCell align="center" style={{ width: 62 }}>
                            <Tooltip title={`#: ${permit.id}`} placement={'top'} arrow interactive>
                              <IconInfo fontSize="small" style={{ verticalAlign: 'middle' }} />
                            </Tooltip>
                          </TableCell>
                          <TableCell style={{ paddingLeft: 0, width: 46 }}>
                            {Boolean(permit.modify.updatable.length) && (
                              <IconButton
                                color="secondary"
                                size="small"
                                style={{ margin: '-5px 0px' }}
                                onClick={() => handleEdit(permit)}
                              >
                                <IconEdit />
                              </IconButton>
                            )}
                          </TableCell>
                          <TableCell style={{ paddingLeft: 0, width: 46 }}>
                            {permit.modify.stoppable && (
                              <IconButton
                                color="secondary"
                                size="small"
                                style={{ margin: '-5px 0' }}
                                onClick={() => handleStop(permit)}
                              >
                                <IconStop />
                              </IconButton>
                            )}
                          </TableCell>
                          <TableCell style={{ paddingLeft: 0, width: 46 }}>
                            {permit.modify.deletable && (
                              <IconButton
                                color="secondary"
                                size="small"
                                style={{ margin: '-5px 0px' }}
                                onClick={() => handleDelete(permit)}
                              >
                                <IconDelete />
                              </IconButton>
                            )}
                          </TableCell>
                        </>
                      )}
                    </TableRow>)
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={totalPermits}
            rowsPerPage={listPerPage}
            page={listPage - 1}
            labelRowsPerPage={`${t('show')}:`}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} / ${count !== -1 ? count : '...'}`}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </>
      )}
      {modify && <EditPermitDialog open={openEditPermitDialog} setOpen={setOpenEditPermitDialog} data={editPermitDialogData} />}
    </>
  )
}

export default List
