import React, { useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  createStyles,
  Divider,
  FormControlLabel, Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core'
import { DateTimePicker, KeyboardDatePicker } from '@material-ui/pickers'

import {
  addDays,
  addHours,
  addMonths,
  setHours,
  setMinutes,
  startOfDay,
  startOfMonth,
  addYears,
  endOfYear,
  sub,
  parse,
} from 'date-fns'
import { withRouter } from 'react-router'
import PrevNext from 'components/elements/prev-next/PrevNext'
import {
  SubscriptionStep,
  useSubscription,
} from 'components/providers/SubscriptionsProvider'
import { PaymentUi, Price } from './Step_4_Confirm.css'
import { makeStyles } from '@material-ui/core/styles'
import { ZoneBubble } from 'components/elements/zone-list/ZoneList.css'
import { formatMoney } from 'utils'
import { DurationType } from 'models/ZoneOfferModel'
import { Severity, useAlert } from 'components/providers/AlertProvider'
import { useTranslation } from 'react-i18next'
import { usePreloader } from 'components/providers/PreloaderProvider'
import { ROUTE } from 'constants/Routes'

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      padding: '9px',
    },
    table: {
      width: '100%',
    },
    formControlLabel: {
      fontSize: '15px',
    },
  }),
)

export default withRouter(({ history }) => {
  const {
    setStep,
    selectedZone,
    selectedCars,
    offer,
    purchase,
    purchaseSubscription,
  } = useSubscription()
  const [date, setDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date>(new Date())
  const [showCalendar, setShowCalendar] = useState(false)
  const [amount, setAmount] = useState(null)
  const { showPreloader, hidePreloader } = usePreloader()
  const { openAlert } = useAlert()
  const { t, i18n } = useTranslation()

  const [checkbox, setCheckbox] = useState<{
    autoRenew: boolean
  }>({
    autoRenew: false,
  })

  const proceed = async (next: boolean) => {
    if (!next) {
      return setStep(SubscriptionStep.SELECT_VEHICLE)
    }

    showPreloader()
    const { successful, code, extra, redirectUrl, complete } = await purchase({
      autoRenew: checkbox.autoRenew,
      date,
      language: i18n.language,
    })

    if (!successful) {
      hidePreloader()

      let severity = Severity.WARNING
      let message
      switch (code) {
        case 6:
          message = t('subscriptionPurchase.alertPurchaseFailedSubscriptionLimitBody', { limit: extra.limit, available: Math.max(extra.limit - extra.active, 0) })
          break

        case 10:
          message = t('subscriptionPurchase.alertPurchaseFailedVehicleOverlapBody', { plates: extra.plates.join(', ') })
          break

        default:
          severity = Severity.ERROR
          message = t('subscriptionPurchase.alertPurchaseFailedBody')
      }

      return openAlert({
        severity,
        title: t('subscriptionPurchase.alertPurchaseFailedTitle'),
        message,
      })
    }

    if (complete) {
      hidePreloader()
      history.push(ROUTE.SUBSCRIPTIONS.HOME)
      return openAlert({
        severity: Severity.SUCCESS,
        title: t('subscriptionPurchase.alertActivationSuccessfulTitle'),
        message: t('subscriptionPurchase.alertActivationSuccessfulBody'),
      })
    }

    location.href = redirectUrl
  }

  const nextDisabled = false,
    classes = useStyles()

  if (!selectedZone) {
    return null
  }

  const updateDates = (selectedDate: Date) => {
    const { durationLength, durationType } = offer.selected
    let finalDate: Date = new Date()

    switch (durationType) {
      case DurationType.daysAbsolute:
      case DurationType.daysRelative:
        if (durationType === DurationType.daysAbsolute) {
          selectedDate = startOfDay(selectedDate)
        }
        finalDate = sub(addDays(selectedDate, durationLength), { minutes: 1 })
        break

      case DurationType.hours:
        finalDate = addHours(selectedDate, durationLength)
        break

      case DurationType.monthsAbsolute:
      case DurationType.monthsRelative:
        if (durationType === DurationType.monthsAbsolute) {
          selectedDate = startOfMonth(selectedDate)
        }
        finalDate = setMinutes(
          setHours(addMonths(selectedDate, durationLength), 23),
          59,
        )
        finalDate = sub(finalDate, { days: 1 })
        break
    }

    setEndDate(finalDate)
    setDate(selectedDate)
  }

  useEffect(() => {
    if (purchaseSubscription) {
      updateDates(addDays(parse(purchaseSubscription.validTo, 'dd.MM.yyyy', new Date()), 1))
    } else {
      updateDates(new Date())
    }

    setAmount(offer.selected.price * offer.amount)
  }, [])

  return (
    <Box p={0}>
      <Grid container justify="space-between">
        <Grid item xs={12} md={5}>
        <KeyboardDatePicker
          open={showCalendar}
          label={t('startDate')}
          inputProps={{
            readOnly: true,
            style: { padding: '15px', background: '#fff' },
          }}
          margin="normal"
          format="dd.LL.yyyy"
          minDate={
            offer.selected.durationType === DurationType.monthsAbsolute
              ? startOfMonth(new Date())
              : new Date()
          }
          maxDate={
            offer.selected.durationType === DurationType.monthsAbsolute
              ? startOfMonth(endOfYear(addYears(new Date(), 1)))
              : addYears(new Date(), 1)
          }
          value={date}
          {...(offer.selected.durationType === DurationType.monthsAbsolute && {
            views: ['year', 'month'],
            openTo: 'month',
          })}
          fullWidth
          onChange={value => {
            updateDates(value)
            setShowCalendar(false)
          }}
          onClose={() => {
            setShowCalendar(false)
          }}
          onClick={() => {
            setShowCalendar(true)
          }}
        />
        </Grid>

        {offer.selected.durationType !== DurationType.infinity && (
          <Grid item xs={12} md={5}>
            <DateTimePicker
              readOnly
              label={t('endTime')}
              inputProps={{
                style: { padding: '15px', background: '#fff' },
              }}
              margin="normal"
              format="dd.LL.yyyy HH:mm"
              value={endDate}
              onChange={updateDates}
              variant="inline"
              fullWidth
            />
          </Grid>
        )}
      </Grid>

      <TableContainer>
        <Table
          className={classes.table}
          size="medium"
        >
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography style={{ fontSize: '14px' }} color="textSecondary">
                  {t('zone')}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <ZoneBubble style={selectedZone.shortName.length > 3 ? { fontSize: '10px' } : undefined}>{selectedZone.shortName}</ZoneBubble>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography style={{ fontSize: '14px' }} color="textSecondary">
                  {t('subscription')}
                </Typography>
              </TableCell>
              <TableCell align="right">{offer.selected.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography style={{ fontSize: '14px' }} color="textSecondary">
                  {t('plateNumbers')}
                </Typography>
              </TableCell>
              <TableCell align="right" style={{ fontFamily: '"Roboto Mono", monospace' }}>
                {selectedCars.map(c => c.plateNumber).join(', ')}
              </TableCell>
            </TableRow>
            {Boolean(offer.selected.price) && (
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography style={{ fontSize: '14px' }} color="textSecondary">
                    {t('price')}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {formatMoney(offer.selected.price)}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography style={{ fontSize: '14px' }} color="textSecondary">
                  {t('quantity')}
                </Typography>
              </TableCell>
              <TableCell align="right">{offer.amount}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: offer.selected.autoRenew ? 'block' : 'none' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkbox.autoRenew}
              onChange={e =>
                setCheckbox({ ...checkbox, autoRenew: e.target.checked })
              }
              name="checkedB"
              color="primary"
            />
          }
          label={
            <Typography className={classes.formControlLabel}>
              {t('subscriptionPurchase.autoRenew')}
            </Typography>
          }
          style={{margin: '20px 0'}}
        />
        <Divider />
      </div>
      <PaymentUi>
        {Boolean(amount) && <>
          <Typography
            style={{ fontSize: '14px' }}
            color="textSecondary"
            gutterBottom
          >
            {t('total')}
          </Typography>
          <Price>{formatMoney(amount)}</Price>
        </>}
        <PrevNext
          style={{ margin: '40px 0' }}
          onClick={proceed}
          nextTitle={amount ? t('purchase') : t('activate')}
          nextDisabled={nextDisabled}
        />
      </PaymentUi>
    </Box>
  )
})
