import * as yup from 'yup'
import {
  isValid,
  parse,
  format,
  format as dateFnsFormat,
  subDays,
  startOfWeek,
  addDays,
  lastDayOfMonth,
  startOfMonth,
  subMonths,
} from 'date-fns'
import * as permissionType from 'constants/permissions'

export const delay = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms))

export enum DatePreset {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  THIS_WEEK = 'THIS_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  PREV_WEEK = 'PREV_WEEK',
  PREV_MONTH = 'PREV_MONTH',
  OTHER = 'OTHER',
}

export const formatTimestamp = (timestamp: string, format?: string) => {
  return dateFnsFormat(new Date(timestamp), format || 'dd.MM.yyyy HH:mm')
}

export const formatMoney = (value: number | string) => {
  return `${(Number(value) / 100).toFixed(2)} €`
}

export const hasPermission = (permissions: any, permission: string) => {
  const permissionItem = (permissions || []).filter(
    (item: { name: string; allowed: boolean }) =>
      item.name === permission && item.allowed,
  )

  return !!(permissionItem || [])
    .map((item: any) => item.allowed)
    .reduce((prev: any, next: any) => prev + next, 0)
}

export const myParkingLotPermission = (permissions): boolean => {
  return (
    hasPermission(
      permissions,
      permissionType.PAGE_MY_PARKING_LOT_PARKING_LOT,
    ) ||
    hasPermission(permissions, permissionType.PAGE_MY_PARKING_LOT_ANALYTICS)
  )
}

export const subscriptionsPermission = (permissions): boolean => {
  return (
    hasPermission(permissions, permissionType.PAGE_SUBSCRIPTIONS_BUY) ||
    hasPermission(permissions, permissionType.PAGE_SUBSCRIPTIONS_FREE_TIME)
  )
}

export const permissionSubscriptionsFreeTime = (permissions): boolean => {
  return hasPermission(permissions, permissionType.PAGE_SUBSCRIPTIONS_FREE_TIME)
}

export const subscriptionsSubscriptionsPermission = (permissions): boolean => {
  return hasPermission(permissions, permissionType.PAGE_SUBSCRIPTIONS_SUBSCRIPTIONS)
}

export const subscriptionsPermitsPermission = (permissions): boolean => {
  return hasPermission(permissions, permissionType.PAGE_SUBSCRIPTIONS_PERMITS)
}

export const parkingLotPermission = (permissions): boolean => {
  return hasPermission(
    permissions,
    permissionType.PAGE_MY_PARKING_LOT_PARKING_LOT,
  )
}
export const parkingLotModifyPermission = (permissions): boolean => {
  return hasPermission(
    permissions,
    permissionType.PAGE_MY_PARKING_LOT_PARKING_LOT_MODIFY,
  )
}
export const parkingLotBarriersPermission = (permissions): boolean => {
  return hasPermission(
    permissions,
    permissionType.PAGE_MY_PARKING_LOT_PARKING_LOT_BARRIERS,
  )
}

export const paymentsPermission = (permissions): boolean => {
  return hasPermission(
    permissions,
    permissionType.PAGE_MY_PARKING_LOT_PAYMENTS,
  )
}
export const analyticsPermission = (permissions): boolean => {
  return hasPermission(
    permissions,
    permissionType.PAGE_MY_PARKING_LOT_ANALYTICS,
  )
}

export function yupDateTimeField(options: { required?: boolean } = {}) {
  return options.required
    ? yup.string()
      .trim()
      .required()
      .test({
        test: value => {
          return isDateTimeFieldValid(value)
        },
      })
    : yup.string()
      .trim()
      .test({
        test: value => {
          return value == '' || isDateTimeFieldValid(value)
        },
      })
}

export function isDateTimeFieldValid(value: string) {
  return isValid(parseDateTimeField(value))
}

export function yupDateField(options: { required?: boolean } = {}) {
  return options.required
    ? yup.string()
      .trim()
      .required()
      .test({
        test: value => {
          return isDateFieldValid(value)
        },
      })
    : yup.string()
      .trim()
      .test({
        test: value => {
          return value == '' || isDateFieldValid(value)
        },
      })
}

export function isDateFieldValid(value: string) {
  return isValid(parseDateField(value))
}

export function getPresetDate(
  preset: DatePreset,
): { startDate: Date; endDate: Date } {
  const today = new Date()
  const startOfThisWeek = startOfWeek(today, { weekStartsOn: 1 })
  const yesterday = subDays(today, 1)
  switch (preset) {
    case DatePreset.YESTERDAY:
      return { endDate: yesterday, startDate: yesterday }
    case DatePreset.THIS_WEEK:
      return {
        startDate: startOfThisWeek,
        endDate: addDays(startOfThisWeek, 6),
      }
    case DatePreset.PREV_WEEK:
      return {
        startDate: subDays(startOfThisWeek, 7),
        endDate: subDays(startOfThisWeek, 1),
      }
    case DatePreset.THIS_MONTH:
      return { startDate: startOfMonth(today), endDate: lastDayOfMonth(today) }

    case DatePreset.PREV_MONTH:
      const previousMonthDate = subMonths(today, 1)
      return {
        startDate: startOfMonth(previousMonthDate),
        endDate: lastDayOfMonth(previousMonthDate),
      }

    default:
      return { endDate: today, startDate: today }
  }
}

export function formatToDateTimeField(date: Date) {
  return format(date, 'dd.MM.yyyy HH:mm')
}
export function formatToDateField(date: Date) {
  return format(date, 'dd.MM.yyyy')
}

export function parseDateField(value: string) {
  return parse(value, 'dd.MM.yyyy', new Date())
}
export function parseDateTimeField(value: string) {
  return parse(value, 'dd.MM.yyyy HH:mm', new Date())
}

export function formatDateFieldToISO(value: string) {
  return parseDateField(value).toISOString()
}
export function formatDateTimeFieldToISO(value: string) {
  return parseDateTimeField(value).toISOString()
}

export function onlyNumeric(st: string): string {
  return st.replace(/[^0-9]/gi, '')
}
export function removeWhitespace(val: string): string {
  try {
    return val.replace(/\s/g, '')
  } catch (e) {
    return val
  }
}

export const normalizePlate = (plate: string): string => {
  const from = 'АаВвЕеЁёИиЙйКкМмНнОоРрСсТтУуХхĀāČčĒēĢģĪīĶķĻļŅņŌōŖŗŠšŪūŽž' // Correct common Russian alphabet characters
  const to = 'AABBEEEENNNNKKMMHHOOPPCCTTYYXXAACCEEGGIIKKLLNNOORRSSUUZZ' // as well as possible Russian/Latvian mistypes
  return plate
    .replace(/[^0-9A-Za-z]/g, c => {
      const i = from.indexOf(c)
      return i >= 0 ? to[i] : ''
    })
    .toUpperCase()
}
