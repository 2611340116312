import styled from 'styled-components'

export const Wrap = styled.div``

export const Elements = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  justify-content: space-between;
`

export const Element = styled.li<{ active?: boolean }>`
  width: 100%;
  margin-right: 5px;
  background: yellow;
  &:last-child {
    margin-right: 0;
  }
  background: ${({ theme, active }) =>
    active ? theme.palette.primary.main : theme.palette.grey['700']};
  color: ${({ theme, active }) =>
    active ? '#fff' : theme.palette.primary.main};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  input {
    background: ${({ theme, active }) =>
      active ? theme.palette.primary.main : theme.palette.grey['700']};
    color: ${({ theme, active }) =>
      active ? '#fff' : theme.palette.primary.main};
  }
`

export const Label = styled.label`
  display: block;
  margin: 30px 0 10px;
`
