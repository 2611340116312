import React, { FunctionComponent } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  frame: {
    width: '100%',
    minWidth: 1140,
  },
}))

export type Props = {}
export type Functions = {}

export type AllProps = Props & Functions

const Analytics: FunctionComponent<AllProps> = ({}) => {
  const styles = useStyles()

  return (
    <Box p={1.5}>
      <iframe
        width="1140"
        height="541.25"
        src="https://app.powerbi.com/reportEmbed?reportId=593e0749-9a18-4aee-bef1-d99cb3b12eb8&autoAuth=true&ctid=2787df5f-9501-4d10-89ee-c1ecbc26db45&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtZXVyb3BlLWItcHJpbWFyeS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D"
        frameBorder="0"
        allowFullScreen={true}
        className={styles.frame}
      ></iframe>
    </Box>
  )
}

export default Analytics
