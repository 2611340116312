import React, { FunctionComponent, useRef, useState } from 'react'
import useFormal from '@kevinwolf/formal-web'
import * as yup from 'yup'
import { SelectField } from 'components/elements/SelectField'
import { useTranslation } from 'react-i18next'
import { TextField } from 'components/elements/TextField'
import { DateTimeField } from 'components/elements/DateTimeField'
import { useSubscriptions } from 'components/views/subscriptions/subscriptions/SubscriptionsProvider'
import { formatDateTimeFieldToISO, parseDateTimeField, yupDateTimeField } from 'utils'
import { PlateNumberField } from 'components/elements/PlateNumberField'
import AddSubscriptionDialog from 'components/views/subscriptions/subscriptions/partials/AddSubscriptionDialog'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import Grid from '@material-ui/core/Grid'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Popper from '@material-ui/core/Popper'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import download from 'downloadjs'
import { useApi } from 'components/providers/ApiProvider'

type Props = {}
type Functions = {}
type AllProps = Props & Functions

const Toolbar: FunctionComponent<AllProps> = ({}) => {
  const { t, i18n } = useTranslation()
  const { filter, setFilter, setLoadSearchList, listLoading, listZones, addZones } = useSubscriptions()
  const api = useApi()

  const [openSearchButtonDropdown, setOpenSearchButtonDropdown] = useState(false)
  const searchButtonRef = useRef(null)
  let exportClicked = false
  const [exportLoading, setExportLoading] = useState(false)

  const [openAddSubscriptionDialog, setOpenAddSubscriptionDialog] = useState(false)

  const handleSearchButtonDropdownToggle = () => {
    setOpenSearchButtonDropdown((prevOpen) => !prevOpen)
  }

  const handleSearchButtonDropdownClose = (event) => {
    if (searchButtonRef.current && searchButtonRef.current.contains(event.target)) {
      return
    }

    setOpenSearchButtonDropdown(false)
  }

  const handleExportClick = () => {
    exportClicked = true
    formal.submit()
  }

  const schema = yup.object().shape({
    zone: yup.number(),
    plateNumber: yup.string(),
    subscriptionType: yup.number(),
    client: yup.string().trim(),
    purchasedFrom: yupDateTimeField(),
    purchasedTill: yupDateTimeField(),
    notes: yup.string().trim(),
  })

  const formal = useFormal(filter, {
    schema,
    onSubmit: values => {
      if (exportClicked) {
        setExportLoading(true)

        api.exportSubscriptionsList({
          zone_id: values.zone || undefined,
          plate_number: filter.plateNumber.toUpperCase() || undefined,
          subscription_type_id: filter.subscriptionType || undefined,
          client: filter.client || undefined,
          ...(filter.purchasedFrom && { purchased_from: formatDateTimeFieldToISO(filter.purchasedFrom) }),
          ...(filter.purchasedTill && { purchased_till: formatDateTimeFieldToISO(filter.purchasedTill) }),
          notes: filter.notes || undefined,
        })
          .then(blob => {
            setExportLoading(false)

            download(blob, 'subscriptions.xlsx')
          })
          .catch(() => {
            setExportLoading(false)

            alert(`${t('alerts.couldNotExportData')} ${t('alerts.tryLater')}`)
          })
      } else {
        setFilter(schema.cast(values))
        setLoadSearchList()
      }
    },
  })

  return (
    <>
      {Boolean(addZones.length) && (
        <Box marginTop={1} marginBottom={2}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<AddIcon />}
            onClick={() => setOpenAddSubscriptionDialog(true)}
          >
            {t('newSubscription')}
          </Button>
        </Box>
      )}
      <form {...formal.getFormProps()}>
        <Grid container spacing={1} style={{ padding: '16px 0' }}>
          <SelectField
            GridProps={{ xs: 6, md: 3 }}
            label={t('zone')}
            optionsCustom={[
              {
                label: t('allZones'),
                id: 0,
              },
              ...listZones.sort((a, b) => a.code.localeCompare(b.code)).map(zone => {
                const localization = zone.localizations.find(l => l.language == i18n.language)

                return {
                  label: `${zone.code}${localization && localization.name ? ` ${localization.name}` : ''}`,
                  id: zone.id,
                }
              }),
            ]}
            size="small"
            {...formal.getFieldProps('zone')}
            onChange={e => {
              formal.change('zone', e.target.value)
              formal.change('subscriptionType', 0)
            }}
          />
          <SelectField
            GridProps={{ xs: 6, md: 3 }}
            label={t('type')}
            optionsCustom={[
              {
                label: t('allTypes'),
                id: 0,
              },
              ...((listZones.find(z => z.id == formal.values.zone) || {}).subscription_types || []).map(offer => ({
                label: offer.name,
                id: offer.id,
              })),
            ]}
            size="small"
            {...formal.getFieldProps('subscriptionType')}
          />
          <PlateNumberField
            GridProps={{ xs: 6, md: 3 }}
            clearable={true}
            size="small"
            {...formal.getFieldProps('plateNumber')}
          />
          <TextField
            GridProps={{ xs: 6, md: 3 }}
            label={t('client')}
            size="small"
            {...formal.getFieldProps('client')}
          />
          <DateTimeField
            GridProps={{ xs: 12, sm: 6, md: true }}
            label={`${t('purchasedAt')} - ${t('from')}`}
            size="small"
            {...formal.getFieldProps('purchasedFrom')}
            initialFocusedDate={(new Date).setHours(0, 0, 0, 0)}
            {
              ...(formal.values.purchasedTill && { maxDate: parseDateTimeField(formal.values.purchasedTill) })
            }
            clearable
          />
          <DateTimeField
            GridProps={{ xs: 12, sm: 6, md: true }}
            label={`${t('purchasedAt')} - ${t('till')}`}
            size="small"
            {...formal.getFieldProps('purchasedTill')}
            initialFocusedDate={(new Date).setHours(23, 59, 59, 99)}
            {
              ...(formal.values.purchasedFrom && { minDate: parseDateTimeField(formal.values.purchasedFrom) })
            }
            clearable
          />
          <TextField
            GridProps={{ xs: 12, md: true }}
            label={t('notes')}
            size="small"
            {...formal.getFieldProps('notes')}
          />
          <Grid item xs={12} md={'auto'}>
            <ButtonGroup variant="contained" color="primary" fullWidth ref={searchButtonRef} style={{ height: '100%', maxHeight: 40 }}>
              <Button
                type="submit"
                startIcon={<SearchIcon />}
                disabled={formal.isSubmitting || listLoading}
              >
                {t('search')}
              </Button>
              <Button
                size="small"
                style={{ flex: 1 }}
                onClick={handleSearchButtonDropdownToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper open={openSearchButtonDropdown} placement="bottom-end" anchorEl={searchButtonRef.current} transition disablePortal>
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: 'right top',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleSearchButtonDropdownClose}>
                      <MenuList>
                        <MenuItem
                          disabled={exportLoading}
                          onClick={() => handleExportClick()}
                        >
                          {`${t('export')} (XLSX)`}
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
        </Grid>
      </form>
      <AddSubscriptionDialog open={openAddSubscriptionDialog} setOpen={setOpenAddSubscriptionDialog} />
    </>
  )
}

export default Toolbar
