import React, { FunctionComponent, useState } from 'react'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import useFormal from '@kevinwolf/formal-web'
import * as yup from 'yup'
import { IParkingLotSessionSpecialRatesRateData, useApi } from 'components/providers/ApiProvider'
import { useParkingLot } from '../ParkingLotProvider'
import { useMessage } from 'components/elements/Message'
import { DraggableDialog, DraggableDialogTitle } from 'components/elements/DraggableDialog'
import { useTranslation } from 'react-i18next'
import SelectField from 'components/elements/SelectField'
import Alert from '@material-ui/lab/Alert'

export interface EditSpecialRateDialogData {
  sessionId: number
  sessionHasSpecialRate: boolean
}

type Props = {
  open: boolean
  data: EditSpecialRateDialogData
}
type Functions = {
  setOpen(value: boolean): void
}
type AllProps = Props & Functions

const EditSpecialRateDialog: FunctionComponent<AllProps> = ({ open, setOpen, data }) => {
  if (!data) {
    return null
  }

  const { t } = useTranslation()
  const api = useApi()
  const { setLoadList } = useParkingLot()
  const { showSuccess, showError } = useMessage()

  const [specialRates, setSpecialRates] = useState<IParkingLotSessionSpecialRatesRateData[]>(null)

  const handleEnter = () => {
    api.getParkingLotSessionSpecialRates({
      session_id: data.sessionId,
    })
      .then(data => {
        setSpecialRates(data.special_rates)
      })
      .catch(() => {
      })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleExited = () => {
    setSpecialRates(null)
    formal.change('specialRate', initialValues.specialRate)
    formal.clearErrors()
  }

  const handleCancelSpecialRate = () => {
    if (!confirm(t('confirms.cancelSpecialRate'))) {
      return
    }

    api.cancelParkingLotSessionSpecialRate({
      session_id: data.sessionId,
    })
      .then(data => {
        if (!data || !data.status) {
          throw new Error
        }

        handleClose()

        setLoadList()

        showSuccess(t('messages.specialRateCanceled'))
      })
      .catch(error => {
        let message

        switch (error.code) {
          case 14:
            message = t('alerts.parkingSessionHasAdditionalPayment')
            break
          default:
            message = t('alerts.tryLater')
        }

        showError(`${t('alerts.couldNotCancelSpecialRate')} ${message}`)
      })
  }

  const initialValues = {
    specialRate: 0,
  }

  const schema = yup.object().shape({
    specialRate: yup.number().required().min(1),
  })

  const formal = useFormal(initialValues, {
    schema,
    onSubmit: values => {
      api.assignParkingLotSessionSpecialRate({
        session_id: data.sessionId,
        offer_id: values.specialRate,
      })
        .then(data => {
          if (!data || !data.status) {
            throw new Error
          }

          handleClose()

          setLoadList()

          showSuccess(t('messages.specialRateAssigned'))
        })
        .catch(error => {
          let message

          switch (error.code) {
            case 3:
              message = t('alerts.checkInput')
              break
            case 14:
              message = t('alerts.parkingSessionHasAdditionalPayment')
              break
            default:
              message = t('alerts.tryLater')
          }

          showError(`${t('alerts.couldNotAssignSpecialRate')} ${message}`)
        })
    },
  })

  return (
    <DraggableDialog fullWidth open={open} onEnter={handleEnter} onClose={handleClose} onExited={handleExited}>
      <DraggableDialogTitle>{t('assignSpecialRate')}</DraggableDialogTitle>
      <form {...formal.getFormProps()} noValidate>
        <DialogContent>
          <Grid container>
            {specialRates && (
              <>
                {Boolean(specialRates.length) ? (
                  <SelectField
                    GridProps={{ xs: 12 }}
                    label={t('rate')}
                    required
                    optionsCustom={[
                      {
                        label: t('select'),
                        id: 0,
                      },
                      ...specialRates.map(offer => ({
                        id: offer.id,
                        label: `${offer.name}${offer.assigners ? ` (${offer.assigners})` : ''}`,
                      })),
                    ]}
                    {...formal.getFieldProps('specialRate')}
                  />
                ) : (
                  <Grid item xs={12} style={{ padding: '0 12px' }}>
                    <Alert severity="info">
                      {t('noSpecialRates')}
                    </Alert>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {data.sessionHasSpecialRate && (
            <Button
              variant="contained"
              color="secondary"
              style={{ marginRight: 'auto' }}
              onClick={handleCancelSpecialRate}
            >
              {t('cancelSpecialRate')}
            </Button>
          )}
          <Button
            variant="outlined"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          {specialRates && Boolean(specialRates.length) && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={formal.isSubmitting}
            >
              {t('assign')}
            </Button>
          )}
        </DialogActions>
      </form>
    </DraggableDialog>
  )
}

export default EditSpecialRateDialog
