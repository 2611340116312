import React, { FunctionComponent } from 'react'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import useFormal from '@kevinwolf/formal-web'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useApi } from 'components/providers/ApiProvider'
import { useMessage } from 'components/elements/Message'
import { DraggableDialog, DraggableDialogTitle } from 'components/elements/DraggableDialog'
import { usePermits } from 'components/views/subscriptions/permits/PermitsProvider'
import { TextField } from 'components/elements/TextField'
import { formatDateFieldToISO, formatToDateField, yupDateField } from 'utils'
import { DateField } from 'components/elements/DateField'

export interface EditPermitDialogData {
  id: number
  validFrom: string
  validTill: string
  count: number
  plateNumbers: string[]
  notes: string
  updatable: string[]
}

type Props = {
  open: boolean
  data: EditPermitDialogData
}
type Functions = {
  setOpen(value: boolean): void
}
type AllProps = Props & Functions

const EditPermitDialog: FunctionComponent<AllProps> = ({ open, setOpen, data }) => {
  if (!data) {
    return null
  }

  const { t } = useTranslation()
  const api = useApi()
  const { setLoadList } = usePermits()
  const { showSuccess, showError } = useMessage()

  const updateValidTill = data.updatable.includes('valid_till')
  const updateCount = data.updatable.includes('count')
  const updatePlateNumbers = data.updatable.includes('plate_numbers')

  const handleEnter = () => {
    if (updateValidTill && data.validTill) {
      formal.change('validTill', formatToDateField(new Date(data.validTill)))
    }
    if (updateCount) {
      formal.change('count', data.count)
    }
    if (updatePlateNumbers) {
      formal.change('plateNumbers', Array.from(data.plateNumbers).sort().join(', '))
    }
    formal.change('notes', data.notes)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleExited = () => {
    formal.change('validTill', initialValues.validTill)
    formal.change('count', initialValues.count)
    formal.change('plateNumbers', initialValues.plateNumbers)
    formal.change('notes', initialValues.notes)
    formal.clearErrors()
  }

  const initialValues = {
    validTill: '',
    count: 1,
    plateNumbers: '',
    notes: '',
  }

  const schema = yup.object().shape({
    validTill: yupDateField(),
    count: yup.number().required().integer().min(1).max(1000),
    plateNumbers: yup.string().matches(/^[0-9a-zA-Z, \n]*$/),
    notes: yup.string().trim(),
  })

  const formal = useFormal(initialValues, {
    schema,
    onSubmit: values => {
      values = schema.cast(values)

      api.updatePermit({
        permit_id: data.id,
        ...(updateValidTill && {
          till: values.validTill ? formatDateFieldToISO(values.validTill) : null,
        }),
        ...(updateCount && {
          count: values.count,
        }),
        ...(updatePlateNumbers && {
          plate_numbers: values.plateNumbers ? values.plateNumbers.split(/[,\n]/).map(plateNumber => plateNumber.replace(/\s/g, '').toUpperCase()).filter((plateNumber, index, self) => plateNumber && self.indexOf(plateNumber) === index) : [],
        }),
        notes: values.notes,
      })
        .then(data => {
          if (!data || !data.status) {
            throw new Error
          }

          handleClose()

          setLoadList()

          showSuccess(t('messages.permitUpdated'))
        })
        .catch(error => {
          let message

          switch (error.code) {
            case 3:
              message = t('alerts.checkInput')
              break
            case 9:
              message = t('alerts.maxPermitVehiclesReached')
              break
            case 10:
              message = t('alerts.permitVehicleOverlap', { plates: error.extra.plates.join(', ') })
              break
            default:
              message = t('alerts.tryLater')
          }

          showError(`${t('alerts.couldNotUpdatePermit')} ${message}`)
        })
    },
  })

  return (
    <DraggableDialog fullWidth open={open} onEnter={handleEnter} onClose={handleClose} onExited={handleExited}>
      <DraggableDialogTitle>{t('editPermit')}</DraggableDialogTitle>
      <form {...formal.getFormProps()} noValidate>
        <DialogContent>
          <Grid container spacing={2}>
            {updateValidTill && (
              <DateField
                GridProps={{ xs: 12 }}
                label={t('endDate')}
                disablePast
                minDate={data.validFrom}
                autoFocus
                clearable
                {...formal.getFieldProps('validTill')}
              />
            )}
            {updateCount && (
              <TextField
                GridProps={{ xs: 12 }}
                type="number"
                label={t('quantity')}
                required
                inputProps={{
                  min: 1,
                  max: 1000,
                }}
                {...(!updateValidTill && { autoFocus: true })}
                {...formal.getFieldProps('count')}
              />
            )}
            {updatePlateNumbers && (
              <TextField
                GridProps={{ xs: 12 }}
                label={t('plateNumbers')}
                multiline
                autoComplete="off"
                helperText={`${t('example')}: AA0001, AA0002`}
                inputProps={{
                  style: {
                    fontFamily: '"Roboto Mono", monospace',
                    lineHeight: '21px',
                    textTransform: 'uppercase',
                    marginTop: -1,
                    marginBottom: -1,
                    maxHeight: 63,
                    overflow: 'auto',
                  },
                }}
                {...(!updateValidTill && !updateCount && { autoFocus: true })}
                {...formal.getFieldProps('plateNumbers')}
              />
            )}
            <TextField
              GridProps={{ xs: 12 }}
              label={t('notes')}
              {...(!updateValidTill && !updateCount && !updatePlateNumbers && { autoFocus: true })}
              {...formal.getFieldProps('notes')}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formal.isSubmitting}
          >
            {t('edit')}
          </Button>
        </DialogActions>
      </form>
    </DraggableDialog>
  )
}

export default EditPermitDialog
