import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useFormal from '@kevinwolf/formal-web'
import crypto from 'crypto'
import { SubmitButton } from '../elements/SubmitButton'
import { TextField } from '../elements/TextField'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { TopBar } from '../elements/Navigation'
import { Button, Divider, makeStyles, Theme } from '@material-ui/core'
import { useConfig } from 'components/providers/ConfigProvider'
import { useApi } from 'components/providers/ApiProvider'
import * as yup from 'yup'
import queryString from 'query-string'
import { ROUTE } from 'constants/Routes'
import { MobillyIcon } from 'Elements/MobillyIcon'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 768,
    marginTop: theme.spacing(10),
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(20),
    },
  },
  divider: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
}))

const randomString = (length: number): string => {
  let str = ''

  while (str.length < length) {
    str += Math.random()
      .toString(36)
      .substring(2)
  }

  return str.substring(0, length)
}

const base64URLEncode = (buffer: Buffer): string => {
  return buffer
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')
}

const sha256 = (str: string): Buffer => {
  return crypto
    .createHash('sha256')
    .update(str)
    .digest()
}

const handleLoginMobilly = config => {
  const verifier = base64URLEncode(crypto.randomBytes(32))
  localStorage.setItem('auth.verifier', verifier)

  const parameters = queryString.stringify(
    {
      client_id: config.oauthMobillyClientId,
      redirect_uri: location.origin,
      response_type: 'code',
      scope: 'openid',
      state: randomString(32),
      code_challenge: base64URLEncode(sha256(verifier)),
      code_challenge_method: 'S256',
      prompt: 'none',
      response_mode: 'query',
    },
    { sort: false },
  )

  location.href = `${config.myMobillyUri}/oauth/authorize?${parameters}`
}

export function Authentication() {
  const api = useApi()
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const styles = useStyles()
  const config = useConfig()

  useEffect(() => {
    async function checkAuth()  {
      const parameters = queryString.parse(location.search);
      try {

        if (typeof parameters.code === 'string') {
            if ( parameters.extra ) {
              await api.mansMobillyAuthenticate(parameters.code);
            } else {
              const verifier = localStorage.getItem('auth.verifier');
              localStorage.removeItem('auth.verifier');
              await api.mansMobillyAuthenticate(parameters.code, verifier, location.origin);
            }
        } else if (typeof parameters.refreshCode === 'string') {
            await api.codeAuthenticate(parameters.refreshCode);
        }
      } catch {
        alert(`${t('alerts.loginFailed')} ${t('alerts.tryLater')}`);
        return;
      }

      if (api.isLoggedOn()) {
        let navigateTo = ROUTE.MY_PARKING_LOT.MAIN;

        if (typeof parameters.extra === 'string') {
          let extra: any;

          try {
            extra = JSON.parse(decodeURIComponent(parameters.extra))
          } catch (e) {
            extra = {}
          }

          if (['lv', 'en', 'ru'].includes(extra.lang)) {
            await i18n.changeLanguage(extra.lang);
          }

          switch (extra.navigateTo) {
            case 'subscriptions':
              navigateTo = ROUTE.SUBSCRIPTIONS.HOME;
              break;
            case 'subscriptions/buy':
              navigateTo = ROUTE.SUBSCRIPTIONS.BUY
              if (extra.zone) {
                navigateTo = `${navigateTo}?zone=${extra.zone}`;
              }
              break;
          }
        }

        history.push(navigateTo)
      }
    }

    checkAuth();
  }, [config, history]);

  const initialValues = {
    username: '',
    password: '',
  }

  const schema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required(),
  })

  const formal = useFormal(initialValues, {
    schema,
    onSubmit: values => {
      api.userAuthenticate(values.username, values.password)
        .then(data => {
          history.push(ROUTE.MY_PARKING_LOT.MAIN)
        })
        .catch(error => {
          let message

          switch (error.status) {
            case 400:
              message = t('alerts.checkInput')
              break
            case 403:
              message = t('alerts.incorrectUsernameOrPassword')
              break
            default:
              message = t('alerts.tryLater')
          }

          alert(`${t('alerts.loginFailed')} ${message}`)
        })
    },
  })

  return (
    <>
      <TopBar />
      <Grid container direction="column" alignItems="center" justify="center" className={styles.container}>
        <Grid item xs={10} sm={6}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            startIcon={<MobillyIcon width={20} />}
            style={{ paddingTop: 16, paddingBottom: 16 }}
            onClick={() => handleLoginMobilly(config)}
          >
            {t('logInWithMobilly')}
          </Button>

          <Box paddingBottom={2} textAlign="center">
            <Divider className={styles.divider} />
            {t('or')}
          </Box>

          <form {...formal.getFormProps()}>
            <Grid container spacing={2}>
              <TextField
                GridProps={{ xs: 12 }}
                label={t('username')}
                {...formal.getFieldProps('username')}
              />
              <TextField
                GridProps={{ xs: 12 }}
                type="password"
                label={t('password')}
                {...formal.getFieldProps('password')}
              />
              <SubmitButton
                GridProps={{ xs: 12 }}
                buttonText={t('logIn')}
                size="large"
                color="secondary"
                disabled={formal.isSubmitting}
              />
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  )
}
