import React, { FunctionComponent } from 'react'
import {
  Wrap,
  ButtonHolder,
  ButtonWrap,
  Title,
  Subtitle,
  Label,
} from './FatRadioButtonList.css'
import { Typography } from '@material-ui/core'


export type RadioButton = {
  id: any
  title: string
  subtitle: string
}
export type Props = {
  buttons: RadioButton[]
  selectedId?: any
  label?: string
}
export type Functions = {
  onChange(button: RadioButton)
}

export type AllProps = Props & Functions
export const FatRadioButtonList: FunctionComponent<AllProps> = ({
  selectedId,
  buttons,
  onChange,
  label,
}) => {

  return (
    <Wrap>
      {label && (
        <Typography
          style={{fontSize:'14px'}}
          color="textSecondary"
          gutterBottom
        >
          {label}
        </Typography>
      )}
      <ButtonHolder>
        {buttons.map(button => (
          <ButtonWrap
            key={button.id}
            active={selectedId == button.id}
            onClick={() => onChange(button)}
          >
            {button.title && <Title>{button.title}</Title>}
            {button.subtitle && <Subtitle>{button.subtitle}</Subtitle>}
          </ButtonWrap>
        ))}
      </ButtonHolder>
    </Wrap>
  )
}

export default FatRadioButtonList
