import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { BrowserRouter as Router } from 'react-router-dom'
import { ConfigProvider } from './components/providers/ConfigProvider'
import { ThemeProvider, theme } from './components/providers/ThemeProvider'
import { ApiProvider } from './components/providers/ApiProvider'
import { DatePickerLocaleProvider } from './components/providers/DatePickerLocaleProvider'
import { Main } from './components/views/Main'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import DateFnsUtils from '@date-io/date-fns'
import { lv } from 'date-fns/locale'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { AlertProvider } from 'components/providers/AlertProvider'
import { PreloaderProvider } from 'components/providers/PreloaderProvider'

export default function App(): JSX.Element {
  return (
    <ConfigProvider>
      <StyledThemeProvider theme={theme}>
        <ThemeProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={lv}>
            <DatePickerLocaleProvider>
              <PreloaderProvider>
                <AlertProvider>
                  <ApiProvider>
                    <Router>
                      <Main />
                    </Router>
                  </ApiProvider>
                </AlertProvider>
              </PreloaderProvider>
            </DatePickerLocaleProvider>
          </MuiPickersUtilsProvider>
          <CssBaseline />
        </ThemeProvider>
      </StyledThemeProvider>
    </ConfigProvider>
  )
}
