import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  SubscriptionStep,
  useSubscription,
} from 'components/providers/SubscriptionsProvider'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Step_2_SelectSubscriptionType from '../step-2-select-subscription-type/Step_2_SelectSubscriptionType'
import Step_3_SelectVehicle from '../step-3-select-vehicle/Step_3_SelectVehicle'
import Step_4_Confirm from '../step-4-confirm/Step_4_Confirm'
import { Step_1_SelectZone } from '../step-1-select-zone/Step_1_SelectZone'
import { Chip } from '@material-ui/core'
import { ZoneOfferModel } from 'models/ZoneOfferModel'
import { useTranslation } from 'react-i18next'
import { Severity } from 'components/providers/AlertProvider'
import { Alert } from 'components/elements/Alert'
import queryString from 'query-string'
import { Car } from 'models/Car'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 700,
      '& .MuiStepLabel-label.MuiStepLabel-active': {
        fontWeight: 400,
      },
      '& .MuiStepLabel-label.MuiStepLabel-completed': {
        fontWeight: 400,
      },
    },
    stepper: {
      [theme.breakpoints.down('sm')]: {
        padding: '24px 10px',
      },
    },
  }),
)

export type Props = {}
export type Functions = {}
export type AllProps = Props & Functions
export const PurchaseSteps: FunctionComponent<AllProps> = ({}) => {
  const classes = useStyles()
  const {
    currentStep,
    selectedZone,
    offer,
    selectedCars,
    setStep,
    resetAllState,
    zones,
    init,
    isLoading,
    setSelectedZone,
    purchaseSubscription,
    setOffer,
    setVehicles,
    setPurchaseSubscription,
  } = useSubscription()

  const { t } = useTranslation()
  const query = queryString.parse(location.search)

  useEffect(() => {
    ;(async () => {
      if (!zones.length) {
        await init()
      }

      if (purchaseSubscription) {
        const zone = zones.find(zone => zone.id == purchaseSubscription.zoneId)

        if (zone) {
          setSelectedZone(zone)

          const offer = zone.offers.find(offer => offer.id == purchaseSubscription.offerId)

          if (offer) {
            setOffer({ offerId: offer.id, amount: purchaseSubscription.purchaseCount })

            const cars = purchaseSubscription.vehicles.map(vehicle => {
              return new Car({ plateNumber: vehicle.plateNumber })
            })

            setVehicles(cars)
            setPurchaseSubscription(purchaseSubscription)
          }
        }
      }
    })()

    return () => {
      resetAllState()
    }
  }, [])

  useEffect(() => {
    if (!zones.length) {
      return
    }

    if (query.zone) {
      const zone = zones.find(zone => zone.shortName == query.zone || zone.id == parseInt(query.zone.toString()))

      if (zone) {
        setSelectedZone(zone)
      }
    }
  }, [zones])

  const navigate = (step: SubscriptionStep) => {
    if (currentStep > step) {
      setStep(step)
    }
  }

  const getStep = step => {
    const { key, label, component } = step
    switch (step.key) {
      case SubscriptionStep.SELECT_ZONE:
        const zone: ZoneOfferModel =
          currentStep !== SubscriptionStep.SELECT_ZONE ? selectedZone : null
        return (
          <Step key={key}>
            <StepLabel
              onClick={() => navigate(SubscriptionStep.SELECT_ZONE)}
              style={{ cursor: zone ? 'pointer' : 'auto' }}
            >
              {label}
              {zone && (
                <Chip
                  style={{ marginLeft: '10px' }}
                  color="primary"
                  label={zone.shortName}
                />
              )}
            </StepLabel>
            <StepContent>{component}</StepContent>
          </Step>
        )

      case SubscriptionStep.SELECT_TYPE:
        const show =
          (offer.selected && currentStep === SubscriptionStep.SELECT_VEHICLE) ||
          currentStep === SubscriptionStep.CONFIRM

        return (
          <Step key={key}>
            <StepLabel
              onClick={() => navigate(SubscriptionStep.SELECT_TYPE)}
              style={{ cursor: show ? 'pointer' : 'auto' }}
            >
              {label}
              {show && (
                <Chip
                  style={{ marginLeft: '10px' }}
                  color="primary"
                  label={`${offer.selected.name}, x${offer.amount}`}
                />
              )}
            </StepLabel>
            <StepContent>{component}</StepContent>
          </Step>
        )

      case SubscriptionStep.SELECT_VEHICLE:
        const showVehiclesChip = currentStep === SubscriptionStep.CONFIRM
        return (
          <Step key={key}>
            <StepLabel
              onClick={() => navigate(SubscriptionStep.SELECT_VEHICLE)}
              style={{ cursor: showVehiclesChip ? 'pointer' : 'auto' }}
            >
              {label}

              {showVehiclesChip && (
                <Chip
                  style={{ marginLeft: '10px', fontFamily: '"Roboto Mono", monospace' }}
                  color="primary"
                  label={selectedCars.map(c => c.plateNumber).join(', ')}
                />
              )}
            </StepLabel>
            <StepContent>{component}</StepContent>
          </Step>
        )
      default:
        return (
          <Step key={key}>
            <StepLabel>{label}</StepLabel>
            <StepContent>{component}</StepContent>
          </Step>
        )
    }
  }

  const steps = [
    {
      label: t('subscriptionPurchase.purchaseStep_1'),
      key: SubscriptionStep.SELECT_ZONE,
      component: <Step_1_SelectZone />,
    },
    {
      label: t('subscriptionPurchase.purchaseStep_2'),
      key: SubscriptionStep.SELECT_TYPE,
      component: <Step_2_SelectSubscriptionType />,
    },
    {
      label: t('subscriptionPurchase.purchaseStep_3'),
      key: SubscriptionStep.SELECT_VEHICLE,
      component: <Step_3_SelectVehicle />,
    },
    {
      label: t('subscriptionPurchase.purchaseStep_4'),
      key: SubscriptionStep.CONFIRM,
      component: <Step_4_Confirm />,
    },
  ]

  return (
    <div className={classes.root}>
      {!isLoading && !Boolean(zones.length) && (
        <Alert
          staticConfig={{
            title: t('alerts.somethingWentWrong'),
            message: t('subscriptionPurchase.alertSubscriptionsNotAvailable'),
            severity: Severity.WARNING,
          }}
        />
      )}

      {Boolean(zones.length) && (
        <Stepper
          className={classes.stepper}
          activeStep={currentStep}
          orientation="vertical"
        >
          {steps.map(step => getStep(step))}
        </Stepper>
      )}
    </div>
  )
}

export default PurchaseSteps
