import React, { FunctionComponent, useContext, useState } from 'react'

export type RootState = {
  isOpen: boolean
}
export type RootFunctions = {
  showPreloader()
  hidePreloader()
}

const InitialState: RootState = {
  isOpen: false,
}

export const PreloaderContext = React.createContext<
  [RootState, (state: any) => void]
>([InitialState, () => {}])

export const PreloaderProvider: FunctionComponent = ({ children }) => {
  const [state, setState] = useState<RootState>(InitialState)
  return (
    <PreloaderContext.Provider value={[state, setState]}>
      {children}
    </PreloaderContext.Provider>
  )
}

export const usePreloader = (): RootFunctions & RootState => {
  const [state, setState] = useContext(PreloaderContext)

  const showPreloader = () => {
    setState(
      (state: RootState): RootState => ({
        ...state,
        isOpen: true,
      }),
    )
  }

  const hidePreloader = () => {
    setState(
      (state: RootState): RootState => ({
        ...state,
        isOpen: false,
      }),
    )
  }

  return {
    ...state,
    showPreloader,
    hidePreloader,
  }
}
