import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { IconButtonProps } from '@material-ui/core'

interface Props {
  icon: React.ReactElement
  spacingLeft?: boolean
}

const useClasses = makeStyles({
  root: {
    verticalAlign: 'top',
    marginTop: -2,
    marginBottom: -2,
  },
  label: {
    '& svg': {
      fontSize: 'inherit',
    },
  },
})

const ListIconButton = ({ icon, spacingLeft, ...props }: IconButtonProps & Props) => {
  const classes = useClasses()

  return <IconButton
    size="small"
    color="primary"
    classes={classes}
    style={{ ...(spacingLeft && { marginLeft: 6 }) }}
    {...props}
  >{icon}</IconButton>
}

export default ListIconButton
