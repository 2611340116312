import React from 'react'
import Chip, { ChipProps } from '@material-ui/core/Chip'

const PaymentOptionChip = (props: ChipProps) => (
  <Chip
    size="small"
    variant="outlined"
    {...props}
  />
)

export default PaymentOptionChip
