import React, { FunctionComponent } from 'react'
import { Box } from '@material-ui/core'
import { useSubscription } from 'components/providers/SubscriptionsProvider'
import ZoneList from 'components/elements/zone-list/ZoneList'
import { ZoneOfferModel } from 'models/ZoneOfferModel'

export type Props = {}
export type Functions = {}
export type AllProps = Props & Functions

export const Step_1_SelectZone: FunctionComponent<AllProps> = () => {
  const { zones, setSelectedZone, isLoading } = useSubscription()
  const handleClick = (zone: ZoneOfferModel) => {
    setSelectedZone(zone)
  }

  return (
    <Box p={0} width={1}>
      <ZoneList zones={zones} onClick={handleClick} />
    </Box>
  )
}

export default Step_1_SelectZone
