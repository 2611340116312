import React from 'react'
import Paper, { PaperProps } from '@material-ui/core/Paper'
import Draggable from 'react-draggable'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import { makeStyles, Theme } from '@material-ui/core/styles'
import DialogTitle, { DialogTitleProps } from '@material-ui/core/DialogTitle'

const useClasses = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiDialogActions-root': {
      paddingTop: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
    },
  },
}))

const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable handle="#draggable-dialog-handle" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export const DraggableDialog = (props: DialogProps) => {
  const classes = useClasses()

  return (
    <Dialog
      PaperComponent={PaperComponent}
      classes={classes}
      {...props}
    />
  )
}

export const DraggableDialogTitle = (props: DialogTitleProps) => {
  return (
    <DialogTitle id="draggable-dialog-handle" style={{ cursor: 'move' }} {...props}/>
  )
}
