import React from 'react'
import { KeyboardDateTimePicker, KeyboardDateTimePickerProps } from '@material-ui/pickers'
import Grid, { GridProps } from '@material-ui/core/Grid'
import { FormalWebFieldProps } from '@kevinwolf/formal-web/lib/types'
import { FormalFieldProps } from '@kevinwolf/formal'

interface DateTimeFieldProps {
  GridProps?: GridProps,
  value: string,
}

export const DateTimeField = (
  {
    GridProps,
    value,
    onChange,
    error,
    ...props
  }: DateTimeFieldProps & FormalFieldProps & FormalWebFieldProps & Omit<KeyboardDateTimePickerProps, 'onChange' | 'error'>,
) => {

  return (
    <Grid item {...GridProps}>
      <KeyboardDateTimePicker
        value={null}
        inputValue={value}
        format="dd.MM.yyyy HH:mm"
        ampm={false}
        inputVariant="outlined"
        fullWidth
        helperText=""
        autoComplete="off"
        inputProps={{ 'data-lpignore': true }}
        KeyboardButtonProps={{
          size: 'small',
        }}
        autoOk={true}
        onChange={(date, value) => {
          onChange({ target: { value: value || '' } })
        }}
        {
          ...(error && { error: true })
        }
        {...props}
      />
    </Grid>
  )
}
