import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import {ROUTE} from 'constants/Routes'
import Button from '@material-ui/core/Button'
import {Backdrop, Box, CircularProgress} from '@material-ui/core'
import queryString from 'query-string'
import EditIcon from '@material-ui/icons/Edit'
import EditCarsDialog from 'components/views/subscriptions/buy/views/home/EditCarsDialog'
import EditNotesDialog from 'components/views/subscriptions/buy/views/home/EditNotesDialog'
import { SubscriptionStep, useSubscription } from 'components/providers/SubscriptionsProvider'
import {ActiveSubscription} from 'models/ActiveSubscription'

import {AppDB} from 'utils/DB'
import {Severity, useAlert} from 'components/providers/AlertProvider'
import {useTranslation} from 'react-i18next'
import ResponsiveDataTable from 'components/elements/ResponsiveDataTable'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import AutoRenewIcon from '@material-ui/icons/Autorenew'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import IconButton from '@material-ui/core/IconButton'
import ParkedIcon from '@material-ui/icons/Lens'

const useClasses = makeStyles((theme: Theme) => ({
  subscriptionNameAndPeriod: {
    [theme.breakpoints.up('md')]: {
      whiteSpace: 'nowrap',
    },
  },
  subscriptionNotes: {
    color: theme.palette.text.disabled,
  },
}))

export default withRouter(({ history }) => {
  const { t, i18n } = useTranslation()
  const { zones, activeSubscriptions: subscriptions, setStep, setPurchaseSubscription, isLoading, init } = useSubscription()
  const [selectedSubscription, setSelectedSubscription] = useState<
    ActiveSubscription
  >(null)
  const hasSubscriptions: boolean = Boolean(subscriptions.length)
  const [openEditCarsDialog, setOpenEditCarsDialog] = useState(false)
  const [openEditNotesDialog, setOpenEditNotesDialog] = useState(false)
  const { openAlert } = useAlert()

  const classes = useClasses()

  useEffect(() => {
    ;(async () => {
      const { result } = queryString.parse(history.location.search)
      const mpayRedirect = AppDB.getPaymentRedirectResult()

      if (result) {
        AppDB.setPaymentRedirectResult(result)
        return (location.href = `${location.origin}${location.pathname}?lang=${i18n.language}`)
      }

      if (mpayRedirect !== null) {
        AppDB.resetRedirect()
        const isSuccess = mpayRedirect.success
        const title = isSuccess
          ? t('subscriptionPurchase.alertPurchaseSuccessfulTitle')
          : t('subscriptionPurchase.alertPurchaseFailedTitle')
        const message = isSuccess
          ? t('subscriptionPurchase.alertPurchaseSuccessfulBody')
          : t('subscriptionPurchase.alertPurchaseFailedBody')

        const severity = isSuccess ? Severity.SUCCESS : Severity.ERROR

        openAlert({
          message,
          title,
          severity,
        })
      }

      const { hasSubscriptions } = await init()
      if (!hasSubscriptions) {
        history.push(ROUTE.SUBSCRIPTIONS.BUY)
      }
    })()
  }, [])

  return (
    <Box p={'20px'} width={1}>
      <Backdrop style={{ zIndex: 1000, color: '#fff' }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {hasSubscriptions && !isLoading && (
        <>
          <EditCarsDialog
            selectedSubscription={selectedSubscription}
            open={openEditCarsDialog}
            onClose={() => setOpenEditCarsDialog(false)}
          />
          <EditNotesDialog
            selectedSubscription={selectedSubscription}
            open={openEditNotesDialog}
            onClose={() => setOpenEditNotesDialog(false)}
          />
          <Button
            onClick={() => history.push(ROUTE.SUBSCRIPTIONS.BUY)}
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            style={{ marginBottom: '30px' }}
          >
            {t('subscriptionPurchase.buySubscription')}
          </Button>

          <Typography variant="h5" component="h1" style={{ marginBottom: 20 }}>
            {t('subscriptionPurchase.activeSubscriptions')}
          </Typography>

          <ResponsiveDataTable
            mobileBreakpoint={959}
            table={{
              head: [
                t('zone'),
                t('subscription'),
                t('quantity'),
                t('plateNumbers'),
                '',
              ],
              rows: subscriptions.map(subscription => ({
                id: subscription.id,
                cells: [
                  { value: subscription.zoneName },
                  {
                    value: <>
                      <Box className={classes.subscriptionNameAndPeriod}>
                        {subscription.name}
                        {subscription.autoRenew && (
                          <Tooltip title={t('subscriptionPurchase.autoRenew')} placement={'top'} arrow={true}>
                            <AutoRenewIcon fontSize="small" color="primary" style={{ verticalAlign: 'top' }} />
                          </Tooltip>
                        )}
                        <Typography variant="inherit" component="em" display="block">{subscription.validFrom} - {subscription.validTo || '...'}</Typography>
                      </Box>
                      <Box marginTop={1} className={classes.subscriptionNotes}>
                        {subscription.notes ? subscription.notes : `${t('notes')}:`}
                        <IconButton
                          size="small"
                          color="secondary"
                          onClick={() => {
                            setSelectedSubscription(subscription)
                            setOpenEditNotesDialog(true)
                          }}
                        >
                          <EditIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                      {!subscription.autoRenew
                        && subscription.validTo
                        && (zones.find(zone => zone.id == subscription.zoneId) || { offers: [] }).offers.find(offer => offer.id == subscription.offerId)
                        && (<Box marginTop={1}>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setStep(SubscriptionStep.NONE)
                              setPurchaseSubscription(subscription)
                              history.push(ROUTE.SUBSCRIPTIONS.BUY)
                            }}
                          >
                            {t('subscriptionPurchase.extend')}
                          </Button>
                        </Box>)
                      }
                    </>,
                  },
                  {
                    value: String(subscription.purchaseCount),
                  },
                  {
                    value: <div style={{ fontFamily: '"Roboto Mono", monospace' }}>
                      {subscription.vehicles.length ? <>
                        {subscription.vehicles.sort((a, b) => a.plateNumber.localeCompare(b.plateNumber)).map((vehicle, key) => {
                          if (!vehicle.isParked)
                            return `${key ? ', ' : ''}${vehicle.plateNumber}`

                          return <React.Fragment key={key}>
                            {key ? ', ' : ''}
                            <Tooltip title={`${t('subscriptionPurchase.parked')}${vehicle.isUnpaid ? `, ${t('subscriptionPurchase.unpaid')}` : ''}`} placement={'top'} arrow={true}>
                              <span style={{ whiteSpace: 'nowrap' }}>{vehicle.plateNumber}<ParkedIcon fontSize={'inherit'} style={{ verticalAlign: 'middle', marginTop: -2, marginLeft: 1, color: vehicle.isUnpaid ? 'orange' : 'green' }} /></span>
                            </Tooltip>
                          </React.Fragment>
                        })}
                      </> : '—'}
                    </div>,
                  },
                  {
                    value: <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      startIcon={<EditIcon />}
                      onClick={() => {
                        setSelectedSubscription(subscription)
                        setOpenEditCarsDialog(true)
                      }}
                    >
                      {t('change')}
                    </Button>,
                    align: 'right',
                  },
                ],
              })),
            }}
          />
        </>
      )}
    </Box>
  )
})
