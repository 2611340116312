import styled from 'styled-components'

export const Label = styled.label`
  display: block;
  margin-bottom: 19px;
  font-size: 15px;
`

export const PaymentUi = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top:40px;
`

export const PaymentLabel = styled.h4`
  margin-bottom: 1px;
  font-size: 14px;
  text-align: right;
  font-weight: normal;
`
export const Price = styled.h3`
  margin-top: 0;
  margin-bottom: 3px;
  font-size: 35px;
  text-align: right;
`
