import React, { FunctionComponent } from 'react'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import { useHistory } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Button from '@material-ui/core/Button'
import LogoutIcon from 'mdi-material-ui/Logout'
import LanguageIcon from '@material-ui/icons/Language'
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from '@material-ui/core/styles'
import ParkingIcon from 'mdi-material-ui/Parking'
import TicketIcon from 'mdi-material-ui/Ticket'
import { useTranslation } from 'react-i18next'
import { MobillyIcon } from 'components/elements/MobillyIcon'
import clsx from 'clsx'
import {
  paymentsPermission,
  analyticsPermission,
  parkingLotPermission,
  myParkingLotPermission,
  permissionSubscriptionsFreeTime,
  subscriptionsSubscriptionsPermission,
  subscriptionsPermission,
  subscriptionsPermitsPermission,
} from 'utils'
import { usePermissions } from 'components/providers/PermissionProvider'
import { ROUTE } from 'constants/Routes'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { useMediaQuery } from '@material-ui/core'
import {useApi} from "components/providers/ApiProvider";

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },

    hide: {
      display: 'none',
    },
    grow: {
      flexGrow: 1,
    },

    appBar: {},
    appBarShift: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },

    drawerOpenButton: {
      [theme.breakpoints.up('sm')]: {
        marginRight: 12,
      },
    },

    mainMenu: {
      position: 'relative',
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        marginRight: 0,
      },
    },
    mainMenuButton: {
      marginRight: '5px',
      [theme.breakpoints.down('sm')]: {
        marginRight: '0px',
        width: '100%',
        padding: '6px',
      },
    },
    mainMenuLabel: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    mainMenuStartIcon: {
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
    },
    mainMenuTitle: {
      fontSize: '15px',
      letterSpacing: '1px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
        marginTop: '3px',
        lineHeight: '15px',
      },
    },

    language: {
      minWidth: 'auto',
      padding: '4px 8px',
      fontSize: theme.typography.pxToRem(14),
    },

    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      color: 'white',
    },

    mobilly: {
      color: '#F27D09',
      fontSize: '22px',
      marginLeft: '14px',
    },

    drawerMenuItem: {
      borderRadius: '8px',
      color: '#F7F7F7',
    },
    drawerMenuItemSelected: {
      backgroundColor: '#403C39 !important',
      color: '#F06623',
    },

    content: {
      flexGrow: 1,
      padding: '24px 0',
      [theme.breakpoints.up('sm')]: {
        marginLeft: -drawerWidth,
      },
    },
    contentShift: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: 0,
      },
    },
    toolbar: {
      ...theme.mixins.toolbar,
      minHeight: 64,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '15px',
    },
    mainWrap: {
      marginTop: '-20px',
    },
  }),
)

interface Props {
}

export const MainNavigation: FunctionComponent<Props> = ({ children }) => {
  const { t, i18n } = useTranslation()
  const permissions = usePermissions()
  const history = useHistory()
  const classes = useStyles()
  const theme = useTheme()
  const api = useApi();

  const [temporaryDrawerOpen, setTemporaryDrawerOpen] = React.useState(false)
  const [persistentDrawerOpen, setPersistentDrawerOpen] = React.useState(sessionStorage.getItem('drawer') != 'hidden')
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = React.useState<null | HTMLElement>(null)

  const isTemporaryDrawerScreen = !useMediaQuery(theme.breakpoints.up('sm'))
  const accountMenuOpen = Boolean(accountMenuAnchorEl)

  const currentMainPath = `/${history.location.pathname.split('/')[1]}`

  const handleAccountMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAccountMenuAnchorEl(event.currentTarget)
  }

  const handleAccountMenuClose = () => {
    setAccountMenuAnchorEl(null)
  }

  const handleDrawerOpen = () => {
    setTemporaryDrawerOpen(true)
    setPersistentDrawerOpen(true)
    sessionStorage.removeItem('drawer')
  }

  const handleDrawerClose = () => {
    setTemporaryDrawerOpen(false)
    setPersistentDrawerOpen(false)
    sessionStorage.setItem('drawer', 'hidden')
  }

  const LANGUAGES = ['lv', 'ru', 'en']

  const hasMyParkingLotPermission = myParkingLotPermission(permissions),
    hasSubscriptionsPermission = subscriptionsPermission(permissions),
    hasSubscriptionsSubscriptionsPermission = subscriptionsSubscriptionsPermission(permissions),
    hasPermissionSubscriptionsFreeTime = permissionSubscriptionsFreeTime(permissions),
    hasParkingLotPermission = parkingLotPermission(permissions),
    hasPaymentsPermission = paymentsPermission(permissions),
    hasAnalyticsPermission = analyticsPermission(permissions)

  const TOP_NAVIGATION = [
    ...(hasMyParkingLotPermission
      ? [
        {
          title: t('parking'),
          icon: <ParkingIcon />,
          path: ROUTE.MY_PARKING_LOT.MAIN,
          id: ROUTE.MY_PARKING_LOT.MAIN,
        },
      ]
      : []),
    ...(hasSubscriptionsPermission
      ? [
        {
          title: t('subscriptions'),
          icon: <TicketIcon />,
          path: ROUTE.SUBSCRIPTIONS.HOME,
          id: ROUTE.SUBSCRIPTIONS.MAIN,
        },
      ]
      : []),
  ]

  const SUBSCRIPTIONS_SIDE_NAV = [
    {
      title: t('mySubscriptions'),
      id: ROUTE.SUBSCRIPTIONS.MAIN,
      path: ROUTE.SUBSCRIPTIONS.HOME,
      activePaths: [ROUTE.SUBSCRIPTIONS.HOME, ROUTE.SUBSCRIPTIONS.BUY],
    },

    ...(hasPermissionSubscriptionsFreeTime
      ? [
        {
          title: t('addFreeTimeSubscription'),
          id: ROUTE.SUBSCRIPTIONS.MAIN,
          path: ROUTE.SUBSCRIPTIONS.FREE_TIME,
        },
      ]
      : []),

    ...(hasSubscriptionsSubscriptionsPermission
      ? [
        {
          title: t('subscriptions'),
          path: ROUTE.SUBSCRIPTIONS.SUBSCRIPTIONS,
          id: ROUTE.SUBSCRIPTIONS.MAIN,
        },
      ]
      : []),

    ...(subscriptionsPermitsPermission(permissions)
      ? [
        {
          title: t('permits'),
          path: ROUTE.SUBSCRIPTIONS.PERMITS,
          id: ROUTE.SUBSCRIPTIONS.MAIN,
        },
      ]
      : []),
  ]
  const PARKING_SIDE_NAV = [
    ...(hasParkingLotPermission
      ? [
        {
          title: t('parking'),
          id: ROUTE.MY_PARKING_LOT.MAIN,
          path: ROUTE.MY_PARKING_LOT.PARKING_LOT,
        },
      ]
      : []),
    ...(hasPaymentsPermission
      ? [
        {
          title: t('payments'),
          id: ROUTE.MY_PARKING_LOT.MAIN,
          path: ROUTE.MY_PARKING_LOT.PAYMENTS,
        },
      ]
      : []),
    ...(hasAnalyticsPermission
      ? [
        {
          title: t('analytic'),
          id: ROUTE.MY_PARKING_LOT.MAIN,
          path: ROUTE.MY_PARKING_LOT.ANALYTICS,
        },
      ]
      : []),
  ]

  const SIDE_NAV: {
    title: string
    id: string
    path: string
    activePaths?: string[]
  }[] = [...SUBSCRIPTIONS_SIDE_NAV, ...PARKING_SIDE_NAV]

  const drawer = (
    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
      <div className={classes.toolbar}>
        <MobillyIcon fill={'#F27D09'} />
        <Typography variant="h5" component="h2" className={classes.mobilly}>
          Mobilly
        </Typography>
        <Hidden xsDown>
          <IconButton size="small" style={{ color: 'white', marginLeft: 'auto' }} onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </Hidden>
      </div>
      <Divider />
      <List>
        {SIDE_NAV.filter(m => m.id === currentMainPath).map(
          ({ title, path, activePaths }, index) => {
            const selected = activePaths
              ? activePaths.find(p => history.location.pathname.includes(p))
              : history.location.pathname.includes(path)
            return (
              <ListItem
                classes={{
                  root: classes.drawerMenuItem,
                  selected: classes.drawerMenuItemSelected,
                }}
                onClick={() => {
                  if (isTemporaryDrawerScreen) {
                    handleDrawerClose()
                  }
                  history.push(path)
                }}
                button
                selected={Boolean(selected)}
                key={index}
              >
                <ListItemText primary={title} />
              </ListItem>
            )
          },
        )}
      </List>
    </div>
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={clsx(classes.appBar, {
        [classes.appBarShift]: persistentDrawerOpen,
      })}>
        <Toolbar style={{ minHeight: 64, padding: '6px 11px' }}>
          <Hidden smUp>
            <IconButton
              color="inherit"
              edge="start"
              className={classes.drawerOpenButton}
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden xsDown>
            <IconButton
              color="inherit"
              className={clsx(classes.drawerOpenButton, persistentDrawerOpen && classes.hide)}
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <div className={classes.mainMenu}>
            {TOP_NAVIGATION.map(nav => (
              <Button
                key={nav.title}
                className={classes.mainMenuButton}
                variant="contained"
                color={currentMainPath === nav.id ? 'secondary' : 'primary'}
                classes={{
                  label: classes.mainMenuLabel,
                  startIcon: classes.mainMenuStartIcon,
                }}
                startIcon={nav.icon}
                onClick={event => {
                  event.preventDefault()
                  history.push(nav.path)
                }}
              >
                <Typography className={classes.mainMenuTitle} variant="inherit">
                  {nav.title}
                </Typography>
              </Button>
            ))}
          </div>
          <div className={classes.grow} />
          <div>
            <IconButton
              onClick={handleAccountMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              anchorEl={accountMenuAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={accountMenuOpen}
              onClose={handleAccountMenuClose}
            >
              <MenuItem onClick={handleAccountMenuClose}>
                <ListItemIcon>
                  <LanguageIcon fontSize="small" />
                </ListItemIcon>

                <div style={{ marginLeft: i18n.language === LANGUAGES[0] ? 0 : -7 }}
                >
                  {LANGUAGES.map(language => (
                    <Button
                      color="secondary"
                      key={language}
                      variant={i18n.language === language ? 'contained' : 'text'}
                      className={classes.language}
                      onClick={() => i18n.changeLanguage(language)}
                    >
                      {language}
                    </Button>
                  ))}
                </div>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  api.logout();
                  history.push('/')
                }}
              >
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">{t('logOut')}</Typography>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden smUp>
          <Drawer
            variant="temporary"
            open={temporaryDrawerOpen}
            classes={{ paper: classes.drawerPaper }}
            onClose={handleDrawerClose}
            ModalProps={{ keepMounted: true }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown>
          <Drawer
            variant="persistent"
            classes={{ paper: classes.drawerPaper }}
            open={persistentDrawerOpen}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={clsx(classes.content, {
        [classes.contentShift]: persistentDrawerOpen,
      })}>
        <div className={classes.toolbar} />
        <div className={classes.mainWrap}>{children}</div>
      </main>
    </div>
  )
}
export default MainNavigation
