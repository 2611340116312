import React from 'react'
import { useHistory } from 'react-router-dom'
import { useApi, IPermissionsData } from '../providers/ApiProvider'
import * as permissionType from '../../constants/permissions'

export const PermissionsContext = React.createContext([])

export const usePermissions = () => {
  const context = React.useContext(PermissionsContext)
  if (!context) {
    throw new Error(
      'usePermissions must be used within a PermissionsContextProvider',
    )
  }
  return context
}

export const PermissionsContextProvider = ({ children }: any) => {
  const history = useHistory()
  const [permissionData, setPermissionsData] = React.useState<
    IPermissionsData | any
  >([])

  const api = useApi()

  React.useEffect(() => {
    api
      .getPermissions([
        { name: permissionType.PAGE_MY_PARKING_LOT_PARKING_LOT },
        { name: permissionType.PAGE_MY_PARKING_LOT_PARKING_LOT_MODIFY },
        { name: permissionType.PAGE_MY_PARKING_LOT_PARKING_LOT_BARRIERS },
        { name: permissionType.PAGE_MY_PARKING_LOT_PAYMENTS },
        { name: permissionType.PAGE_MY_PARKING_LOT_ANALYTICS },
        { name: permissionType.PAGE_SUBSCRIPTIONS_BUY },
        { name: permissionType.PAGE_SUBSCRIPTIONS_FREE_TIME },
        { name: permissionType.PAGE_SUBSCRIPTIONS_SUBSCRIPTIONS },
        { name: permissionType.PAGE_SUBSCRIPTIONS_PERMITS },
      ])
      .then(res => {
        setPermissionsData(res)
      })
      .catch(() => {
        api.logout();
        history.push('/')
      })
  }, [api, history])

  return (
    <PermissionsContext.Provider value={permissionData}>
      {children}
    </PermissionsContext.Provider>
  )
}
