import React, { FunctionComponent, useEffect } from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableBody from '@material-ui/core/TableBody'
import { useSpecialRates } from '../SpecialRatesProvider'
import { formatTimestamp } from 'utils'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@material-ui/lab'
import { Container, LinearProgress } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

export type Props = {}
export type Functions = {}
export type AllProps = Props & Functions

const List: FunctionComponent<AllProps> = ({}) => {
  const { t } = useTranslation()
  const { rates, loadRates, listLoading } = useSpecialRates()

  useEffect(() => {
    loadRates()
  }, [])

  const showAssignedByColumn = rates && rates.find(session => {
    return typeof session.assigned_user_name !== 'undefined'
  })

  return (
    <>
      <Typography variant="h5" component="h1" style={{ marginBottom: 20 }}>
        {t('specialRates.todayAssignedRates')}
      </Typography>

      {rates && listLoading && (
        <LinearProgress style={{ height: 1, marginBottom: -1 }} />
      )}

      {rates && !rates.length && (
        <Container maxWidth="md" style={{ paddingTop: 36 }}>
          <Alert severity="info">{t('noRecords')}</Alert>
        </Container>
      )}

      {(!rates || Boolean(rates.length)) && (
        <TableContainer>
          <Table>
            <TableHead>
              {!rates && (
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              )}
              {rates && (
                <TableRow>
                  <TableCell>{t('zone')}</TableCell>
                  <TableCell>{t('period')}</TableCell>
                  <TableCell>{t('plateNumber')}</TableCell>
                  <TableCell>{t('droveIn')}</TableCell>
                  {showAssignedByColumn && (
                    <TableCell>{t('assignedBy')}</TableCell>
                  )}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {!rates && (
                <TableRow>
                  <TableCell>
                    <Skeleton />
                    <Skeleton style={{ margin: '8px 0' }} />
                    <Skeleton />
                    <Skeleton style={{ margin: '8px 0' }} />
                    <Skeleton />
                  </TableCell>
                </TableRow>
              )}
              {rates && rates
                .map((rate, key) => (
                  <TableRow key={key}>
                    <TableCell>{rate.zone}</TableCell>
                    <TableCell>{rate.rate_name}</TableCell>
                    <TableCell style={{ fontFamily: '"Roboto Mono", monospace' }}>{rate.plate_number}</TableCell>
                    <TableCell>{formatTimestamp(rate.entry_time)}</TableCell>
                    {showAssignedByColumn && (
                      <TableCell>{rate.assigned_user_name}</TableCell>
                    )}
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export default List
