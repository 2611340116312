const store = require('store')

export enum DB {
  REDIRECT_STATUS = 'REDIRECT_STATUS',
}
export class AppDB {
  static setPaymentRedirectResult(status: any) {
    store.set(DB.REDIRECT_STATUS, status)
  }
  static getPaymentRedirectResult(): { success: boolean; paymentID?: string } {
    const base64 = store.get(DB.REDIRECT_STATUS)

    if (!base64) {
      return null
    }

    try {
      const object = JSON.parse(atob(base64))
      return {
        success: object.status === 1,
        paymentID: object.payment_id,
      }
    } catch (e) {
      return {
        success: false,
      }
    }
  }

  static resetRedirect() {
    store.remove(DB.REDIRECT_STATUS)
  }
}
