import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import lv from './lv.json'
import en from './en.json'
import ru from './ru.json'

const defaultLanguage = 'lv'

const defaultDetector = {
  name: 'default',
  lookup() {
    return defaultLanguage
  },
}

const languageDetector = new LanguageDetector()
languageDetector.addDetector(defaultDetector)

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      lv: {
        translation: lv,
      },
      en: {
        translation: en,
      },
      ru: {
        translation: ru,
      },
    },
    detection: {
      order: ['querystring', 'localStorage', 'default'],
      lookupQuerystring: 'lang',
      lookupLocalStorage: 'lang',
    },
    fallbackLng: defaultLanguage,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
