import React, { useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import Analytics from './Analytics'
import {
  paymentsPermission,
  analyticsPermission,
  parkingLotPermission,
  myParkingLotPermission,
} from 'utils'
import { usePermissions } from 'components/providers/PermissionProvider'
import { ROUTE } from 'constants/Routes'
import ParkingLot from 'components/views/parking-lot/ParkingLot'
import ParkingPayments from 'ParkingPayments/ParkingPayments'

export default withRouter(({ history }) => {
  const permissions = usePermissions()

  const hasParkingLotPermission = parkingLotPermission(permissions),
    hasPaymentsPermission = paymentsPermission(permissions),
    hasAnalyticsPermission = analyticsPermission(permissions),
    hasMyParkingLotPermission = myParkingLotPermission(permissions)

  useEffect(() => {
    if (permissions.length) {
      if (!hasMyParkingLotPermission) {
        history.push(ROUTE.SUBSCRIPTIONS.HOME)
      }
    }
  }, [permissions])

  const NAV = [
    hasParkingLotPermission && {
      uri: '/my-parking-lot/parking-lot',
    },
    hasPaymentsPermission && {
      uri: '/my-parking-lot/payments',
    },
    hasAnalyticsPermission && {
      uri: '/my-parking-lot/analytics',
    },
  ].filter(el => el)

  if (history.location.pathname === '/my-parking-lot' && NAV.length && NAV[0]) {
    return <Redirect to={NAV[0].uri} />
  }

  return (
    <Paper square elevation={0}>
      <Switch>
        {hasParkingLotPermission && (
          <Route path="/my-parking-lot/parking-lot">
            <ParkingLot />
          </Route>
        )}
        {hasPaymentsPermission && (
          <Route path="/my-parking-lot/payments">
            <ParkingPayments />
          </Route>
        )}
        {hasAnalyticsPermission && (
          <Route path="/my-parking-lot/analytics">
            <Analytics />
          </Route>
        )}
      </Switch>
    </Paper>
  )
})
