import React, { useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  figure: {
    margin: 0,
    backgroundRepeat: 'no-repeat',
  },
  zoomIn: {
    cursor: 'zoom-in',
  },
  zoomOut: {
    cursor: 'zoom-out',
  },
})

export function ImageZoom(props: { src: string }): JSX.Element {
  const { src } = props
  const classes = useStyles()
  const [state, setState] = useState<any>({
    src,
    zoom: null,
  })

  if (src != state.src) {
    setState({ src, zoom: null })
  }

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation()

    if (state.zoom) {
      setState({ ...state, zoom: null })

      return
    }

    const { left, top, width, height } = e.currentTarget.getBoundingClientRect()
    const x = ((e.pageX - left) / width) * 100
    const y = ((e.pageY - top - window.scrollY) / height) * 100
    setState({
      ...state,
      zoom: { x, y },
    })
  }

  return (
    <figure
      onClick={handleClick}
      className={clsx(classes.figure, state.zoom ? classes.zoomOut : classes.zoomIn)}
      style={state.zoom && { backgroundImage: `url(${src})`, backgroundPosition: `${state.zoom.x}% ${state.zoom.y}%` }}
    >
      <img
        src={src}
        width="100%"
        height="auto"
        style={{ display: 'block', ...(state.zoom && { opacity: 0 }) }}
      />
    </figure>
  )
}
