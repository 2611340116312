import React from 'react'
import Grid, { GridProps } from '@material-ui/core/Grid'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import { FormalFieldProps } from '@kevinwolf/formal'
import { FormalWebFieldProps } from '@kevinwolf/formal-web/lib/types'
import { useTranslation } from 'react-i18next'
import { normalizePlate } from 'utils'
import { makeStyles, Theme } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'

interface PlateNumberFieldProps {
  GridProps?: GridProps
  allowMultiple?: boolean
  clearable?: boolean
}

const useClasses = makeStyles((theme: Theme) => ({
  root: {
    '& input': {
      fontFamily: '"Roboto Mono", monospace',
      textTransform: 'uppercase',
    },
  },
}))

export const PlateNumberField = (
  {
    GridProps,
    allowMultiple,
    clearable,
    onChange,
    error,
    ...props
  }: PlateNumberFieldProps & FormalFieldProps & FormalWebFieldProps & Omit<TextFieldProps, 'variant' | 'error'>,
) => {
  const { t } = useTranslation()
  const classes = useClasses()

  return (
    <Grid item {...GridProps}>
      <TextField
        label={t('plateNumber')}
        variant="outlined"
        fullWidth
        autoComplete="off"
        inputProps={{ 'data-lpignore': true }}
        classes={classes}
        onChange={event => {
          const value = event.target.value
          const normalizedValue = allowMultiple ? value.split(/\s+/).map(value => normalizePlate(value)).join(' ') : normalizePlate(value)

          if (value.toUpperCase() == normalizedValue) {
            onChange({ target: { value } })

            return
          }

          const valueLengthDiff = normalizedValue.length - value.length
          const selectionStart = event.target.selectionStart + valueLengthDiff
          const selectionEnd = event.target.selectionEnd + valueLengthDiff

          onChange({ target: { value: normalizedValue } })

          setTimeout(target => {
            target.selectionStart = selectionStart
            target.selectionEnd = selectionEnd
          }, 0, event.target)
        }}
        {...(clearable && {
          InputProps: {
            endAdornment: <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() => onChange({ target: { value: '' } })}
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>,
          },
        })}
        {
          ...(error && { error: true })
        }
        {...props}
      />
    </Grid>
  )
}
