import React from 'react'
import Grid, { GridProps } from '@material-ui/core/Grid'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import { FormalFieldProps } from '@kevinwolf/formal'

interface Props {
  GridProps?: GridProps
}

export const TextField = ({ GridProps, error, ...props }: Props & FormalFieldProps & Omit<TextFieldProps, 'error'>) => (
  <Grid item {...GridProps}>
    <MuiTextField
      fullWidth
      variant="outlined"
      error={Boolean(error)}
      {...props}
    />
  </Grid>
)
