import React from 'react'
import { ChipProps } from '@material-ui/core/Chip'
import PaymentOptionChip from 'Elements/PaymentOptionChip'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'

interface Props {
  letter?: string
}

const useClasses = makeStyles({
  avatar: {
    backgroundColor: '#F06623',
  },
})

const MobillyPaymentOptionChip = ({ letter, ...props }: ChipProps & Props) => {
  const classes = useClasses()

  return (<PaymentOptionChip
    avatar={<Avatar>{letter || 'M'}</Avatar>}
    classes={classes}
    {...props}
  />)
}

export default MobillyPaymentOptionChip
