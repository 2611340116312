import React, { Ref } from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

const MoneyOffIcon = React.forwardRef((props: SvgIconProps, ref: Ref<SVGSVGElement>) => (
  <SvgIcon {...props} ref={ref}>
    <polygon points="12.81,9 14.81,11 15,11 16,9" />
    <path
      d="M10.84,7.03C11.967,6.081,13.414,5.5,15,5.5c1.609,0,3.09,0.59,4.23,1.57L21,5.3C19.41,3.87,17.3,3,15,3 c-2.28,0-4.352,0.857-5.935,2.256L10.84,7.03z"
    />
    <path
      d="M20.435,19.165l-1.806-1.806l0,0l-3.206-3.206l0,0L14.27,13l0,0l-2-2l0,0L7.836,6.566l0,0L4.02,2.75L2.75,4.02 l4.129,4.129C6.747,8.426,6.624,8.708,6.52,9H3l-1,2h4.06C6.02,11.33,6,11.66,6,12s0.02,0.67,0.06,1H3l-1,2h4.52 c1.24,3.49,4.56,6,8.48,6c1.383,0,2.688-0.317,3.856-0.874l1.124,1.124l1.27-1.27L20.435,19.165L20.435,19.165z M8.58,13 c-0.05-0.33-0.08-0.66-0.08-1s0.03-0.67,0.08-1h1.15l2,2H8.58z M15,18.5c-2.51,0-4.68-1.42-5.76-3.5h4.49l3.188,3.188 C16.311,18.377,15.673,18.5,15,18.5z"
    />
  </SvgIcon>
))

export default MoneyOffIcon
