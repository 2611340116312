import { format } from 'date-fns'

export class ActiveSubscription {
  id: number
  offerId: number
  autoRenew: boolean
  name: string
  validFrom: string
  validTo: string
  vehicles: {
    plateNumber: string
    isParked: boolean
    isUnpaid?: boolean
  }[]
  zoneId: number
  zoneName: string
  purchaseCount: number
  notes: string

  constructor(json: any) {
    this.id = json.id
    this.offerId = json.offer_id
    this.autoRenew = json.auto_renew === 1
    this.name = json.name
    this.validFrom =
      json.valid_from && format(new Date(json.valid_from), 'dd.MM.yyyy')
    this.validTo =
      json.valid_to && format(new Date(json.valid_to), 'dd.MM.yyyy')
    this.vehicles = json.vehicles.map(vehicle => ({ plateNumber: vehicle.plate_number, isParked: Boolean(vehicle.is_parked), isUnpaid: Boolean(vehicle.is_unpaid) }))
    this.zoneId = json.zone_id
    this.zoneName = json.zone_name
    this.purchaseCount = json.purchase_count
    this.notes = json.notes
  }
}
