import React from 'react'
import { Switch, Route } from 'react-router-dom'
import MyParkingLot from '../views/MyParkingLot'
import Subscriptions from '../views/Subscriptions'
import { Authentication } from '../views/Authentication'
import PrivateRoute from '../elements/PrivateRoute'
import { PermissionsContextProvider } from '../providers/PermissionProvider'
import { SubscriptionProvider } from 'components/providers/SubscriptionsProvider'
import { Alert } from 'components/elements/Alert'
import Preloader from 'components/elements/Preloader'

export const Main = () => {
  return (
    <SubscriptionProvider>
      <Alert />
      <Preloader />
      <Switch>
        <Route exact path="/">
          <Authentication />
        </Route>
        <Route path="/">
          <PermissionsContextProvider>
            <PrivateRoute path="/my-parking-lot">
              <MyParkingLot />
            </PrivateRoute>
            <PrivateRoute path="/subscriptions">
              <Subscriptions />
            </PrivateRoute>
          </PermissionsContextProvider>
        </Route>
      </Switch>
    </SubscriptionProvider>
  )
}
