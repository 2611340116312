import React, { CSSProperties, FunctionComponent } from 'react'
import { Wrap } from './PrevNext.css'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'

export type Props = {
  prevTitle?: string
  nextTitle?: string
  nextDisabled?: boolean
  style?: CSSProperties
}
export type Functions = {
  onClick(next: boolean)
}

export type AllProps = Props & Functions

export const PrevNext: FunctionComponent<AllProps> = ({
  prevTitle,
  nextTitle,
  onClick,
  nextDisabled,
  style,
}) => {
  const { t } = useTranslation()

  return (
    <Wrap style={{ ...style }}>
      <Button
        onClick={() => onClick(false)}
        type="submit"
        variant="outlined"
        color="primary"
        size="large"
      >
        {prevTitle || t('back')}
      </Button>
      <Button
        onClick={() => onClick(true)}
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        disabled={nextDisabled}
      >
        {nextTitle || t('next')}
      </Button>
    </Wrap>
  )
}

export default PrevNext
