import React, { Ref } from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

const AutomaticIcon = React.forwardRef((props: SvgIconProps, ref: Ref<SVGSVGElement>) => (
  <SvgIcon {...props} viewBox="0 0 64 64" ref={ref}>
    <g>
      <circle cx="32" cy="32" r="32" />
      <path
        fill="#FFFFFF"
        d="M41.135,47l-2.6-8.534H25.464L22.865,47h-8.191l12.654-36h9.294l12.702,36H41.135z M36.721,32.09 c-2.402-7.733-3.756-12.106-4.059-13.12s-0.519-1.814-0.649-2.403c-0.54,2.092-2.085,7.267-4.635,15.523H36.721z"
      />
    </g>
  </SvgIcon>
))

export default AutomaticIcon
