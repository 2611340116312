import React, { FunctionComponent, useState } from 'react'
import { DraggableDialog, DraggableDialogTitle } from 'components/elements/DraggableDialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { useApi } from 'components/providers/ApiProvider'
import Grid from '@material-ui/core/Grid'
import { useMessage } from 'components/elements/Message'
import Alert from '@material-ui/lab/Alert'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'

const BarrierGroup = ({ title, barriers, handleButtonClick }) => {
  return <>
    {Boolean(title) && <Typography variant="body1" gutterBottom>{title}</Typography>}
    <Grid container spacing={1}>
      {barriers.map(barrier => {
        return <Grid item key={barrier.id}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ width: 150 }}
            onClick={() => handleButtonClick(barrier)}
          >
            {barrier.code}
          </Button>
        </Grid>
      })}
    </Grid>
  </>
}

type Props = {
  open: boolean
}
type Functions = {
  setOpen(value: boolean): void
}
type AllProps = Props & Functions

const BarriersDialog: FunctionComponent<AllProps> = ({ open, setOpen }) => {
  const { t } = useTranslation()
  const api = useApi()
  const { showSuccess, showError } = useMessage()

  const [barriers, setBarriers] = useState(null)

  const handleEnter = () => {
    api.getBarriersList()
      .then(data => {
        const sortedBarriers = data.barriers.sort((a, b) => {
          return a.code.localeCompare(b.code)
        })

        const groupedBarriers = new Map()
        const otherBarriers = []

        sortedBarriers.forEach(barrier => {
          const barrierCodeParts = barrier.code.split('-')

          if (barrierCodeParts.length == 1) {
            otherBarriers.push(barrier)
          } else {
            const zoneCode = barrierCodeParts[0]

            if (!groupedBarriers.has(zoneCode)) {
              groupedBarriers.set(zoneCode, [])
            }
            groupedBarriers.get(zoneCode).push(barrier)
          }
        })

        if (groupedBarriers.size || otherBarriers.length) {
          setBarriers([groupedBarriers, otherBarriers])
        } else {
          setBarriers([])
        }
      })
      .catch(() => {
        showError(`${t('alerts.couldNotLoadData')} ${t('alerts.tryLater')}`)
      })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleExited = () => {
    setBarriers(null)
  }

  const handleBarrierButtonClick = (barrier) => {
    api.openBarrier(barrier.id)
      .then(data => {
        if (!data || !data.status) {
          throw new Error
        }

        showSuccess(t('messages.barrierOpened', { code: barrier.code }))
      })
      .catch(() => {
        showError(t('alerts.couldNotOpenBarrier'))
      })
  }

  return (
    <DraggableDialog fullWidth open={open} onEnter={handleEnter} onClose={handleClose} onExited={handleExited}>
      <DraggableDialogTitle>{t('barriers')}</DraggableDialogTitle>
      <DialogContent style={{ overflowY: 'unset' }}>
        {!barriers && (
          <Skeleton />
        )}

        {barriers && !barriers.length && (
          <div style={{ width: '100%', padding: '12px 24px' }}>
            <Alert severity="info">
              {t('noBarriers')}
            </Alert>
          </div>
        )}

        {barriers && Boolean(barriers.length) && (
          <Grid container spacing={2}>
            {[...barriers[0]].map(([zoneCode, zoneBarriers], key) => (
              <Grid item xs={12} key={key}>
                <BarrierGroup title={barriers[0].size > 1 || barriers[1].length ? zoneCode : null} barriers={zoneBarriers} handleButtonClick={handleBarrierButtonClick} />
              </Grid>
            ))}

            {Boolean(barriers[1].length) && (
              <Grid item xs={12}>
                <BarrierGroup title={barriers[0].size ? t('otherBarriers') : null} barriers={barriers[1]} handleButtonClick={handleBarrierButtonClick} />
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
        >
          {t('cancel')}
        </Button>
      </DialogActions>
    </DraggableDialog>
  )
}

export default BarriersDialog
