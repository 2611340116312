import React, { FunctionComponent } from 'react'
import { Box } from '@material-ui/core'
import Toolbar from './partials/Toolbar'
import List from './partials/List'
import { PermitsProvider } from './PermitsProvider'
import Message, { MessageProvider } from 'components/elements/Message'

type Props = {}
type Functions = {}
type AllProps = Props & Functions

const Permits: FunctionComponent<AllProps> = ({}) => {
  return (
    <Box p={1.5}>
      <PermitsProvider>
        <MessageProvider>
          <Toolbar />
          <List />
          <Message />
        </MessageProvider>
      </PermitsProvider>
    </Box>
  )
}

export default Permits
