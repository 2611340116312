import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IParkingPaymentsListData, ParkingLotSessionPaymentsPaymentOption, useApi } from 'components/providers/ApiProvider'
import { useParkingPayments } from 'ParkingPayments/ParkingPaymentsProvider'
import Container from '@material-ui/core/Container'
import LinearProgress from '@material-ui/core/LinearProgress'
import Alert from '@material-ui/lab/Alert'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Skeleton from '@material-ui/lab/Skeleton'
import TableBody from '@material-ui/core/TableBody'
import TablePagination from '@material-ui/core/TablePagination'
import { TablePaginationActions } from 'Elements/TablePaginationActions'
import { formatDateTimeFieldToISO, formatMoney, formatTimestamp } from 'utils'
import { useHistory } from 'react-router-dom'
import { useMessage } from 'Elements/Message'
import PaymentOptionChip from 'Elements/PaymentOptionChip'
import MobillyPaymentOptionChip from 'Elements/MobillyPaymentOptionChip'
import { theme } from 'components/providers/ThemeProvider'
import { makeStyles } from '@material-ui/core/styles'
import EditPlateNumberDialog, { EditPlateNumberDialogData } from 'ParkingPayments/partials/EditPlateNumberDialog'
import ListIconButton from 'Elements/ListIconButton'
import IconEdit from '@material-ui/icons/Edit'
import IconInfo from '@material-ui/icons/InfoOutlined'
import Tooltip from '@material-ui/core/Tooltip'
import IconSearchInNewWindow from '@material-ui/icons/Pageview'
import queryString from 'query-string'
import { addHours, format, subHours } from 'date-fns'

const useClasses = makeStyles(theme => ({
  fadeTableRow: {
    '& td': {
      color: theme.palette.grey['A200'],
    },
  },
}))

const PlateNumber = ({ value }: { value: string }) => {
  return <span style={{ fontFamily: '"Roboto Mono", monospace' }}>{value}</span>
}

type Props = {}
type Functions = {}
type AllProps = Props & Functions

const List: FunctionComponent<AllProps> = ({}) => {
  const { t } = useTranslation()
  const api = useApi()
  const history = useHistory()
  const { filter, loadList, listLoading, setListLoading, listPerPage, setLoadChangePerPageList, listPage, setListPage, setLoadChangePageList } = useParkingPayments()
  const { fadeTableRow } = useClasses()
  const { showError } = useMessage()

  const [modify, setModify] = useState<boolean>(false)
  const [payments, setPayments] = useState<IParkingPaymentsListData[]>(null)
  const [totalPayments, setTotalPayments] = useState(0)

  const [openEditPlateNumberDialog, setOpenEditPlateNumberDialog] = useState(false)
  const [editPlateNumberDialogData, setEditPlateNumberDialogData] = useState<EditPlateNumberDialogData | null>(null)

  const load = (onLoad?: Function, page?: number) => {
    if (!page) {
      setListLoading(true)
    }

    api.getParkingPaymentsList({
      zone_id: filter.zone || undefined,
      plate_number: filter.plateNumber.toUpperCase() || undefined,
      ...(filter.paidFrom && { paid_from: formatDateTimeFieldToISO(filter.paidFrom) }),
      ...(filter.paidTill && { paid_till: formatDateTimeFieldToISO(filter.paidTill) }),
      state: filter.state || undefined,
      limit: listPerPage,
      page: page || listPage,
    })
      .then(data => {
        if (!page && data.payments && !data.payments.length && data.totalPayments) {
          const pages = Math.ceil(data.totalPayments / listPerPage)

          if (listPage > pages) {
            setListPage(pages)
            load(onLoad, pages)

            return
          }
        }

        setListLoading(false)

        if (onLoad) {
          onLoad(data.payments)
        }

        setModify(Boolean(data.modify))
        setPayments(data.payments)
        setTotalPayments(data.totalPayments)
      })
      .catch(error => {
        setListLoading(false)

        if (error.status == 401) {
          history.push('/')

          return
        }

        showError(`${t('alerts.couldNotLoadData')} ${t('alerts.tryLater')}`)
      })
  }

  useEffect(() => {
    load()
  }, [loadList])

  const handleSearchPeriod = (zoneId: number, from: string, till: string) => {
    const url = {
      url: location.origin + '/my-parking-lot/parking-lot',
      query: {
        zone: zoneId.toString(),
        dateFrom: format(subHours(new Date(from), 1), 'dd.MM.yyyy HH:mm'),
        dateTill: format(addHours(new Date(till), 1), 'dd.MM.yyyy HH:mm'),
      },
    }

    window.open(queryString.stringifyUrl(url, { sort: false }), '_blank')
  }

  const handleSearchPlateNumber = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, zoneId: number, plateNumber: string) => {
    if (event.altKey && plateNumber.length >= 4) {
      plateNumber = plateNumber.substr(1, plateNumber.length - 2)
    }

    const url = {
      url: location.origin + '/my-parking-lot/parking-lot',
      query: {
        zone: zoneId.toString(),
        plateNumber,
      },
    }

    window.open(queryString.stringifyUrl(url, { sort: false }), '_blank')
  }

  const handleEditPlateNumber = (payment: IParkingPaymentsListData) => {
    setEditPlateNumberDialogData({ id: payment.external_payment_id, plateNumber: payment.plate_number })
    setOpenEditPlateNumberDialog(true)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setLoadChangePageList(page + 1)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLoadChangePerPageList(parseInt(event.target.value, 10))
  }

  return (
    <>
      {payments && listLoading && (
        <LinearProgress style={{ height: 1, marginBottom: -1 }} />
      )}

      {payments && !payments.length && (
        <Container maxWidth="md" style={{ paddingTop: 36 }}>
          <Alert severity="info">{t('noRecords')}</Alert>
        </Container>
      )}

      {(!payments || Boolean(payments.length)) && (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                {!payments && (
                  <TableRow>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                )}
                {payments && (
                  <TableRow>
                    <TableCell>{t('zone')}</TableCell>
                    <TableCell>{t('amount')}</TableCell>
                    <TableCell>{t('paidAt')}</TableCell>
                    <TableCell />
                    <TableCell>{t('period')}</TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>{t('plateNumber')}</TableCell>
                    <TableCell>{t('session')}</TableCell>
                    {modify && <TableCell />}
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {!payments && (
                  <TableRow>
                    <TableCell>
                      <Skeleton />
                      <Skeleton style={{ margin: '8px 0' }} />
                      <Skeleton />
                      <Skeleton style={{ margin: '8px 0' }} />
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                )}
                {payments && payments
                  .map((payment, key) => {
                    const closedPayment =
                      payment.session_entry_at && payment.session_exit_at
                      && (!payment.entry_at || !payment.exit_at || !(new Date(payment.exit_at).getTime() <= new Date(payment.session_entry_at).getTime() || new Date(payment.entry_at).getTime() >= new Date(payment.session_exit_at).getTime()))

                    return (<TableRow key={key} hover {...(closedPayment && { className: fadeTableRow })}>
                      <TableCell>{payment.zone}</TableCell>
                      <TableCell>
                        {formatMoney(payment.amount)}
                        {Boolean(payment.amount_refunded) && <> (<span style={{ color: 'orange' }}>{formatMoney(payment.amount_refunded)}</span>)</>}
                      </TableCell>
                      <TableCell>{formatTimestamp(payment.paid_at)}</TableCell>
                      <TableCell style={{ paddingTop: 14, paddingBottom: 14 }}>
                        {payment.option == ParkingLotSessionPaymentsPaymentOption.MobillyAutomatic && (
                          <MobillyPaymentOptionChip
                            letter="A"
                            label="Automatic"
                          />
                        )}
                        {payment.option == ParkingLotSessionPaymentsPaymentOption.MobillyStartStop && (
                          <MobillyPaymentOptionChip
                            label="Start-stop"
                          />
                        )}
                        {payment.option == ParkingLotSessionPaymentsPaymentOption.MobillyMPay && (
                          <MobillyPaymentOptionChip
                            label="Mpay"
                          />
                        )}
                        {payment.option == ParkingLotSessionPaymentsPaymentOption.MobillyPostpay && (
                          <MobillyPaymentOptionChip
                            label="Postpay"
                          />
                        )}
                        {payment.option == ParkingLotSessionPaymentsPaymentOption.CashRegister && (
                          <PaymentOptionChip
                            label={t('cashRegister')}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {(!payment.entry_at || !payment.exit_at) ? <>{`—`}</> : <>
                          <ListIconButton
                            icon={<IconSearchInNewWindow />}
                            color="secondary"
                            spacingLeft
                            onClick={() => handleSearchPeriod(payment.zone_id, payment.entry_at, payment.exit_at)}
                            style={{ marginRight: 12 }}
                          />
                          <span style={{
                            ...(((payment.session_entry_at && new Date(payment.exit_at).getTime() <= new Date(payment.session_entry_at).getTime()) || (payment.session_exit_at && new Date(payment.entry_at).getTime() >= new Date(payment.session_exit_at).getTime())) && { color: theme.palette.primary.main }),
                          }}>{formatTimestamp(payment.entry_at)} - {formatTimestamp(payment.exit_at)}</span>
                        </>}
                      </TableCell>
                      <TableCell>
                        <ListIconButton
                            icon={<IconSearchInNewWindow />}
                            color="secondary"
                            spacingLeft
                            onClick={(e) => handleSearchPlateNumber(e, payment.zone_id, payment.session_plate_number || payment.plate_number)}
                            style={{ marginRight: 12 }}
                          />
                        <PlateNumber value={payment.session_plate_number || payment.plate_number} />
                        {payment.modify && payment.external_payment_id && !payment.session_id && (
                          <ListIconButton
                            icon={<IconEdit />}
                            spacingLeft
                            onClick={() => handleEditPlateNumber(payment)}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {(!payment.session_entry_at && !payment.session_exit_at) ? <>{`—`}</> : <>{formatTimestamp(payment.session_entry_at)} - {payment.session_exit_at ? formatTimestamp(payment.session_exit_at) : '...'}</>}
                      </TableCell>
                      {modify && (
                        <>
                          <TableCell align="center" style={{ width: 62 }}>
                            <Tooltip title={<>
                              {`Maksājums #: ${payment.id}`}
                              {payment.session_id && <><br />{`Sesija #: ${payment.session_id}`}</>}
                            </>} placement={'top'} arrow interactive>
                              <IconInfo fontSize="small" style={{ verticalAlign: 'middle' }} />
                            </Tooltip>
                          </TableCell>
                        </>
                      )}
                    </TableRow>)
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={totalPayments}
            rowsPerPage={listPerPage}
            page={listPage - 1}
            labelRowsPerPage={`${t('show')}:`}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} / ${count !== -1 ? count : '...'}`}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </>
      )}
      {modify && <EditPlateNumberDialog open={openEditPlateNumberDialog} setOpen={setOpenEditPlateNumberDialog} data={editPlateNumberDialogData} />}
    </>
  )
}

export default List
