import React, { FunctionComponent } from 'react'
import { Redirect, Route } from 'react-router-dom'
import MainNavigation from 'components/elements/main-navigation/MainNavigation';
import {useApi} from "components/providers/ApiProvider";

export type Props = {
  path: string
}
export type Functions = {}
export type AllProps = Props & Functions
export const PrivateRoute: FunctionComponent<AllProps> = ({
  children,
  path,
}) => {
  const api = useApi();
  return (
    <Route
      path={path}
      render={({ location }) => {
        return api.isLoggedOn() ? (
          <MainNavigation>{children}</MainNavigation>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }}
    />
  )
}

export default PrivateRoute
