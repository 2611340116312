import React, { FunctionComponent } from 'react'
import { Backdrop, CircularProgress } from '@material-ui/core'
import { usePreloader } from 'components/providers/PreloaderProvider'

export type Props = {}
export type Functions = {}
export type AllProps = Props & Functions

export const Preloader: FunctionComponent<AllProps> = () => {
  const { isOpen } = usePreloader()
  return (
    <Backdrop style={{ zIndex: 1000, color: '#fff' }} open={isOpen}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
export default Preloader
