const API_URL = process.env.API_URL || 'https://api.mpay.lv'
const MY_MOBILLY_URL = process.env.MY_MOBILLY_URL || 'https://mans.mobilly.lv'
const OAUTH_MOBILLY_CLIENT_ID = process.env.OAUTH_MOBILLY_CLIENT_ID || 'mpay'
const MPAY_ADAPTER_URL = process.env.MPAY_ADAPTER_URL || 'https://adapters.mpay.lv/mpay_prod'

module.exports = {
  uri: API_URL,
  myMobillyUri: MY_MOBILLY_URL,
  oauthMobillyClientId: OAUTH_MOBILLY_CLIENT_ID,
  mpayAdapter: MPAY_ADAPTER_URL,
}
