import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'

export const useClasses = makeStyles((theme: Theme) => ({
  toolbarContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingLeft: theme.spacing(10),
    },
  },
  plateNumberFieldWrapper: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 300,
    },
  },
  zoneFieldWrapper: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 200,
    },
  },
}))
