import React from 'react'
import { lv } from 'date-fns/locale'

type Locales = 'lv'

interface DatePickerLocaleContext {
  locale: Locale
  setLocale: (loacale: Locales) => void
}

const Context = React.createContext<DatePickerLocaleContext | null>(null)

export const useDatePickerLocale = () => {
  const context = React.useContext(Context)
  if (!context) {
    throw new Error(
      'useDatePickerLocale must be used within a DatePickerLocaleProvider',
    )
  }
  return context
}

type Props = {
  children: React.ReactNode
}

export function DatePickerLocaleProvider({ children }: Props) {
  const [locale, setLocale] = React.useState(lv)

  function handleLocaleChange(locale: Locales) {
    switch (locale) {
      case 'lv':
        setLocale(lv)
        break
      default:
        throw new Error(`unsuported locale: ${locale}`)
    }
  }

  return (
    <Context.Provider value={{ locale, setLocale: handleLocaleChange }}>
      {children}
    </Context.Provider>
  )
}
