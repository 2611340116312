import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import PrevNext from 'components/elements/prev-next/PrevNext'
import { Box } from '@material-ui/core'
import {
  SubscriptionStep,
  useSubscription,
} from 'components/providers/SubscriptionsProvider'
import VehicleManager from 'components/views/subscriptions/buy/common/vehicle-manager/VehicleManager'
import { Car } from 'models/Car'

export type Props = {}
export type Functions = {}
export type AllProps = Props & Functions
export default withRouter(({ history }) => {
  const { setStep, setVehicles, selectedCars, addCar } = useSubscription()
  const [checked, setChecked] = useState<Car[]>(selectedCars)
  const nextButtonDisabled = !Boolean(checked.length)

  const proceed = (next: boolean) => {
    setVehicles(checked)
    if (!next) {
      return setStep(SubscriptionStep.SELECT_TYPE)
    }
    setVehicles(checked)
  }

  return (
    <Box p={0}>
      <VehicleManager
        selectedCars={selectedCars}
        onChange={setChecked}
        onAddCar={addCar}
      />

      <PrevNext
        style={{ marginTop: 60 }}
        onClick={proceed}
        nextDisabled={nextButtonDisabled}
      />
    </Box>
  )
})
