import React, { FunctionComponent } from 'react'
import { Wrap, ZoneWrap, ZoneBubble, Title } from './ZoneList.css'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { ZoneOfferModel } from 'models/ZoneOfferModel'
import { useTranslation } from 'react-i18next'
export type Props = {
  zones: ZoneOfferModel[]
}
export type Functions = {
  onClick(zone: ZoneOfferModel)
}

export type AllProps = Props & Functions

export const ZoneList: FunctionComponent<AllProps> = ({
  zones,
  onClick,
}) => {
  const { i18n } = useTranslation()

  return (
    <Wrap>
      {zones.sort((a, b) => a.shortName.localeCompare(b.shortName)).map(zone => {
        const localization = zone.localizations.find(l=>l.language === i18n.language)
        return (
          <ZoneWrap key={zone.id} onClick={() => onClick(zone)}>
            <ZoneBubble style={zone.shortName.length > 3 ? { fontSize: '10px' } : undefined}>{zone.shortName}</ZoneBubble>
            <Title>{localization ? localization.name : ''}</Title>
            <ChevronRightIcon />
          </ZoneWrap>
        )
      })}
    </Wrap>
  )
}

export default ZoneList
