import React, { FunctionComponent, useContext, useState } from 'react'
import { ISpecialRatesListRateData, useApi } from 'components/providers/ApiProvider'
import { useTranslation } from 'react-i18next'

type RootState = {
  rates: ISpecialRatesListRateData[] | null
  listLoading: boolean
}

type RootFunctions = {
  loadRates(): void
}

const InitialState: RootState = {
  rates: null,
  listLoading: false,
}

export const SpecialRatesContext = React.createContext<[RootState, (state: any) => void]>([InitialState, () => {
}])

export const SpecialRatesProvider: FunctionComponent = ({ children }) => {
  const [state, setState] = useState<RootState>(InitialState)

  return (
    <SpecialRatesContext.Provider value={[state, setState]}>
      {children}
    </SpecialRatesContext.Provider>
  )
}

export const useSpecialRates = (): RootFunctions & RootState => {
  const [state, setState] = useContext(SpecialRatesContext)

  const { t } = useTranslation()
  const api = useApi()

  const loadRates = () => {
    setListLoading(true)

    api.getSpecialRatesList().then(data => {
      setState((state: RootState) => ({
        ...state,
        rates: data.sessions,
        listLoading: false,
      }))
    }).catch(error => {
      setListLoading(false)

      let message

      switch (error.code) {
        default:
          message = t('alerts.tryLater')
      }

      alert(`${t('alerts.couldNotLoadAssignedSpecialRateData')} ${message}`)
    })
  }

  const setListLoading = (status: boolean) => {
    setState((state: RootState) => ({
      ...state,
      listLoading: status,
    }))
  }

  return {
    loadRates,
    ...state,
  }
}
