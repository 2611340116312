import React, { FunctionComponent, useEffect, useState } from 'react'
import { Car } from 'models/Car'
import {
  Chip,
  Input,
  InputAdornment,
  InputLabel, useMediaQuery,
  withStyles,
} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Tooltip from '@material-ui/core/Tooltip'
import HelpIcon from '@material-ui/icons/Help'
import DriveEtaIcon from '@material-ui/icons/DriveEta'
import AddIcon from '@material-ui/icons/Add'
import { normalizePlate } from 'utils'
import { Alert, Autocomplete } from '@material-ui/lab'
import { useSubscription } from 'components/providers/SubscriptionsProvider'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/core/styles'

const VehicleInput = withStyles(theme => ({
  input: {
    backgroundColor: '#fff',
    textTransform: 'uppercase',
    fontFamily: '"Roboto Mono", monospace',
  },
}))(Input)

export type Props = {
  selectedCars: Car[]
  extraCars?: Car[]
}
export type Functions = {
  onChange(checked: Car[])
  onAddCar(car: Car)
}
export type AllProps = Props & Functions
export const VehicleManager: FunctionComponent<AllProps> = ({
  onChange,
  selectedCars,
  extraCars,
  onAddCar,
}) => {
  const [checked, setChecked] = useState<Car[]>(selectedCars)
  const [newPlateNumber, setNewPlateNumber] = useState('')
  const [error, setError] = useState(null)
  const { cars } = useSubscription()
  const { t } = useTranslation()
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    onChange(checked)
  }, [checked])

  const hasError = Boolean(error)
  return (
    <div>
      <div style={{ margin: '0 -8px' }}>
        {checked.map((car, key) => (
          <Chip
            label={car.plateNumber}
            variant="outlined"
            color="secondary"
            key={key}
            onDelete={() => {
              setChecked(
                checked.reduce((acc, current) => {
                  if (current.plateNumber !== car.plateNumber) {
                    acc = [...acc, current]
                  }

                  return acc
                }, []),
              )
            }}
            style={{ margin: 8, fontFamily: '"Roboto Mono", monospace' }}
          />
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          marginTop: '40px',
          flexDirection: 'column',
        }}
      >
        {Boolean(error) && (
          <Alert
            severity="error"
            style={{
              marginBottom: '20px',
              maxWidth: '360px',
              width: '100%',
            }}
          >
            {error}
          </Alert>
        )}

        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Autocomplete
            inputValue={newPlateNumber}
            onInputChange={(event, value) => {
              const normalizedValue = normalizePlate(value)

              if (value.toUpperCase() == normalizedValue) {
                setNewPlateNumber(value)

                return
              }

              const target = event.target as HTMLInputElement
              const valueLengthDiff = normalizedValue.length - value.length
              const selectionStart = target.selectionStart + valueLengthDiff
              const selectionEnd = target.selectionEnd + valueLengthDiff

              setNewPlateNumber(normalizedValue)

              setTimeout(target => {
                target.selectionStart = selectionStart
                target.selectionEnd = selectionEnd
              }, 0, target)
            }}
            options={cars.filter(car => !checked.find(checkedCar => checkedCar.plateNumber == car.plateNumber)).map(car => car.plateNumber)}
            freeSolo
            renderInput={(params) => (
              <FormControl error={hasError} ref={params.InputProps.ref}>
                <InputLabel>
                  {t('plateNumber')}
                  <Tooltip title={`${t('example')}: AB1234`} placement={'top'} arrow={true}>
                    <HelpIcon
                      fontSize={'inherit'}
                      style={{
                        verticalAlign: 'bottom',
                        marginLeft: 4,
                      }}
                    />
                  </Tooltip>
                </InputLabel>
                <VehicleInput
                  {...params.inputProps}
                  inputProps={{ maxLength: 10 }}
                  startAdornment={
                    <InputAdornment position="start">
                      <DriveEtaIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            )}
            style={{maxWidth: 200, marginRight: 12 }}
          />
          <Button
            size="small"
            variant="contained"
            color="secondary"
            startIcon={!smallDevice && <AddIcon />}
            onClick={() => {
              const plateNumber = newPlateNumber.toUpperCase()

              if (!plateNumber.length) {
                return setError(
                  t('subscriptionPurchase.alertProvideVehiclePlateNumber'),
                )
              }

              if (checked.find(car => car.plateNumber === plateNumber)) {
                return setError(
                  t('subscriptionPurchase.alertPlateNumberAlreadyAdded'),
                )
              }

              setError(null)
              setChecked([...checked, new Car({ plateNumber })])
              setNewPlateNumber('')
            }}
          >
            {t('add')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default VehicleManager
