import React, { FunctionComponent, useContext, useState } from 'react'
import { Car } from 'models/Car'
import { ActiveSubscription } from 'models/ActiveSubscription'
import { useApi } from 'components/providers/ApiProvider'
import { Offer, ZoneOfferModel } from 'models/ZoneOfferModel'

export enum SubscriptionStep {
  NONE = null,
  SELECT_ZONE = 0,
  SELECT_TYPE = 1,
  SELECT_VEHICLE = 2,
  CONFIRM = 3,
}

export type RootState = {
  selectedZone: ZoneOfferModel
  offer: { selected: Offer; amount: number }
  selectedCars: Car[]
  zones: ZoneOfferModel[]
  subscriptionZones: ZoneOfferModel[]
  currentStep: SubscriptionStep
  cars: Car[]
  activeSubscriptions: ActiveSubscription[]
  purchaseSubscription: ActiveSubscription
  isLoading: boolean
}
export type RootFunctions = {
  init(): Promise<{ hasSubscriptions: boolean }>
  setSelectedZone(zone: ZoneOfferModel)
  setZones(zones: ZoneOfferModel[])
  setOffer(params: { offerId: number; amount: number })
  setVehicles(cars: Car[])
  getOffers(): Promise<{
    successful: boolean
  }>
  setStep(step: SubscriptionStep)
  resetAllState()
  addCar(car: Car)
  getActiveSubscriptions(): Promise<{
    successful: boolean
    message?: string
  }>
  setPurchaseSubscription(subscription: ActiveSubscription)
  purchase({
    autoRenew,
    date,
    language,
  }): Promise<{
    successful: boolean
    complete?: boolean
    redirectUrl?: string
    code?: number
    message?: string
    extra?: any
  }>
  modify(params: {
    subscription_id: number
    plates?: string[]
    notes?: string
  }): Promise<{
    successful: boolean
    modifyOk: boolean
    code?: number
    message?: string
    extra?: any
  }>
}

const InitialState: RootState = {
  selectedZone: null,
  offer: {
    amount: 1,
    selected: null,
  },
  zones: [],
  subscriptionZones: [],
  selectedCars: [],
  currentStep: SubscriptionStep.SELECT_ZONE,
  cars: [],
  activeSubscriptions: [],
  purchaseSubscription: null,
  isLoading: false,
}

export const SubscriptionContext = React.createContext<
  [RootState, (state: any) => void]
>([InitialState, () => {}])

export const SubscriptionProvider: FunctionComponent = ({ children }) => {
  const [state, setState] = useState<RootState>(InitialState)
  return (
    <SubscriptionContext.Provider value={[state, setState]}>
      {children}
    </SubscriptionContext.Provider>
  )
}

export const useSubscription = (): RootFunctions & RootState => {
  const [state, setState] = useContext(SubscriptionContext)
  const api = useApi()

  const setSelectedZone = (selectedZone: ZoneOfferModel) => {
    setState(state => ({
      ...InitialState,
      currentStep: SubscriptionStep.SELECT_TYPE,
      selectedZone,
      cars: state.cars,
      zones: state.zones,
    }))
  }

  const setOffer = ({ offerId, amount }) => {
    setState(
      (state: RootState): RootState => ({
        ...state,
        offer: {
          selected: state.selectedZone.offers.find(o => o.id === offerId),
          amount,
        },
        currentStep: SubscriptionStep.SELECT_VEHICLE,
      }),
    )
  }

  const setVehicles = (selectedCars: Car[]) => {
    setState(state => ({
      ...state,
      currentStep: SubscriptionStep.CONFIRM,
      selectedCars,
    }))
  }

  const setZones = (zones: ZoneOfferModel[]) => {
    setState(state => ({
      ...state,
      zones,
    }))
  }

  const setCars = (cars: Car[]) =>
    setState(state => ({
      ...state,
      cars,
    }))

  const getOffers = async () => {
    const { successful, zones, cars } = await api.getOffers()

    setZones(zones)
    setCars(cars)

    return {
      successful,
    }
  }

  const setStep = (currentStep: SubscriptionStep) => {
    setState(state => ({
      ...state,
      currentStep,
    }))
  }

  const resetAllState = () => {
    setState(state => ({
      ...InitialState,
      zones: state.zones,
      cars: state.cars,
    }))
  }

  const purchase = async ({
    autoRenew,
    date,
    language,
  }): Promise<{
    successful: boolean
    complete?: boolean
    redirectUrl?: string
    code?: number
    message?: string
    extra?: any
  }> => {
    const { selectedCars, offer } = state
    return await api.makePurchase({
      auto_renew: autoRenew,
      offer_id: offer.selected.id,
      count: offer.amount,
      plates: selectedCars.map(c => c.plateNumber),
      start: date,
    }, language)
  }

  const init = async (): Promise<{ hasSubscriptions: boolean }> => {
    return new Promise<{ hasSubscriptions: boolean }>(async resolve => {
      setState(
        (state: RootState): RootState => ({
          ...state,
          isLoading: true,
        }),
      )
      await getOffers()
      await getActiveSubscriptions()
      setState(
        (state: RootState): RootState => {
          resolve({
            hasSubscriptions: Boolean(state.activeSubscriptions.length),
          })
          return {
            ...state,
            isLoading: false,
          }
        },
      )
    })
  }

  const addCar = (car: Car) => {
    setState(
      (state: RootState): RootState => ({
        ...state,
        cars: [...state.cars, car],
      }),
    )
  }

  const getActiveSubscriptions = async (): Promise<{
    successful: boolean
    message?: string
  }> => {
    const {
      successful,
      message,
      subscriptions,
    } = await api.getActiveSubscriptions()

    setState(
      (state: RootState): RootState => ({
        ...state,
        isLoading: false,
        activeSubscriptions: successful
          ? subscriptions
          : state.activeSubscriptions,
      }),
    )

    return {
      successful,
      message,
    }
  }

  const setPurchaseSubscription = (purchaseSubscription: ActiveSubscription) => {
    setState((state: RootState): RootState => ({
      ...state,
      purchaseSubscription,
    }))
  }

  const modify = async (params: {
    subscription_id: number
    plates?: string[]
    notes?: string
  }): Promise<{
    successful: boolean
    modifyOk: boolean
    code?: number
    message?: string
    extra?: any
  }> => {
    const modifyResponse = await api.modify({
      ...params,
    })

    if (!modifyResponse.successful) {
      return {
        ...modifyResponse,
        modifyOk: false,
      }
    }
    const { successful, message } = await getActiveSubscriptions()
    return { successful, message, modifyOk: true }
  }

  return {
    ...state,
    getActiveSubscriptions,
    addCar,
    init,
    purchase,
    resetAllState,
    setVehicles,
    setOffer,
    setSelectedZone,
    setZones,
    getOffers,
    setStep,
    modify,
    setPurchaseSubscription,
  }
}
