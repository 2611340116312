import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import { usePermissions } from 'components/providers/PermissionProvider'
import { hasPermission, subscriptionsPermitsPermission, subscriptionsSubscriptionsPermission } from 'utils'
import * as permissionType from '../../constants/permissions'
import { PurchaseSteps } from './subscriptions/buy/views/purchase-steps/PurchaseSteps'
import SpecialRates from './subscriptions/special-rates/SpecialRates'
import { ROUTE } from 'constants/Routes'
import Home from 'components/views/subscriptions/buy/views/home/Home'
import Subscriptions from 'components/views/subscriptions/subscriptions/Subscriptions'
import Permits from 'components/views/subscriptions/permits/Permits'

export default withRouter(({ history }) => {
  const permissions = usePermissions()

  const hasBuyPermission = hasPermission(
      permissions,
      permissionType.PAGE_SUBSCRIPTIONS_BUY,
    ),
    hasFreeTimePermission = hasPermission(
      permissions,
      permissionType.PAGE_SUBSCRIPTIONS_FREE_TIME,
    )
  const hasSubscriptionsPermission = subscriptionsSubscriptionsPermission(permissions)

  return (
    <>
      <Switch>
        {subscriptionsPermitsPermission(permissions) && (
          <Route path={ROUTE.SUBSCRIPTIONS.PERMITS} component={Permits} />
        )}
        {hasSubscriptionsPermission && (
          <Route path={ROUTE.SUBSCRIPTIONS.SUBSCRIPTIONS} component={Subscriptions} />
        )}
        {hasFreeTimePermission && (
          <Route path={ROUTE.SUBSCRIPTIONS.FREE_TIME} component={SpecialRates} />
        )}
        {hasBuyPermission && (
          <>
            <Route path={ROUTE.SUBSCRIPTIONS.BUY} component={PurchaseSteps} />
            <Route path={ROUTE.SUBSCRIPTIONS.HOME} component={Home} />
          </>
        )}
      </Switch>
    </>
  )
})
