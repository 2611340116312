import React, { FunctionComponent, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Slide from '@material-ui/core/Slide'
import { TransitionProps } from '@material-ui/core/transitions'
import { Alert as Alerts, AlertTitle } from '@material-ui/lab'
import { Severity, useAlert } from 'components/providers/AlertProvider'

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  },
)

export type Props = {
  staticConfig?: { severity?: Severity; message?: string; title?: string }
}
export type Functions = {}
export type AllProps = Props & Functions

export const Alert: FunctionComponent<AllProps> = ({ staticConfig }) => {
  const { severity, message, isOpen, closeAlert, title } = useAlert()
  const [staticOpen, useStaticOpen] = useState(Boolean(staticConfig))
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={Boolean(staticConfig) ? staticOpen : isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeAlert}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <Alerts
        variant="filled"
        severity={(staticConfig && staticConfig.severity) || severity}
        style={{ borderRadius: 0, marginBottom: '20px' }}
      >
        {(staticConfig && staticConfig.title) || title}
      </Alerts>

      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {(staticConfig && staticConfig.message) || message}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            if (Boolean(staticConfig)) {
              return useStaticOpen(false)
            }
            closeAlert()
          }}
          color="primary"
        >
          ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default Alert
