import React, { FunctionComponent, useEffect, useState } from 'react'
import * as yup from 'yup'
import useFormal from '@kevinwolf/formal-web'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { SubmitButton } from 'components/elements/SubmitButton'
import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { SelectField } from 'components/elements/SelectField'
import { ISpecialRatesZonesZoneData, useApi } from 'components/providers/ApiProvider'
import { useMessage } from 'components/elements/Message'
import { useSpecialRates } from '../SpecialRatesProvider'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { useClasses } from '../SpecialRates.css'
import { Autocomplete } from '@material-ui/lab'
import TextField from '@material-ui/core/TextField'
import { normalizePlate } from 'utils'
import debounce from 'debounce'

export type Props = {}
export type Functions = {}
export type AllProps = Props & Functions

const Toolbar: FunctionComponent<AllProps> = ({}) => {
  const { t, i18n } = useTranslation()
  const api = useApi()
  const history = useHistory()
  const classes = useClasses()

  const { showSuccess, showError } = useMessage()
  const { loadRates } = useSpecialRates()

  const [zones, setZones] = useState<ISpecialRatesZonesZoneData[]>([])
  const [plateNumberFieldOptions, setPlateNumberFieldOptions] = React.useState<string[]>([])

  useEffect(() => {
    api.getSpecialRatesZones()
      .then(data => {
        setZones(data.zones)
      })
      .catch(() => {
      })
  }, [])

  useEffect(() => {
    if (zones.length == 1) {
      formal.change('zone', zones[0].id)
    }
  }, [zones])

  const initialValues = {
    plateNumber: '',
    zone: 0,
    offer: null,
  }

  const schema = yup.object().shape({
    plateNumber: yup.string().required(),
    zone: yup.number().required().min(1),
    offer: yup.number().required(),
  })

  const formal = useFormal(initialValues, {
    schema,
    onSubmit: values => {
      api.assignSpecialRate({
        plate_number: values.plateNumber.trim().toUpperCase(),
        offer_id: values.offer,
      })
        .then(data => {
          if (!data || !data.status) {
            throw new Error
          }

          formal.change('plateNumber', '')

          loadRates()

          showSuccess(t('messages.freeTimeAssigned'))
        })
        .catch(error => {
          if (error.status == 401) {
            history.push('/')

            return
          }

          let message

          switch (error.code) {
            case 3:
              message = t('alerts.checkInput')
              break
            case 100:
              message = t('alerts.parkingSessionNotFound')
              break
            case 102:
              message = t('alerts.maxActiveSessionsReached', { limit: error.extra.limit })
              break
            default:
              message = t('alerts.tryLater')
          }

          showError(`${t('alerts.couldNotAssignFreeTime')} ${message}`)
        })
    },
  })

  useEffect(() => {
    const offers = (zones.find(z => z.id == formal.values.zone) || {}).offers || []

    formal.change('offer', offers.length ? offers[0].id : null)
  }, [formal.values.zone])

  const fetch = React.useMemo(
    () =>
      debounce((params: { zone_id: number, plate_number: string }, callback: (plate_numbers: string[]) => void) => {
        api.getSpecialRatesPlateNumbers(params)
          .then(data => {
            callback(data.plate_numbers)
          })
          .catch(() => {
          })
      }, 500),
    [],
  )

  React.useEffect(() => {
    let mounted = true

    if (formal.values.plateNumber == '' || !formal.values.zone) {
      fetch.clear()
      setPlateNumberFieldOptions([])

      return
    }

    fetch({ zone_id: formal.values.zone, plate_number: formal.values.plateNumber }, (plate_numbers: string[]) => {
      if (mounted) {
        setPlateNumberFieldOptions(plate_numbers)
      }
    })

    return () => {
      mounted = false
    }
  }, [formal.values.plateNumber, fetch])


  return (
    <Box className={classes.toolbarContainer}>
      <form {...formal.getFormProps()}>
        <Typography color="textSecondary" gutterBottom style={{ fontSize: 14, marginBottom: 14 }}>
          {t('specialRates.inputDataToAssignRate')}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} sm className={classes.plateNumberFieldWrapper}>
            <Autocomplete
              value={null}
              inputValue={formal.values.plateNumber}
              onInputChange={(event, value) => {
                const normalizedValue = normalizePlate(value)

                formal.change('plateNumber', normalizedValue)

                if (value.toUpperCase() == normalizedValue) {
                  return
                }

                const target = event.target as HTMLInputElement
                const valueLengthDiff = normalizedValue.length - value.length
                const selectionStart = target.selectionStart + valueLengthDiff
                const selectionEnd = target.selectionEnd + valueLengthDiff

                setTimeout(target => {
                  target.selectionStart = selectionStart
                  target.selectionEnd = selectionEnd
                }, 0, target)
              }}
              options={plateNumberFieldOptions}
              freeSolo
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('plateNumber')}
                  variant="outlined"
                  fullWidth
                  autoFocus
                  inputProps={{
                    ...params.inputProps,
                    maxLength: 10,
                    style: {
                      textTransform: 'uppercase',
                      fontFamily: '"Roboto Mono", monospace',
                    },
                  }}
                />
              )}
            />
          </Grid>
          <SubmitButton
            buttonText={t('assign')}
            style={{ minHeight: 56 }}
            disabled={formal.isSubmitting}
          />
        </Grid>
        <Grid container spacing={1} style={{ marginTop: 16 }}>
          <SelectField
            GridProps={{ xs: 12, sm: true, className: classes.zoneFieldWrapper }}
            label={t('zone')}
            optionsCustom={[
              {
                label: zones.length > 1 ? t('select') : '',
                id: 0,
              },
              ...zones.sort((a, b) => a.code.localeCompare(b.code)).map(zone => {
                const localization = (zone.localizations || []).find(l => l.language == i18n.language)

                return {
                  label: `${zone.code}${localization && localization.name ? ` ${localization.name}` : ''}`,
                  id: zone.id,
                }
              }),
            ]}
            {...formal.getFieldProps('zone')}
            disabled={zones.length == 1}
          />
          {((zones.find(z => z.id == formal.values.zone) || {}).offers || []).map((offer, key) => (
            <Grid item key={key}>
              <Button
                variant={formal.values.offer == offer.id ? 'contained' : 'outlined'}
                size="large"
                color="secondary"
                style={{ minHeight: 56 }}
                onClick={() => formal.change('offer', offer.id)}
              >
                {offer.name}
              </Button>
            </Grid>
          ))}
        </Grid>
      </form>
    </Box>
  )
}

export default Toolbar
