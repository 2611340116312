import React, { CSSProperties, FunctionComponent, useState } from 'react'
import { Wrap, Element, Label, Elements } from './CustomRadioWithInput.css'
import { TextField, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { InputBase } from '@material-ui/core'
import { onlyNumeric } from 'utils'


export enum Type {
  TEXT = 'text',
  NUMBER = 'number',
}
export enum InputMode {
  NUMERIC = 'numeric',
}

export const CustomInput = withStyles(theme => ({
  root: {},
  input: {
    border: 'none',
    fontSize: '14px',
    color: theme.palette.primary.main,
    fontWeight: 500,
    textAlign: 'center',
  },
}))(InputBase)

export type Option = {
  id: any
  title?: string
  input?: {
    value: string
    type?: Type
    inputMode?: InputMode
  }
}

export type Props = {
  options: Option[]
  label: string
  selectedId?: any
  style?: CSSProperties
}
export type Functions = {
  onChange(data: { id: any; inputValue?: string })
}

export type AllProps = Props & Functions

export const CustomRadioWithInput: FunctionComponent<AllProps> = ({
  options,
  selectedId,
  onChange,
  label,
  style,
}) => {
  const inputOption = options.find(option => Boolean(option.input))
  const [customValue, setCustomValue] = useState(
    inputOption && inputOption.input.value,
  )
  const [activeElement, setActiveElement] = useState(selectedId)
  const handleChange = ({ id, inputValue = null }) => {
    onChange({ id, ...(inputValue && { inputValue }) })
  }

  return (
    <Wrap style={{ ...style }}>
      {label && (
        <Typography
          style={{ fontSize: '14px' }}
          color="textSecondary"
          gutterBottom
        >
          {label}
        </Typography>
      )}
      <Elements>
        {options.map(option => {
          const isInput = Boolean(option.input)
          return (
            <Element
              key={option.id}
              active={activeElement === option.id}
              onClick={() => {
                setActiveElement(option.id)
                handleChange({
                  id: option.id,
                  ...(isInput && { inputValue: customValue }),
                })
              }}
            >
              {isInput ? (
                <CustomInput
                  inputProps={{ inputMode: 'numeric', maxLength: 2 }}
                  value={customValue}
                  onChange={e => {
                    const value = onlyNumeric(e.target.value)
                    setCustomValue(value)
                    handleChange({ id: option.id, inputValue: value })
                  }}
                  placeholder={option.title}
                  type={option.input.type}
                  {...(option.input.inputMode && {
                    inputMode: option.input.inputMode,
                  })}
                />
              ) : (
                option.title
              )}
            </Element>
          )
        })}
      </Elements>
    </Wrap>
  )
}

export default CustomRadioWithInput
