import React from 'react'
// @ts-ignore
import config from '../../../config'

interface IConfig {
  uri: string
  myMobillyUri: string
  mpayAdapter:string;
  oauthMobillyClientId: string
}

const Context = React.createContext<IConfig | null>(null)

export const useConfig = () => {
  const context = React.useContext(Context)
  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider')
  }
  return context
}

export const ConfigProvider: React.FC<{ value?: any }> = ({
  value,
  children,
}) => {
  return <Context.Provider value={value || config}>{children}</Context.Provider>
}
