export type Localization = {
  language: string
  name: string
  description: string
}

export enum DurationType {
  infinity = 'infinity',
  daysAbsolute = 'days_absolute',
  daysRelative = 'days_relative',
  monthsAbsolute = 'months_absolute',
  monthsRelative = 'months_relative',
  hours = 'hours',
}

export type Offer = {
  id: number
  name: string
  price: number
  durationType: DurationType
  durationLength: number
  maxActiveVehicles: string
  maxVehicles: string
  autoRenew: boolean
}
export class ZoneOfferModel {
  id: number
  shortName: string
  localizations: Localization[]
  offers: Offer[]
  constructor(json: any) {
    this.id = json.id
    this.shortName = json.short_name
    this.localizations = (json ? json.localizations : []).map(l => ({
      description: l.description,
      language: l.language,
      name: l.name,
    }))
    try{
      this.offers = json.offers.map(o => ({
        id: o.id,
        name: o.name,
        price: o.price,
        durationType: o.duration_type,
        durationLength: o.duration_length,
        maxActiveVehicles: o.max_active_vehicles,
        maxVehicles: o.max_vehicles,
        autoRenew: o.auto_renew,
      }))
    }catch (e) {
      console.warn(e)
      this.offers = []
    }

  }
}
