import React, { FunctionComponent, useEffect, useState } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { createStyles, Theme } from '@material-ui/core'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootCard: { width: '100%', marginBottom: '5px' },
    titleCard: { fontSize: 14 },
  }),
)

export type Cell = {
  value: string | JSX.Element
  color?: string
  fontFamily?: string
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  id?: any
  hiddenOnMobile?: boolean
}

export type Row = {
  cells: Cell[]
  id?: any
}
export type TableData = {
  rows: Row[]
  head: string[]
}

export type Props = {
  table: TableData
  mobileBreakpoint: number
}
export type Functions = {
  onClick?(id: any)
}
export type AllProps = Props & Functions
export const ResponsiveDataTable: FunctionComponent<AllProps> = ({
  onClick,
  mobileBreakpoint,
  table: { rows, head },
}) => {
  const [isDesktop, setIsDesktop] = useState<boolean>(
    window.innerWidth > mobileBreakpoint,
  )
  const classes = useStyles()

  useEffect(() => {
    const resize = () => {
      setIsDesktop(Boolean(window.innerWidth > mobileBreakpoint))
    }
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  const DESKTOP_TABLE = (
    <Table data-testid="data_table">
      <TableHead>
        <TableRow>
          {head.map((title, index) => (
            <TableCell key={index}>
              {title}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <React.Fragment key={index}>
            <TableRow
              onClick={() => {
                if (onClick) {
                  onClick(row.id)
                }
              }}
            >
              {row.cells.map((cell, index) => {
                let align: 'inherit' | 'left' | 'center' | 'right' | 'justify' =
                    'inherit',
                  value: any = ''

                if (typeof cell === 'string') {
                  value = cell
                } else {
                  value = (
                    <Box fontFamily={cell.fontFamily} color={cell.color}>
                      {cell.value}
                    </Box>
                  )
                  align = cell.align
                }

                return (
                  <TableCell key={index} align={align}>
                    {value}
                  </TableCell>
                )
              })}
            </TableRow>
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  )

  const MOBILE_TABLE = (
    <>
      {rows.map(({ id, cells }, index) => (
        <Card
          key={index}
          className={classes.rootCard}
          variant="outlined"
          onClick={() => {
            if (onClick) {
              onClick(id)
            }
          }}
        >
          <CardContent key={index} style={{ paddingBottom: '5px' }}>
            {cells.map((cell, index) => (
              <React.Fragment key={index}>
                {!cell.hiddenOnMobile && (
                  <>
                    {head[index] && (
                      <Typography
                        component="div"
                        color="textSecondary"
                        className={classes.titleCard}
                        style={{
                          textAlign: cell.align || 'left',
                        }}
                      >
                        {head[index]}
                      </Typography>
                    )}
                    <Typography
                      variant="body2"
                      component="div"
                      style={{
                        marginBottom: 12,
                        textAlign: cell.align || 'left',
                      }}
                    >
                      {cell.value}
                    </Typography>
                  </>
                )}
              </React.Fragment>
            ))}
          </CardContent>
        </Card>
      ))}
    </>
  )

  return isDesktop ? DESKTOP_TABLE : MOBILE_TABLE
}

export default ResponsiveDataTable
