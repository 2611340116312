import styled from 'styled-components'

export const Wrap = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
`
export const ZoneWrap = styled.li<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 8px;
  padding: 10px;
  background: ${({ theme }) => theme.palette.grey['700']};
  cursor: pointer;
`

export const ZoneBubble = styled.div`
  border-radius: 50%;
  background: #000;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-right: 10px;
  font-size: 12px;
  font-weight: 500;
  flex-shrink: 0;
`
export const Title = styled.p`
  margin: 0;
  flex: 1;
  color: ${({ theme }) => theme.palette.primary.main};
`
