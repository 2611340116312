import React, { FunctionComponent, memo, useState } from 'react'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { DateTimeField } from 'components/elements/DateTimeField'
import Grid from '@material-ui/core/Grid'
import useFormal from '@kevinwolf/formal-web'
import * as yup from 'yup'
import { IParkingLotStopEventsEventData, useApi } from 'components/providers/ApiProvider'
import { formatDateTimeFieldToISO, formatTimestamp, parseDateTimeField, yupDateTimeField } from 'utils'
import { useParkingLot } from '../ParkingLotProvider'
import { useMessage } from 'components/elements/Message'
import { DraggableDialog, DraggableDialogTitle } from 'components/elements/DraggableDialog'
import { SelectField } from 'components/elements/SelectField'
import Alert from '@material-ui/lab/Alert'
import { ImageZoom } from 'components/elements/ImageZoom'
import { ImagePlaceholder } from '../ParkingLot.css'
import { useTranslation } from 'react-i18next'

export interface DialogSession {
  id: number
  zoneId: number
  plateNumber: string
  entryAt: string
}

const EventImage = memo<{ src: string }>(({ src }) => {
  return src ? <ImageZoom src={src} /> : <ImagePlaceholder />
})

type Props = {
  open: boolean
  session: DialogSession
}
type Functions = {
  setOpen(value: boolean): void
}
type AllProps = Props & Functions

const StopSessionDialog: FunctionComponent<AllProps> = ({ open, setOpen, session }) => {
  if (!session) {
    return null
  }

  const { t } = useTranslation()
  const api = useApi()
  const { addZones: zones, setLoadList } = useParkingLot()
  const { showSuccess, showError } = useMessage()

  const [events, setEvents] = useState<IParkingLotStopEventsEventData[] | null>(null)

  const handleEnter = () => {
    api.getParkingLotStopEvents({
      zone_id: session.zoneId,
      plate_number: session.plateNumber,
      occurred_after: session.entryAt,
    })
      .then(data => {
        if (!data.events.length) {
          formal.change('event', -1)
        }

        setEvents(data.events)

        if (data.events.length == 1) {
          formal.change('event', data.events[0].id)
        }
      })
      .catch(() => {
        showError(t('alerts.couldNotLoadCameraEventData'))
      })

    const exitCameras = zones.find(zone => zone.id == session.zoneId).cameras.filter(camera => camera.type != 'entry')

    if (exitCameras.length == 1) {
      formal.change('exitCamera', exitCameras.pop().id)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleExited = () => {
    setEvents(null)
    formal.change('event', initialValues.event)
    formal.change('exitAt', initialValues.exitAt)
    formal.change('exitCamera', initialValues.exitCamera)
    formal.clearErrors()
  }

  const initialValues = {
    event: 0,
    exitAt: '',
    exitCamera: 0,
  }

  const schema = yup.object().shape({
    event: yup.number().required().integer().min(-1).notOneOf([0]),
    exitAt: yup.string().when('event', {
      is: -1,
      then: yupDateTimeField({ required: true }).test({
        test: value => {
          if (value == '') {
            return true
          }

          const valueDate = parseDateTimeField(value)

          if (valueDate <= (new Date(session.entryAt))) {
            return false
          }

          return valueDate <= (new Date)
        },
      }),
    }),
    exitCamera: yup.number().when('event', {
      is: -1,
      then: yup.number().required().integer().min(1),
    }),
  })

  const formal = useFormal(initialValues, {
    schema,
    onSubmit: values => {
      api.stopParkingLotSession({
        session_id: session.id,
        ...(values.event != -1 ? {
          event_id: values.event,
        } : {
          exit_at: formatDateTimeFieldToISO(values.exitAt.trim()),
          exit_camera_id: values.exitCamera,
        }),
      })
        .then(data => {
          if (!data || !data.status) {
            throw new Error
          }

          handleClose()

          setLoadList()

          showSuccess(t('messages.parkingSessionStopped'))
        })
        .catch(error => {
          let message

          switch (error.code) {
            case 3:
              message = t('alerts.checkInput')
              break
            default:
              message = t('alerts.tryLater')
          }

          showError(`${t('alerts.couldNotStopParkingSession')} ${message}`)
        })
    },
  })

  return (
    <DraggableDialog fullWidth open={open} onEnter={handleEnter} onClose={handleClose} onExited={handleExited}>
      <DraggableDialogTitle>{t('stopParkingSession')}</DraggableDialogTitle>
      <form {...formal.getFormProps()} noValidate>
        <DialogContent>
          <Grid container spacing={2}>
            {events && (
              <>
                {Boolean(events.length) ? (
                  <SelectField
                    GridProps={{ xs: 12 }}
                    label={t('cameraEvent')}
                    required
                    optionsCustom={[
                      { id: 0, label: t('select') },
                      ...events.map(event => ({
                        id: event.id,
                        label: formatTimestamp(event.occurred_at),
                      })),
                      { id: -1, label: t('otherTime') },
                    ]}
                    {...formal.getFieldProps('event')}
                  />
                ) : (
                  <Grid item xs={12} style={{ padding: '0 12px 24px' }}>
                    <Alert severity="info">
                      {t('noCameraEvents')}
                    </Alert>
                  </Grid>
                )}

                {formal.values.event > 0 && (
                  <Grid item xs={12}>
                    <EventImage src={events.find(event => event.id == formal.values.event).image_url} />
                  </Grid>
                )}
              </>
            )}

            {formal.values.event == -1 && (
              <>
                <DateTimeField
                  GridProps={{ xs: 6 }}
                  label={t('time')}
                  required
                  {...formal.getFieldProps('exitAt')}
                  disableFuture={true}
                  {
                    ...(session && { minDate: session.entryAt })
                  }
                />
                <SelectField
                  GridProps={{ xs: 6 }}
                  label={t('camera')}
                  required
                  optionsCustom={[
                    {
                      label: t('select'),
                      id: 0,
                    },
                    ...(!session ? [] : zones.find(zone => zone.id == session.zoneId).cameras.filter(camera => camera.type != 'entry').map(camera => ({
                      label: camera.code,
                      id: camera.id,
                    }))),
                  ]}
                  {...formal.getFieldProps('exitCamera')}
                />
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formal.isSubmitting}
          >
            {t('stop')}
          </Button>
        </DialogActions>
      </form>
    </DraggableDialog>
  )
}

export default StopSessionDialog
