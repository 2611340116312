import React, { FunctionComponent } from 'react'
import Box from '@material-ui/core/Box'
import { ParkingPaymentsProvider } from 'ParkingPayments/ParkingPaymentsProvider'
import Message, { MessageProvider } from 'Elements/Message'
import Toolbar from 'ParkingPayments/partials/Toolbar'
import List from 'ParkingPayments/partials/List'

type Props = {}
type Functions = {}
type AllProps = Props & Functions

const ParkingPayments: FunctionComponent<AllProps> = ({}) => {
  return (
    <Box p={1.5}>
      <ParkingPaymentsProvider>
        <MessageProvider>
          <Toolbar />
          <List />
          <Message />
        </MessageProvider>
      </ParkingPaymentsProvider>
    </Box>
  )
}

export default ParkingPayments
