export const ROUTE = {
    SUBSCRIPTIONS:{
        MAIN:'/subscriptions',
        HOME:'/subscriptions/home',
        BUY:'/subscriptions/buy',
        FREE_TIME:'/subscriptions/free-time',
        SUBSCRIPTIONS:'/subscriptions/subscriptions',
        PERMITS:'/subscriptions/permits',
    },
    MY_PARKING_LOT:{
        MAIN:'/my-parking-lot',
        PARKING_LOT:'/my-parking-lot/parking-lot',
        PAYMENTS:'/my-parking-lot/payments',
        ANALYTICS:'/my-parking-lot/analytics',
    },
}
