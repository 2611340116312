import styled from 'styled-components'

export const Wrap = styled.div`
    
`;

export const ButtonHolder = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 7px;
  grid-row-gap: 7px;
`

export const Label = styled.label`
    margin-bottom: 5px;
    display: block;
`;

export const ButtonWrap = styled.li<{ active?: boolean }>`
  background: ${({ theme, active }) =>
    active ? theme.palette.primary.main : theme.palette.grey['700']};
  color: ${({ theme, active }) =>
    active ? '#fff' : theme.palette.primary.main};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 5px;
  cursor: pointer;
`

export const Title = styled.span`
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
`

export const Subtitle = styled.span`
  margin-top: 2px;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
`
