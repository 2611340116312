import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Box } from '@material-ui/core'
import {
  SubscriptionStep,
  useSubscription,
} from 'components/providers/SubscriptionsProvider'
import PrevNext from 'components/elements/prev-next/PrevNext'
import FatRadioButtonList from 'components/elements/fat-radio-button-list/FatRadioButtonList'
import CustomRadioWithInput, {
  InputMode,
  Type,
} from 'components/elements/custom-radio-with-input/CustomRadioWithInput'
import Alert from '@material-ui/lab/Alert'
import { formatMoney } from 'utils'
import { Severity, useAlert } from 'components/providers/AlertProvider'
import { useTranslation } from 'react-i18next'
import { AlertTitle } from '@material-ui/lab'

export type Props = {}
export type Functions = {}
export type AllProps = Props & Functions
export default withRouter(({ history }) => {
  const { t } = useTranslation()
  const { selectedZone, offer, setOffer, setStep } = useSubscription()
  const { openAlert } = useAlert()
  if (!selectedZone) {
    return null
  }

  const offers = selectedZone.offers.map(offer => ({
    id: offer.id,
    title: offer.name,
    subtitle: offer.price ? formatMoney(offer.price) : undefined,
  }))

  const [selectedOfferId, setSelectedOfferId] = useState(
    (offer && offer.selected && offer.selected.id) || (offers.length === 1 ? offers[0].id : null),
  )

  const isCustomAmount = offer.amount > 4
  const [amount, setAmount] = useState({
    id: isCustomAmount ? 5 : offer.amount,
    value: offer.amount,
  })

  const handleOptions = ({ id, inputValue }) => {
    setAmount({ id, value: Number(id === 5 ? inputValue || 0 : id) })
  }

  const proceed = (next: boolean) => {
    if (!next) {
      return setStep(SubscriptionStep.SELECT_ZONE)
    }

    if (amount.value <= 0) {
      return openAlert({
        message: t('subscriptionPurchase.alertMandatoryAmount'),
        title: t('alerts.checkAllFields'),
        severity: Severity.ERROR,
      })
    }

    setOffer({
      amount: amount.value,
      offerId: selectedOfferId,
    })
  }

  let nextButtonDisabled = selectedOfferId == null || !amount.id

  return (
    <Box p={0}>
      <FatRadioButtonList
        label={t('subscriptionPurchase.selectSubscriptionType')}
        onChange={offer => setSelectedOfferId(offer.id)}
        buttons={offers}
        selectedId={selectedOfferId}
      />
      <CustomRadioWithInput
        style={{ marginTop: 40 }}
        label={t('subscriptionPurchase.selectSubscriptionAmount')}
        onChange={handleOptions}
        options={[
          {
            title: '1',
            id: 1,
          },
          {
            title: '2',
            id: 2,
          },
          {
            title: '3',
            id: 3,
          },
          {
            title: '4',
            id: 4,
          },
          {
            input: {
              type: Type.TEXT,
              inputMode: InputMode.NUMERIC,
              value: String(isCustomAmount ? amount.value : ''),
            },
            title: t('other').toLowerCase(),
            id: 5,
          },
        ]}
        selectedId={amount.id}
      />

      <Alert severity="warning" style={{marginTop:'30px'}}>
        <AlertTitle>{t('important')}</AlertTitle>
        {t('subscriptionPurchase.overbookingLegal')}
      </Alert>

      <PrevNext
        style={{ marginTop: 60 }}
        onClick={proceed}
        nextDisabled={nextButtonDisabled}
      />
    </Box>
  )
})
