export const PAGE_MY_PARKING_LOT_PARKING_LOT = 'PAGE_MY_PARKING_LOT_PARKING_LOT'
export const PAGE_MY_PARKING_LOT_PARKING_LOT_MODIFY = 'PAGE_MY_PARKING_LOT_PARKING_LOT_MODIFY'
export const PAGE_MY_PARKING_LOT_PARKING_LOT_BARRIERS = 'PAGE_MY_PARKING_LOT_PARKING_LOT_BARRIERS'

export const PAGE_MY_PARKING_LOT_PAYMENTS = 'PAGE_MY_PARKING_LOT_PAYMENTS'

export const PAGE_MY_PARKING_LOT_ANALYTICS = 'PAGE_MY_PARKING_LOT_ANALYTICS'

export const PAGE_SUBSCRIPTIONS_BUY = 'PAGE_SUBSCRIPTIONS_BUY'
export const PAGE_SUBSCRIPTIONS_FREE_TIME = 'PAGE_SUBSCRIPTIONS_FREE_TIME'
export const PAGE_SUBSCRIPTIONS_SUBSCRIPTIONS = 'PAGE_SUBSCRIPTIONS_SUBSCRIPTIONS'
export const PAGE_SUBSCRIPTIONS_PERMITS = 'PAGE_SUBSCRIPTIONS_PERMITS'
