import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { TableCell } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ButtonGroup from '@material-ui/core/ButtonGroup'

export const useClasses = makeStyles((theme: Theme) => ({
  fadeTableRow: {
    '& td': {
      color: theme.palette.grey['A200'],
    },
  },
  plateNumber: {
    fontFamily: '"Roboto Mono", monospace',
  },
}))

export const ToolbarButtonsGridContainer = withStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}))(Grid)

export const ToolbarFormGridContainer = withStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}))(Grid)

export const ListTableCell = withStyles({
  root: {
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
  },
})(TableCell)

export const ListPlateNumberImageTableCell = withStyles({
  root: {
    verticalAlign: 'top',
    padding: '16px 0 8px 16px',
    width: 116,
  },
})(TableCell)

export const ListExpandTableCell = withStyles({
  root: {
    verticalAlign: 'top',
    textAlign: 'center',
    padding: '14px 0 0 0',
  },
})(TableCell)

export const ListItemActionButton = withStyles({
  root: {
    justifyContent: 'left',
  },
})(Button)

export const ListArchiveEventButtonGroup = withStyles({
  root: {
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
})(ButtonGroup)

export const ListArchiveEventButton = withStyles({
  root: {
    justifyContent: 'left',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.14)',
    },
  },
})(Button)

export const ShowImageThumbnailsToggleButton = withStyles((theme: Theme) => ({
  root: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    '&.Mui-selected': {
      color: theme.palette.secondary.main,
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
  },
}))(ToggleButton)

export const ImagePlaceholder = styled.div`
  padding-bottom: 56.25%;
  background-color: rgba(0, 0, 0, 0.05);
  background-image: url("data:image/svg+xml,%3Csvg%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224%22%20fill%3D%22rgba(0%2C%200%2C%200%2C%200.1)%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m21%205v6.59l-3-3.01-4%204.01-4-4-4%204-3-3.01v-4.58c0-1.1.9-2%202-2h14c1.1%200%202%20.9%202%202zm-3%206.42%203%203.01v4.57c0%201.1-.9%202-2%202h-14c-1.1%200-2-.9-2-2v-6.58l3%202.99%204-4%204%204z%22%2F%3E%3C%2Fsvg%3E");
  background-size: 48px 48px;
  background-repeat: no-repeat;
  background-position: 50%;
`
