import React, { FunctionComponent } from 'react'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import useFormal from '@kevinwolf/formal-web'
import * as yup from 'yup'
import { useApi } from 'components/providers/ApiProvider'
import { useParkingLot } from '../ParkingLotProvider'
import { useMessage } from 'components/elements/Message'
import { DraggableDialog, DraggableDialogTitle } from 'components/elements/DraggableDialog'
import { PlateNumberField } from 'components/elements/PlateNumberField'
import { useTranslation } from 'react-i18next'
import { Link } from '@material-ui/core'

export interface EditSessionPlateNumberDialogData {
  sessionId: number
  plateNumber: string
}

type Props = {
  open: boolean
  data: EditSessionPlateNumberDialogData
}
type Functions = {
  setOpen(value: boolean): void
}
type AllProps = Props & Functions

const EditSessionPlateNumberDialog: FunctionComponent<AllProps> = ({ open, setOpen, data }) => {
  if (!data) {
    return null
  }

  const { t } = useTranslation()
  const api = useApi()
  const { setLoadEditSessionPlateNumberList, setSearchByPlateNumber } = useParkingLot()
  const { showSuccess, showError } = useMessage()
  let submitAndSearch = false

  const handleEnter = () => {
    formal.change('plateNumber', data.plateNumber)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleExited = () => {
    formal.change('plateNumber', initialValues.plateNumber)
    formal.clearErrors()
  }

  const handleSubmitAndSearch = () => {
    submitAndSearch = true
    formal.submit()
  }

  const handleCancelSession = (e, parking_session_id) => {
    e.preventDefault()

    setOpen(false)

    api.cancelParkingLotSession({
      session_id: parking_session_id,
    })
      .then(data => {
        if (!data || !data.status) {
          throw new Error
        }

        setLoadEditSessionPlateNumberList()

        showSuccess(t('messages.parkingSessionCanceled'))
      })
      .catch(error => {
        let message

        switch (error.code) {
          case 13:
            message = t('alerts.parkingSessionHasUnattachablePayments')
            break
          default:
            message = t('alerts.tryLater')
        }

        showError(`${t('alerts.couldNotCancelParkingSession')} ${message}`)
      })
  }

  const initialValues = {
    plateNumber: '',
  }

  const schema = yup.object().shape({
    plateNumber: yup.string().required(),
  })

  const formal = useFormal(initialValues, {
    schema,
    onSubmit: values => {
      const plateNumber = values.plateNumber.toUpperCase()

      api.updateParkingLotSessionPlateNumber({
        session_id: data.sessionId,
        plate_number: plateNumber,
      })
        .then(data => {
          if (!data || !data.status) {
            throw new Error
          }

          handleClose()

          if (submitAndSearch) {
            setSearchByPlateNumber(plateNumber)
          } else {
            setLoadEditSessionPlateNumberList()
          }

          showSuccess(t('messages.plateNumberChanged'))
        })
        .catch(error => {
          let message

          if (error.code == 12) {
            message = <span>
              {t('alerts.couldNotChangePlateNumber')} {t('alerts.parkingSessionOverlaps')} <Link href="#" style={{ color: 'inherit', textDecoration: 'underline' }} onClick={(e) => handleCancelSession(e, data.sessionId)}>{t('cancelSession')}</Link>.
            </span>
          } else {
            switch (error.code) {
              case 3:
                message = t('alerts.checkInput')
                break
              case 13:
                message = t('alerts.parkingSessionHasUnattachablePayments')
                break
              default:
                message = t('alerts.tryLater')
            }

            message = `${t('alerts.couldNotChangePlateNumber')} ${message}`
          }

          showError(message)
        })
    },
  })

  return (
    <DraggableDialog fullWidth open={open} onEnter={handleEnter} onClose={handleClose} onExited={handleExited}>
      <DraggableDialogTitle>{t('editPlateNumber')}</DraggableDialogTitle>
      <form {...formal.getFormProps()} noValidate>
        <DialogContent>
          <Grid container>
            <PlateNumberField
              GridProps={{ xs: 12 }}
              required
              {...formal.getFieldProps('plateNumber')}
              autoFocus={true}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formal.isSubmitting}
          >
            {t('edit')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            disabled={formal.isSubmitting}
            onClick={handleSubmitAndSearch}
          >
            {t('editAndSearch')}
          </Button>
        </DialogActions>
      </form>
    </DraggableDialog>
  )
}

export default EditSessionPlateNumberDialog
