import React from 'react'
import Button, { ButtonProps } from '@material-ui/core/Button'
import Grid, { GridProps } from '@material-ui/core/Grid'

interface Props {
  GridProps?: GridProps
  buttonText: string
}

export const SubmitButton = ({ GridProps, buttonText, ...props }: Props & ButtonProps) => (
  <Grid item {...GridProps}>
    <Button
      type="submit"
      variant="contained"
      color="primary"
      fullWidth
      style={{ height: '100%' }}
      {...props}
    >
      {buttonText}
    </Button>
  </Grid>
)
