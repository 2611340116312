import React, { FunctionComponent, useContext, useState } from 'react'

export enum Severity {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export type RootState = {
  isOpen: boolean
  message: string
  title: string
  severity: Severity
}
export type RootFunctions = {
  openAlert(params: { message: string; severity: Severity, title:string })
  closeAlert()
}

const InitialState: RootState = {
  isOpen: false,
  message: '',
  title: '',
  severity: Severity.INFO,
}

export const AlertContext = React.createContext<
  [RootState, (state: any) => void]
>([InitialState, () => {}])

export const AlertProvider: FunctionComponent = ({ children }) => {
  const [state, setState] = useState<RootState>(InitialState)
  return (
    <AlertContext.Provider value={[state, setState]}>
      {children}
    </AlertContext.Provider>
  )
}

export const useAlert = (): RootFunctions & RootState => {
  const [state, setState] = useContext(AlertContext)
  const openAlert = (params: { message: string; severity: Severity, title:string }) => {
    setState(
      (state: RootState): RootState => ({
        isOpen: true,
        ...params,
      }),
    )
  }
  const closeAlert = () => {
    setState(
      (state: RootState): RootState => ({
        ...state,
        isOpen: false,
      }),
    )
  }
  return {
    ...state,
    openAlert,
    closeAlert,
  }
}
