import React from 'react'
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers'
import Grid, { GridProps } from '@material-ui/core/Grid'
import { FormalWebFieldProps } from '@kevinwolf/formal-web/lib/types'
import { FormalFieldProps } from '@kevinwolf/formal'

interface Props {
  GridProps?: GridProps,
  value: string,
}

export const DateField = (
  {
    GridProps,
    value,
    onChange,
    error,
    ...props
  }: Props & FormalFieldProps & FormalWebFieldProps & Omit<KeyboardDatePickerProps, 'onChange' | 'error'>,
) => {

  return (
    <Grid item {...GridProps}>
      <KeyboardDatePicker
        value={null}
        inputValue={value}
        format="dd.MM.yyyy"
        inputVariant="outlined"
        fullWidth
        helperText=""
        autoComplete="off"
        inputProps={{ 'data-lpignore': true }}
        autoOk={true}
        onChange={(date, value) => {
          onChange({ target: { value: value || '' } })
        }}
        {
          ...(error && { error: true })
        }
        {...props}
      />
    </Grid>
  )
}
