import React from 'react'
import clsx from 'clsx'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { MobillyIcon } from './MobillyIcon'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.up('sm')]: {
      zIndex: theme.zIndex.drawer + 1,
    },
  },
  row: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    margin: '0 -16px',
  },
  col: {
    padding: '0 16px',
  },
  logo: {
    fontSize: 0,
  },
  languages: {
    display: 'flex',
  },
  language: {
    minWidth: 'auto',
    padding: 4,
    marginLeft: 4,
    marginRight: 4,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.white,
    [theme.breakpoints.up('sm')]: {
      padding: 6,
      marginLeft: 6,
      marginRight: 6,
    },
  },
}))

export const TopBar = withRouter(() => {
    const classes = useStyles()
    const { i18n } = useTranslation()

    return (
      <AppBar position="sticky" elevation={0} className={classes.appBar}>
        <Toolbar>
          <div className={classes.row}>
            <div className={clsx(classes.col, classes.logo)}>
              <MobillyIcon />
            </div>
            <div className={clsx(classes.col, classes.languages)}>
              <Button
                onClick={() => i18n.changeLanguage('lv')}
                className={classes.language}
              >LV</Button>
              <Button
                onClick={() => i18n.changeLanguage('ru')}
                className={classes.language}
              >RU</Button>
              <Button
                onClick={() => i18n.changeLanguage('en')}
                className={classes.language}
              >EN</Button>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    )
  },
)
