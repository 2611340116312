import styled from 'styled-components'

export const Wrap = styled.div`
  max-width: 300px;
  width: 100%;
  display: flex;
  button {
    &:first-child {
      margin-right: 5px;
    }
    width: 50%;
  }
`
