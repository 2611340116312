import React, { FunctionComponent, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { ActiveSubscription } from 'models/ActiveSubscription'
import { useTranslation } from 'react-i18next'
import { useSubscription } from 'components/providers/SubscriptionsProvider'
import { Severity, useAlert } from 'components/providers/AlertProvider'
import { useTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { TextField } from 'components/elements/TextField'
import * as yup from 'yup'
import useFormal from '@kevinwolf/formal-web'

type Props = {
  open: boolean
  selectedSubscription: ActiveSubscription
}
type Functions = {
  onClose()
}

export type AllProps = Props & Functions

export const EditNotesDialog: FunctionComponent<AllProps> = (
  {
    open,
    onClose,
    selectedSubscription,
  },
) => {
  if (!selectedSubscription) {
    return null
  }

  const { t } = useTranslation()
  const { modify } = useSubscription()
  const { openAlert } = useAlert()
  const theme = useTheme()
  const smallDevice = useMediaQuery(theme.breakpoints.down('xs'))

  const handleEnter = () => {
    formal.change('notes', selectedSubscription.notes)
  }

  const handleExited = () => {
    formal.change('notes', initialValues.notes)
  }

  const initialValues = {
    notes: '',
  }

  const schema = yup.object().shape({
    notes: yup.string().trim(),
  })

  const formal = useFormal(initialValues, {
    schema,
    onSubmit: async values => {
      values = schema.cast(values)

      const { successful, code, message, modifyOk } = await modify({
        subscription_id: Number(selectedSubscription.id),
        notes: values.notes,
      })

      onClose()

      if (!successful) {
        let alertTitle
        let alertMessage

        if (modifyOk) {
          alertTitle = t('subscriptionPurchase.alertRetrieveActiveSubscriptionFailedTitle')
          alertMessage = message
        } else {
          alertTitle = t('subscriptionPurchase.alertSubscriptionUpdateFailedTitle')
          switch (code) {
            default:
              alertMessage = t('subscriptionPurchase.alertSubscriptionUpdateFailedBody')
          }
        }

        return openAlert({
          severity: Severity.ERROR,
          title: alertTitle,
          message: alertMessage,
        })
      }

      openAlert({
        severity: Severity.SUCCESS,
        title: t('subscriptionPurchase.alertSubscriptionUpdateSuccessfulTitle'),
        message: t('subscriptionPurchase.alertSubscriptionUpdateSuccessfulNotesBody'),
      })
    },
  })

  const onSubmit = async () => {
    formal.submit()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={smallDevice}
      onEnter={handleEnter}
      onExited={handleExited}
    >
      <DialogTitle>
        {t('editNotes')}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <TextField
            GridProps={{ xs: 12 }}
            label={t('notes')}
            {...formal.getFieldProps('notes')}
            autoFocus={true}
          />
        </Grid>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: 'flex-start',
          padding: 20,
          marginTop: 20,
        }}
      >
        <Button
          onClick={() => {
            onClose()
          }}
          variant="outlined"
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={onSubmit}
          color="primary"
          disabled={formal.isSubmitting}
        >
          {t('submit')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditNotesDialog
