import React, { FunctionComponent, useEffect, useState } from 'react'
import Collapse from '@material-ui/core/Collapse'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/styles'
import SvgIcon from '@material-ui/core/SvgIcon'
import { IParkingLotSessionPaymentsPaymentData, ParkingLotSessionPaymentsPaymentOption } from 'components/providers/ApiProvider'
import { Skeleton } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { useApi } from 'components/providers/ApiProvider'
import { formatMoney, formatTimestamp } from 'utils'
import { useParkingLot } from 'components/views/parking-lot/ParkingLotProvider'
import { theme } from 'components/providers/ThemeProvider'
import PaymentOptionChip from 'Elements/PaymentOptionChip'
import MobillyPaymentOptionChip from 'Elements/MobillyPaymentOptionChip'
import { Container } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import IconInfo from '@material-ui/icons/InfoOutlined'
import IconButton from '@material-ui/core/IconButton'
import IconRefund from '@material-ui/icons/Undo'
import IconDetach from '@material-ui/icons/LinkOff'
import { useMessage } from 'Elements/Message'
import Tooltip from '@material-ui/core/Tooltip'
import RefundPaymentDialog, { RefundPaymentDialogData } from 'components/views/parking-lot/partials/RefundPaymentDialog'

const ExpandButton = withStyles({
  label: {
    fontWeight: 500,
  },
  startIcon: {
    marginLeft: 0,
  },
})(Button)

const ListTable = withStyles({
  root: {
    '& td, & th': {
      borderBottomColor: 'rgba(224, 224, 224, 0.33)',
    },
  },
})(Table)

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 12, height: 12 }} {...props}>
      <path
        d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  )
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 12, height: 12 }} {...props}>
      <path
        d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  )
}

type Props = {
  session: {
    id: number,
    entry_at: string,
    exit_at?: string,
  }
}
type Functions = {}
type AllProps = Props & Functions

const ListSessionPayments: FunctionComponent<AllProps> = ({ session }) => {
  if (!session) {
    return null
  }

  const { t } = useTranslation()
  const api = useApi()
  const { loadPayments, setLoadDetachPaymentList } = useParkingLot()

  const [expand, setExpand] = useState(false)
  const [modify, setModify] = useState<boolean>(false)
  const [payments, setPayments] = useState<IParkingLotSessionPaymentsPaymentData[]>(null)

  const [openRefundPaymentDialog, setOpenRefundPaymentDialog] = useState(false)
  const [refundPaymentDialogData, setRefundPaymentDialogData] = useState<RefundPaymentDialogData | null>(null)

  const { showSuccess, showError } = useMessage()

  const load = () => {
    api.getParkingLotSessionPayments({ session_id: session.id })
      .then(data => {
        setModify(Boolean(data.modify))
        setPayments(data.payments)
      })
      .catch(() => {
      })
  }

  useEffect(() => {
    if (!expand || payments) {
      return
    }

    load()
  }, [expand])

  useEffect(() => {
    if (!loadPayments) {
      return
    }

    if (!expand) {
      setPayments(null)

      return
    }

    load()
  }, [loadPayments])

  const handleExpand = () => {
    setExpand(!expand)
  }

  const handleRefund = (payment: IParkingLotSessionPaymentsPaymentData) => {
    setRefundPaymentDialogData({ session_id: session.id, payment_id: payment.id })
    setOpenRefundPaymentDialog(true)
  }

  const handleDetach = (payment: IParkingLotSessionPaymentsPaymentData) => {
    if (!confirm(t('confirms.detachPayment'))) {
      return
    }

    api.detachParkingLotSessionPayment({
      session_id: session.id,
      payment_id: payment.id,
    })
      .then(data => {
        if (!data || !data.status) {
          throw new Error
        }

        setLoadDetachPaymentList()

        showSuccess(t('messages.paymentDetached'))
      })
      .catch(() => {
        showError(t('alerts.couldNotDetachPayment'))
      })
  }

  return (
    <>
      <ExpandButton
        startIcon={expand ? <MinusSquare /> : <PlusSquare />}
        size={'large'}
        onClick={() => handleExpand()}
      >{t('payments')}</ExpandButton>
      <Collapse in={expand} timeout="auto" unmountOnExit>
        {payments && !payments.length && (
          <Container maxWidth="md">
            <Alert severity="info">{t('noRecords')}</Alert>
          </Container>
        )}

        {(!payments || Boolean(payments.length)) && (
          <TableContainer>
            <ListTable size="small">
              <TableHead>
                {!payments && (
                  <TableRow>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                )}
                {payments && (
                  <TableRow>
                    <TableCell>{t('amount')}</TableCell>
                    <TableCell>{t('paidAt')}</TableCell>
                    <TableCell />
                    <TableCell>{t('period')}</TableCell>
                    {modify && <TableCell colSpan={3} />}
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {!payments && (
                  <TableRow>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                )}
                {payments && payments.map((payment, key) => {
                  return <TableRow key={key}>
                    <TableCell>
                      {formatMoney(payment.amount)}
                      {Boolean(payment.amount_refunded) && <> (<span style={{ color: 'orange' }}>{formatMoney(payment.amount_refunded)}</span>)</>}
                    </TableCell>
                    <TableCell>{formatTimestamp(payment.paid_at)}</TableCell>
                    <TableCell style={{ paddingTop: 4, paddingBottom: 4 }}>
                      {payment.option == ParkingLotSessionPaymentsPaymentOption.MobillyAutomatic && (
                        <MobillyPaymentOptionChip
                          letter="A"
                          label="Automatic"
                        />
                      )}
                      {payment.option == ParkingLotSessionPaymentsPaymentOption.MobillyStartStop && (
                        <MobillyPaymentOptionChip
                          label="Start-stop"
                        />
                      )}
                      {payment.option == ParkingLotSessionPaymentsPaymentOption.MobillyMPay && (
                        <MobillyPaymentOptionChip
                          label="Mpay"
                        />
                      )}
                      {payment.option == ParkingLotSessionPaymentsPaymentOption.MobillyPostpay && (
                        <MobillyPaymentOptionChip
                          label="Postpay"
                        />
                      )}
                      {payment.option == ParkingLotSessionPaymentsPaymentOption.CashRegister && (
                        <PaymentOptionChip
                          label={t('cashRegister')}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {(!payment.entry_at || !payment.exit_at) ? (<>{`—`}</>) : (<span style={{
                        ...((new Date(payment.exit_at).getTime() <= new Date(session.entry_at).getTime() || (session.exit_at && new Date(payment.entry_at).getTime() >= new Date(session.exit_at).getTime())) && { color: theme.palette.primary.main }),
                      }}>{formatTimestamp(payment.entry_at)} - {formatTimestamp(payment.exit_at)}</span>)}
                    </TableCell>
                    {modify && (
                      <>
                        <TableCell align="center" style={{ width: 62 }}>
                          <Tooltip title={`#: ${payment.id}`} placement={'top'} arrow interactive>
                            <IconInfo fontSize="small" style={{ verticalAlign: 'middle' }} />
                          </Tooltip>
                        </TableCell>
                        <TableCell style={{ paddingLeft: 0, width: 46 }}>
                          {payment.modify.refundable && (
                            <IconButton
                              color="secondary"
                              size="small"
                              style={{ margin: '-5px 0' }}
                              onClick={() => handleRefund(payment)}
                            >
                              <IconRefund />
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell style={{ paddingLeft: 0, width: 46 }}>
                          {payment.modify.detachable && (
                            <IconButton
                              color="secondary"
                              size="small"
                              style={{ margin: '-5px 0' }}
                              onClick={() => handleDetach(payment)}
                            >
                              <IconDetach />
                            </IconButton>
                          )}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                })}
              </TableBody>
            </ListTable>
          </TableContainer>
        )}
      </Collapse>
      {modify && (
        <>
          <RefundPaymentDialog open={openRefundPaymentDialog} setOpen={setOpenRefundPaymentDialog} data={refundPaymentDialogData} />
        </>
      )}
    </>
  )
}

export default ListSessionPayments
