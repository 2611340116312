import React from 'react'
import { ThemeProvider as Provider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core'


const shadowKeyUmbraOpacity = 0.2
const shadowKeyPenumbraOpacity = 0.14
const shadowAmbientShadowOpacity = 0.12

const createShadow = (...px: number[]) =>
  [
    `${px[0]}px ${px[1]}px ${px[2]}px ${px[3]}px rgba(240,106,36,${shadowKeyUmbraOpacity})`,
    `${px[4]}px ${px[5]}px ${px[6]}px ${px[7]}px rgba(240,106,36,${shadowKeyPenumbraOpacity})`,
    `${px[8]}px ${px[9]}px ${px[10]}px ${px[11]}px rgba(240,106,36,${shadowAmbientShadowOpacity})`,
  ].join(',')

let theme = createMuiTheme({
  typography: {
    fontFamily: ['Rubik', 'sans-serif'].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    background: {
      default: '#ffffff',
    },
    primary: {
      main: '#F06623',
    },
    secondary: {
      main: '#403C39',
    },
    grey: {
      '100': '#262322',
      '200': '#403C39',
      '300': '#8C8581',
      '400': '#BFB8B4',
      '500': '#D9D0CC',
      '600': '#E6DFDC',
      '700': '#FAF5F2',
    },
  },
  shadows: [
    'none',
    createShadow(0, 1, 3, 0, 0, 1, 1, 0, 0, 2, 1, -1),
    createShadow(0, 1, 5, 0, 0, 2, 2, 0, 0, 3, 1, -2),
    createShadow(0, 1, 8, 0, 0, 3, 4, 0, 0, 3, 3, -2),
    createShadow(0, 2, 4, -1, 0, 4, 5, 0, 0, 1, 10, 0),
    createShadow(0, 3, 5, -1, 0, 5, 8, 0, 0, 1, 14, 0),
    createShadow(0, 3, 5, -1, 0, 6, 10, 0, 0, 1, 18, 0),
    createShadow(0, 4, 5, -2, 0, 7, 10, 1, 0, 2, 16, 1),
    createShadow(0, 5, 5, -3, 0, 8, 10, 1, 0, 3, 14, 2),
    createShadow(0, 5, 6, -3, 0, 9, 12, 1, 0, 3, 16, 2),
    createShadow(0, 6, 6, -3, 0, 10, 14, 1, 0, 4, 18, 3),
    createShadow(0, 6, 7, -4, 0, 11, 15, 1, 0, 4, 20, 3),
    createShadow(0, 7, 8, -4, 0, 12, 17, 2, 0, 5, 22, 4),
    createShadow(0, 7, 8, -4, 0, 13, 19, 2, 0, 5, 24, 4),
    createShadow(0, 7, 9, -4, 0, 14, 21, 2, 0, 5, 26, 4),
    createShadow(0, 8, 9, -5, 0, 15, 22, 2, 0, 6, 28, 5),
    createShadow(0, 8, 10, -5, 0, 16, 24, 2, 0, 6, 30, 5),
    createShadow(0, 8, 11, -5, 0, 17, 26, 2, 0, 6, 32, 5),
    createShadow(0, 9, 11, -5, 0, 18, 28, 2, 0, 7, 34, 6),
    createShadow(0, 9, 12, -6, 0, 19, 29, 2, 0, 7, 36, 6),
    createShadow(0, 10, 13, -6, 0, 20, 31, 3, 0, 8, 38, 7),
    createShadow(0, 10, 13, -6, 0, 21, 33, 3, 0, 8, 40, 7),
    createShadow(0, 10, 14, -6, 0, 22, 35, 3, 0, 8, 42, 7),
    createShadow(0, 11, 14, -7, 0, 23, 36, 3, 0, 9, 44, 8),
    createShadow(0, 11, 15, -7, 0, 24, 38, 3, 0, 9, 46, 8),
  ],
})

theme = {
  ...theme,
  overrides: {

    MuiDrawer: {
      paper: {
        backgroundColor: '#262322',
      },
    },
    MuiToolbar: {
      gutters: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },
      },
    },
    // @ts-ignore-start
    MuiToggleButtonGroup: {
      root: {
        border: 'none',
        backgroundColor: 'transparent',
      },
    },
    MuiToggleButton: {
      root: {
        height: '64px',
        border: 'none',
        '&$selected': {
          margin: 0,
          color: theme.palette.primary.main,
          backgroundColor: 'transparent',
        },
      },
    },
    // @ts-ignore-end
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#FAF5F2',
        borderWidth: 0,
        '& $notchedOutline': {
          borderColor: 'transparent',
        },
        '&:hover $notchedOutline': {
          borderColor: 'transparent',
        },
        '&$focused $notchedOutline': {
          borderColor: 'transparent',
          borderWidth: 1,
        },
        '&$error $notchedOutline': {
          // borderColor: theme.palette.background.default,
        },
        '&$disabled $notchedOutline': {
          borderColor: 'transparent',
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        '&$focused': {
          color: theme.palette.primary.main,
        },
        '&$shrink': {
          color: theme.palette.primary.main,
        },
      },
    },
    MuiButton: {
      label: {
        fontWeight: 400,
      }
    },
    MuiTableRow: {
      root: {
        '&.MuiTableRow-hover:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.02)',
        },
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 500,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
      },
    },
    MuiChip: {
      root: {
        color: theme.palette.text.primary,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontWeight: 'normal',
        color: theme.palette.secondary.main,
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        '&.MuiTypography-h4': {
          fontSize: '2rem',
          lineHeight: 1.45,
        },
      },
    },
  },
}

export const ThemeProvider: React.FC = ({ children }) => (
  <Provider theme={theme}>{children}</Provider>
)

export { theme }
