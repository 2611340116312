import React, { FunctionComponent, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import VehicleManager from 'components/views/subscriptions/buy/common/vehicle-manager/VehicleManager'
import { ActiveSubscription } from 'models/ActiveSubscription'
import { useTranslation } from 'react-i18next'
import { Car } from 'models/Car'
import { useSubscription } from 'components/providers/SubscriptionsProvider'
import { Severity, useAlert } from 'components/providers/AlertProvider'
import Alert from '@material-ui/lab/Alert'
import { useTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import styled from 'styled-components'

const ZoneBubble = styled.div`
  border-radius: 50%;
  background: #000;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-right: 10px;
  font-size: 12px;
  font-weight: 500;
  flex-shrink: 0;
  vertical-align: top;
`

export type Props = {
  open: boolean
  selectedSubscription: ActiveSubscription
}
export type Functions = {
  onClose()
}

export type AllProps = Props & Functions

export const EditCarsDialog: FunctionComponent<AllProps> = ({
  open,
  onClose,
  selectedSubscription,
}) => {
  if (!selectedSubscription) {
    return null
  }

  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { modify } = useSubscription()
  const [selectedCars, setSelectedCars] = useState<Car[]>([])
  const { openAlert } = useAlert()
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('xs'));

  const subscriptionVehicles = selectedSubscription.vehicles.map(
    vehicle =>
      new Car({
        plateNumber: vehicle.plateNumber,
      }),
  )

  const onSubmit = async () => {
    setIsSubmitting(true)

    const { successful, code, message, extra, modifyOk } = await modify({
      subscription_id: Number(selectedSubscription.id),
      plates: selectedCars.map(c => c.plateNumber),
    })

    onClose()

    if (!successful) {
      let alertSeverity = Severity.WARNING
      let alertTitle
      let alertMessage

      if (modifyOk) {
        alertSeverity = Severity.ERROR
        alertTitle = t('subscriptionPurchase.alertRetrieveActiveSubscriptionFailedTitle')
        alertMessage = message
      } else {
        alertTitle = t('subscriptionPurchase.alertSubscriptionUpdateFailedTitle')
        switch (code) {
          case 9:
            alertMessage = t('subscriptionPurchase.alertSubscriptionUpdateFailedVehicleLimitBody')
            break

          case 10:
            alertMessage = t('subscriptionPurchase.alertSubscriptionUpdateFailedVehicleOverlapBody', { plates: extra.plates.join(', ') })
            break

          default:
            alertSeverity = Severity.ERROR
            alertMessage = t('subscriptionPurchase.alertSubscriptionUpdateFailedBody')
        }
      }

      setIsSubmitting(false)
      return openAlert({
        severity: alertSeverity,
        title: alertTitle,
        message: alertMessage,
      })
    }

    setIsSubmitting(false)
    openAlert({
      severity: Severity.SUCCESS,
      title: t('subscriptionPurchase.alertSubscriptionUpdateSuccessfulTitle'),
      message: t('subscriptionPurchase.alertSubscriptionUpdateSuccessfulBody'),
    })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={smallDevice}
    >
      <DialogTitle>
        <ZoneBubble style={selectedSubscription.zoneName.length > 3 ? { fontSize: '10px' } : undefined}>{selectedSubscription.zoneName}</ZoneBubble> {selectedSubscription.name}
      </DialogTitle>
      <DialogContent>
        {!Boolean(selectedCars.length) && (
          <Alert severity="warning" style={{ marginTop: 8, marginBottom: -8 }}>
            {t('subscriptionPurchase.noPlateNumbersAdded')}
          </Alert>
        )}
        <VehicleManager
          extraCars={subscriptionVehicles}
          onAddCar={car => {}}
          onChange={setSelectedCars}
          selectedCars={subscriptionVehicles}
        />
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: 'flex-start',
          padding: '20px',
          marginTop: '20px',
        }}
      >
        <Button
          onClick={() => {
            onClose()
          }}
          variant="outlined"
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={onSubmit}
          color="primary"
          disabled={isSubmitting}
        >
          {t('submit')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditCarsDialog
